import BigNumber from 'bignumber.js'
import Web3 from 'web3'
import { TransactionReceipt } from 'web3-types'

// eslint-disable-next-line
import { BN } from 'ethereumjs-util'
import { SendOptions } from 'web3-eth-contract'
import { batchDepositAbi } from '../../abi/batchDeposit'
import { TransactionStatus } from '../../store/actions/depositFileActions'
import { DepositKeyInterface } from '../../store/reducers'
import { BATCH_DEPOSIT_ADDRESS, PRICE_PER_VALIDATOR } from '../../utils/envVars'
import { prefix0X } from '../../utils/prefix0x'
import { Connector } from '@web3-react/types'
import { WorkflowStep } from '../../store/actions/workflowActions'
import { PendingValidator, storePendingValidators } from '../../hooks/usePendingValidators'

const isUserRejectionError = (error: any) => {
  if (error.code === 4001) return true // Metamask reject
  if (error.code === -32603) {
    // 0. Metamask deny signature
    if (error.message.includes('MetaMask Tx Signature: User denied transaction signature.')) return true
    // 1. Ledger time-out or no-connect via Metamask
    if (error.message.includes('​Ledger device: U2F OTHER_ERROR')) return false // false because the TX can be submitted after the Metamask timeout period
    // 2. Ledger reject via Metamask
    if (error.message.includes('Ledger device: Condition of use not satisfied (denied by the user?)')) return true
    // 3. Trezor reject via Metamask
    if (error.message.includes('Action cancelled by user')) return true
    // 4. Trezor popup closed via Metamask
    if (error.message.includes('Popup closed')) return true
    // 5. Trezor popup denied via Metamask
    if (error.message.includes('Permissions not granted')) return true
    // 6. Trezor disconnected via Metamask
    if (error.message.includes('device disconnected during action')) return true
    // 7. Fortmatic reject
    if (error.message.includes('Fortmatic: User denied transaction.')) return true
    // 8. Portis Reject
    if (error.message.includes('User denied transaction signature.')) return true
  }
  return false
}

/*
 * Call batchDeposit to handle multiple deposits
 */
export const handleMultipleTransactions = async (
  depositKeys: DepositKeyInterface[],
  connector: Connector,
  account: any,
  updateTransactionStatus: (pubkey: string, status: TransactionStatus, txHash?: string) => void,
  updateWorkflowStatus: (status: WorkflowStep) => void
) => {
  const walletProvider: any = connector.provider
  const web3: any = new Web3(walletProvider)
  const contract = new web3.eth.Contract(batchDepositAbi, BATCH_DEPOSIT_ADDRESS)

  const remainingTxs = depositKeys.filter(
    (key) => key.transactionStatus === TransactionStatus.READY || key.transactionStatus === TransactionStatus.REJECTED
  )

  const pricePerValidator = new BigNumber(PRICE_PER_VALIDATOR)
  const TX_VALUE = pricePerValidator.multipliedBy(1e18).multipliedBy(remainingTxs.length)

  const transactionParameters: SendOptions = {
    // gasLimit: '0x124f8', TODO set gas limit
    gasPrice: web3.utils.toHex(await web3.eth.getGasPrice()),
    from: account as string,
    value: new BN(TX_VALUE.toFixed()),
  }

  const pubkeys: string[] = [],
    all_withdrawal_credentials: string[] = [],
    signatures: string[] = [],
    deposit_data_roots: string[] = []

  remainingTxs.forEach((deposit_info) => {
    const {
      pubkey,
      // eslint-disable-next-line
      withdrawal_credentials,
      signature,
      // eslint-disable-next-line
      deposit_data_root,
    } = deposit_info

    pubkeys.push(prefix0X(pubkey))
    all_withdrawal_credentials.push(prefix0X(withdrawal_credentials))
    signatures.push(prefix0X(signature))
    deposit_data_roots.push(prefix0X(deposit_data_root))
    updateTransactionStatus(pubkey, TransactionStatus.PENDING)
  })

  const setRemainingTransactionStatus = (status: TransactionStatus, extraArgs?: any) => {
    remainingTxs.forEach(({ pubkey }) => {
      updateTransactionStatus(pubkey, status, extraArgs)
    })
  }

  contract.methods
    .batchDeposit(pubkeys, all_withdrawal_credentials, signatures, deposit_data_roots)
    .send(transactionParameters)
    .on('transactionHash', (txHash: string): void => {
      setRemainingTransactionStatus(TransactionStatus.STARTED, txHash)
    })
    .on('receipt', () => {
      // do something?
    })
    .on('confirmation', (confirmation: number, receipt: TransactionReceipt): any => {
      console.debug('Received receipt', confirmation, receipt)
      if (confirmation === 0) {
        const status = receipt.status ? TransactionStatus.SUCCEEDED : TransactionStatus.FAILED
        setRemainingTransactionStatus(status)
        if (receipt.status) {
          storePendingValidators(decodeDepositEvents(web3, receipt))
          updateWorkflowStatus(WorkflowStep.CONGRATULATIONS)
        }
      }
    })
    .on('error', (error: any) => {
      const errorStatus = isUserRejectionError(error) ? TransactionStatus.REJECTED : TransactionStatus.FAILED
      setRemainingTransactionStatus(errorStatus)
    })
}

const decodeDepositEvents = (web3: Web3, receipt: TransactionReceipt): PendingValidator[] => {
  const contract = new web3.eth.Contract(batchDepositAbi, BATCH_DEPOSIT_ADDRESS)
  return receipt.logs
    .filter(
      (l) =>
        !!l.data && !!l.topics && l.topics[0] === '0x649bbc62d0e31342afea4e5cd82d4049e7e1ee912fc0889aa790803be39038c5'
    )
    .map((l) => {
      const decoded = web3.eth.abi.decodeLog(
        [
          {
            indexed: false,
            internalType: 'bytes',
            name: 'pubkey',
            type: 'bytes',
          },
          {
            indexed: false,
            internalType: 'bytes',
            name: 'withdrawal_credentials',
            type: 'bytes',
          },
          {
            indexed: false,
            internalType: 'bytes',
            name: 'amount',
            type: 'bytes',
          },
          {
            indexed: false,
            internalType: 'bytes',
            name: 'signature',
            type: 'bytes',
          },
          {
            indexed: false,
            internalType: 'bytes',
            name: 'index',
            type: 'bytes',
          },
        ],
        // @ts-ignore
        l.data,
        // @ts-ignore
        l.topics[0]
      )
      return {
        pubKey: decoded.pubkey,
        depositTime: Math.floor(Date.now() / 1000),
      }
    })
}

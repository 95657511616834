// @ts-nocheck
import React from 'react'
import useIntlNetworkName from '../../hooks/useIntlNetworkName'

export const WrongNetwork = () => {
  const { executionLayerName } = useIntlNetworkName()
  return (
    <div className="mx-auto mt-[150px] w-max rounded-md bg-white p-4 shadow-modal">
      <p>Your wallet is on the wrong Ethereum network. To continue, connect to {executionLayerName}.</p>
    </div>
  )
}

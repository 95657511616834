import { Web3Provider } from '@ethersproject/providers'
import { formatEther } from '@ethersproject/units'
import { useWeb3React } from '@web3-react/core'
import { InjectedConnector } from '@web3-react/injected-connector'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import React, { useEffect, useState } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { MetaMask } from '@web3-react/metamask'
import { WalletConnect } from '@web3-react/walletconnect-v2'
import { RoutesEnum } from '../../Routes'
import { AllowedELNetworks } from '../../pages/ConnectWallet/web3Utils'
import { NetworkChainId } from '../../constants/web3'
import EthCurrencyIcon from '../../static/eth-currency.svg'
import PierTwoLogo from '../../static/logo.svg'
import useEthBalance from '../../hooks/useEthBalance'
import { Modal } from '../Modal'
import { ConnectWalletModal } from '../Modal/ConnectWallet'
import { NetworkDropdown } from '../NetworkDropdown'
import { IS_MAINNET, NETWORK_NAME } from '../../utils/envVars'
import { WalletDropdown } from '../WalletDropdown'

const depositLink = {
  label: 'Deposit',
  href: '/',
}

const validatorsLink = {
  label: 'My Validators',
  href: '/validators',
}
const withdrawLink = {
  label: 'Withdraw',
  href: '/manual-withdrawals',
}

const navLinks = IS_MAINNET ? [depositLink, validatorsLink, withdrawLink] : [depositLink, withdrawLink]

const _AppBar = () => {
  const { connector, provider, isActive: walletConnected, account, chainId } = useWeb3React<Web3Provider>()
  const [isConnectModalOpen, setIsConnectModalOpen] = useState(false)
  const [walletProviderName, setWalletProviderName] = useState<'metamask' | 'wallet-connect' | undefined>()

  const handleOpenConnectModal = () => setIsConnectModalOpen(true)
  const handleCloseConnectModal = () => setIsConnectModalOpen(false)

  let network
  let networkAllowed = false

  if (chainId) {
    network = NetworkChainId[chainId]
    networkAllowed = AllowedELNetworks.includes(network)
  }

  const balance = useEthBalance()

  useEffect(() => {
    if (walletConnected && connector && connector instanceof MetaMask) {
      setWalletProviderName('metamask')
    } else if (walletConnected && connector && connector instanceof WalletConnect) {
      setWalletProviderName('wallet-connect')
    }
    setIsConnectModalOpen(false);
  }, [walletConnected, connector])

  const disconnect = () => {
    if (connector?.deactivate) {
      connector.deactivate()
    }
    connector.resetState()
  }

  return (
    <>
      <nav className="fixed left-0 top-0 z-50 flex h-12 w-full items-center border-b border-b-pt-dark-indigo bg-pt-grey">
        <div className="relative mx-auto flex flex w-full max-w-[1440px] items-center justify-between text-xs lg:px-0">
          <Link to={RoutesEnum.landingPage}>
            <img
              src={PierTwoLogo}
              alt="Pier Two"
              className="h-[17px] w-[87px]"
            />
          </Link>
          <div className="flex items-center gap-x-6">
            <div className="flex gap-x-6">
              {navLinks.map(({ label, href }) => (
                <Link
                  to={href}
                  key={label}
                >
                  <span className="text-pt-dark-indigo">{label}</span>
                </Link>
              ))}
            </div>
            {walletConnected && (
              <div className="flex items-center gap-x-2">
                <img
                  src={EthCurrencyIcon}
                  alt="ETH icon"
                  className="h-4 w-4"
                />
                {balance.toFixed(2)}
                <span>Ξ</span>
              </div>
            )}
            <div className="flex">
              <WalletDropdown
                connector={connector}
                walletConnected={walletConnected}
                account={account}
                walletProviderName={walletProviderName}
                deactivate={disconnect}
                handleOpenConnectModal={handleOpenConnectModal}
              />
              <NetworkDropdown targetNetwork={NETWORK_NAME} />
            </div>
          </div>
        </div>

        {/* <RightNavContainer>
          {!mobile && walletConnected && (
            <DotDropdownBox>
              {networkAllowed ? (
                <DotDropdown
                  className="secondary-link"
                  label={
                    <Dot success={networkAllowed} error={!networkAllowed} />
                  }
                  dropAlign={{ top: 'bottom', right: dropAlignInline }}
                  dropContent={
                    // eslint-disable-next-line react/jsx-wrap-multilines
                    <Box pad="small">
                      <Text>
                        Your wallet is connected to the right network!
                      </Text>
                    </Box>
                  }
                />
              ) : (
                <DotDropdown
                  className="secondary-link"
                  label={<Dot error={!networkAllowed} />}
                  dropAlign={{ top: 'bottom', right: dropAlignInline }}
                  dropContent={
                    // eslint-disable-next-line react/jsx-wrap-multilines
                    <Box pad="small">
                      <Text>
                        <FormattedMessage
                          defaultMessage="Your wallet should be set to {executionLayerName} to use this launchpad."
                          values={{ executionLayerName }}
                        />
                      </Text>
                    </Box>
                  }
                />
              )}
              <Text size="small" color="blueDark">
                {trimString(account as string, 10)}
              </Text>
            </DotDropdownBox>
          )} */}
        {/* {!mobile && (
          <>
            <ValidatorDropdown
              className="secondary-link"
              label={<NetworkText>{NETWORK_NAME}</NetworkText>}
              dropAlign={{ top: 'bottom', right: dropAlignInline }}
              dropContent={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <Card>
                  <Box pad="small" className="mt0">
                    {!IS_MAINNET && (
                      <Text className="mb10">
                        This is a test network ⚠️
                      </Text>
                    )}
                    <DropdownLink to={switchLaunchpadUrl}>
                      <FormattedMessage
                        defaultMessage="Switch to {oppositeNetwork} launchpad"
                        values={{ oppositeNetwork }}
                      />
                    </DropdownLink>
                  </Box>
                </Card>
              }
            />
          </>
        )} */}
        {/* </RightNavContainer> */}
      </nav>
      <Modal
        isOpen={isConnectModalOpen}
        onClose={handleCloseConnectModal}
      >
        <ConnectWalletModal onClose={handleCloseConnectModal} />
      </Modal>
    </>
  )
}

export const AppBar = withRouter(_AppBar)
export default AppBar

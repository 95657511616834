import { useIntl } from 'react-intl'
import { IS_MAINNET, TESTNET_LAUNCHPAD_NAME } from '../utils/envVars'

const useIntlNetworkName = (): {
  executionLayerName: string
  consensusLayerName: string
} => {
  const { formatMessage } = useIntl()
  const mainnet = formatMessage({ id: 'mainnet-name', defaultMessage: 'Mainnet' })
  const testnet = formatMessage(
    { id: 'testnet-name', defaultMessage: '{TESTNET_LAUNCHPAD_NAME} testnet' },
    { TESTNET_LAUNCHPAD_NAME }
  )
  const executionLayerName: string = IS_MAINNET ? mainnet : testnet
  const consensusLayerName: string = IS_MAINNET ? mainnet : testnet
  return { executionLayerName, consensusLayerName }
}

export default useIntlNetworkName

import { BEACONCHAIN_API_KEY } from './envVars'

// @ts-ignore
export const fetcher = (...args) => fetch(...args).then((res) => res.json())

// @ts-ignore
export const beaconChainFetcher = (...args) =>
  // @ts-ignore
  fetch(...args, {
    method: 'GET',
    headers: {
      apikey: BEACONCHAIN_API_KEY,
    },
  }).then((res) => res.json())

const ONE_MINUTE = 60 * 1000

const cache: Record<string, { res: any; expiry: number }> = {}

// @ts-ignore
export const multiFetcher = async (urls) => {
  const promises = []
  const now = Date.now()
  for (let i = 0; i < urls.length; i++) {
    const url = urls[i]
    if (cache[url] && cache[url].expiry > now) {
      console.log(`Fetching ${url} from cache`)
      promises.push(Promise.resolve(cache[url].res))
    } else {
      promises.push(beaconChainFetcher(urls[i]))
    }
  }

  const res = await Promise.allSettled(promises)
  // push fulfilled promises
  res.forEach((result, i) => {
    if (result.status === 'fulfilled') {
      cache[urls[i]] = {
        res: result.value,
        expiry: now + ONE_MINUTE,
      }
    }
  })
  // throw if some rejected
  if (res.some((result) => result.status === 'rejected')) {
    throw new Error(`Failed to fetch from multifetcher ${urls}`)
  }

  return res.map((result) => (result as any).value)
}

/*
  eslint-disable camelcase
*/

import { Action, ActionTypes } from '../actions'
import { BeaconChainStatus, DepositStatus, TransactionStatus } from '../actions/depositFileActions'
import { produce } from 'immer'
import { VoluntaryExitInterface } from '../../utils/withdrawals'

export interface WithdrawalRequestInterface extends VoluntaryExitInterface {}

export interface WithdrawalFileInterface {
  name: string
  signatures: WithdrawalRequestInterface[]
  // beaconChainApiStatus: BeaconChainStatus
}

const initialState: WithdrawalFileInterface = {
  name: '',
  signatures: [],
  // beaconChainApiStatus: BeaconChainStatus.HEALTHY,
}

// interface BeaconchainDepositDataInterface {
// amount: number
// block_number: number
// block_ts: number
// from_address: string
// merkletree_index: string
// publickey: string
// removed: boolean
// signature: string
// tx_hash: string
// tx_index: number
// tx_input: string
// valid_signature: boolean
// withdrawal_credentials: string
// }

// export interface BeaconchainDepositInterface {
// data: BeaconchainDepositDataInterface[]
// status: string
// }

export const withdrawalFileReducer = (state: WithdrawalFileInterface = initialState, action: Action) => {
  if (action.type === ActionTypes.updateWithdrawalFileName) {
    return produce(state, (draftState) => {
      draftState.name = action.payload
    })
  }

  if (action.type === ActionTypes.updateWithdrawalSignatures) {
    return produce(state, (draftState) => {
      draftState.signatures = action.payload
    })
  }

  // if (action.type === ActionTypes.updateTransactionStatus) {
  // const { keys } = state
  // const clonedKeys = [...keys]
  // const indexOfFileToUpdate = keys.findIndex(({ pubkey }) => pubkey === action.payload.pubkey)
  // clonedKeys[indexOfFileToUpdate].transactionStatus = action.payload.status

  // if (action.payload.txHash) {
  // clonedKeys[indexOfFileToUpdate].txHash = action.payload.txHash
  // }

  // return { ...state, keys: clonedKeys }
  // }

  // if (action.type === ActionTypes.updateDepositStatus) {
  // const { keys } = state
  // const clonedKeys = [...keys]
  // const indexOfFileToUpdate = keys.findIndex(({ pubkey }) => pubkey === action.payload.pubkey)
  // clonedKeys[indexOfFileToUpdate].depositStatus = action.payload.depositStatus

  // return { ...state, keys: clonedKeys }
  // }

  // if (action.type === ActionTypes.updateBeaconChainAPIStatus) {
  // return {
  // ...state,
  // beaconChainApiStatus: action.payload,
  // }
  // }
  return state
}

import { ActionTypes } from './index'

export enum WalletAction {
  'CONNECT_WALLET_CLOSED',
  'CONNECT_WALLET_OPEN',
}

export interface UpdateWalletAction {
  type: ActionTypes.updateWalletAction
  payload: WalletAction
}
export const updateWalletAction = (action: WalletAction): UpdateWalletAction => {
  return {
    type: ActionTypes.updateWalletAction,
    payload: action,
  }
}

export type DispatchWalletActionType = (action: WalletAction) => void

export type DispatchConnectWallet = () => void

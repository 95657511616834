import cx from 'classnames'
import React, { FC } from 'react'
import CheckCircleIcon from '../../static/check-circle.svg'
import { Connector } from '@web3-react/types'

interface WalletButtonV2Props {
  title: string
  provider: any
  selectedWallet: Connector | undefined | null
  setSelectedWallet(wallet?: Connector): void
  providerLogo: string
}
export const WalletButtonV2: FC<WalletButtonV2Props> = ({
  title,
  provider,
  selectedWallet,
  setSelectedWallet,
  providerLogo,
}) => (
  <button
    onClick={() => {
      if (selectedWallet === provider) {
        setSelectedWallet(undefined)
      } else {
        setSelectedWallet(provider)
      }
    }}
    className="flex w-full items-center gap-x-4 rounded-md transition-colors hover:bg-pt-grey"
  >
    <div className="p-4">
      <img
        src={providerLogo}
        alt="Wallet Connect"
        className="h-8 w-8"
      />
    </div>
    <div className="flex w-full flex-1 items-center justify-between">
      <span
        className={cx('text-black', {
          'font-bold': selectedWallet === provider,
        })}
      >
        {title}
      </span>
      <img
        src={CheckCircleIcon}
        alt="Check"
        className={cx('h-8 w-8 transition-opacity', {
          'opacity-0': selectedWallet !== provider,
        })}
      />
    </div>
  </button>
)

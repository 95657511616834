import { Web3Provider } from '@ethersproject/providers'
import { useWeb3React } from '@web3-react/core'
import { Close } from 'grommet-icons'
import React, { SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Dispatch } from 'redux'
import styled from 'styled-components'
import { Code } from '../../components/Code'
import { Link } from '../../components/Link'
import { TermsModal } from '../../components/TermsModal'
import { Text } from '../../components/Text'
import { WorkflowPageTemplate } from '../../components/WorkflowPage/WorkflowPageTemplate'
import { networkConfigs } from '../../constants/networks'
import { NetworkChainId } from '../../constants/web3'
import useIntlNetworkName from '../../hooks/useIntlNetworkName'
import {
  DispatchWithdrawalFileNameUpdateType,
  updateWithdrawalFileName,
  updateWithdrawalSignatures,
} from '../../store/actions/withdrawalFileActions'
import { StoreState, WithdrawalRequestInterface } from '../../store/reducers'
import { GENESIS_FORK_VERSION } from '../../utils/envVars'
import { getFromLocalStorage, saveToLocalStorage } from '../../utils/localStorage'
import {
  VoluntaryExitInterface,
  validateWithdrawalRequest,
  validateWithdrawalRequestJsonStructure,
} from '../../utils/withdrawals'
import { getExistingDepositsForPubkeys } from '../UploadValidator/validateDepositKey'

const Dropzone = styled.div`
  outline: none;
  :focus {
    outline: none;
  }
  width: 570px;
  height: 260px;
  cursor: ${(p: { isFileAccepted: boolean; isFileStaged: boolean }) =>
    p.isFileAccepted || p.isFileStaged ? 'inherit' : 'pointer'};
  padding: 30px;
  border-radius: 6px;
  background-color: white;
  box-shadow: 0px 4px 4px 0px #00000040;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
`

const DeleteBtn = styled.span`
  cursor: pointer;
  padding: 3px;
`

const createBroadcastLink = (chainId: number | undefined) => {
  switch (chainId) {
    case NetworkChainId.Goerli:
      return networkConfigs.Goerli.broadcastLink
    case NetworkChainId.Mainnet:
      return networkConfigs.Mainnet.broadcastLink
    case NetworkChainId.Holesky:
      return networkConfigs.Holesky.broadcastLink
    default:
      return networkConfigs.Mainnet.broadcastLink
  }
}

interface OwnProps {}
interface StateProps {
  withdrawalFileName: string
  withdrawalSignatures: WithdrawalRequestInterface[]
}
interface DispatchProps {
  dispatchWithdrawalSignaturesUpdate: any
  dispatchWithdrawalFileNameUpdate: DispatchWithdrawalFileNameUpdateType
  // dispatchDepositStatusUpdate: DispatchDepositStatusUpdateType
  // dispatchBeaconChainAPIStatusUpdate: DispatchBeaconChainAPIStatusUpdateType
}
type Props = StateProps & DispatchProps & OwnProps

const _ManualWithdrawalsPage = ({
  withdrawalFileName,
  withdrawalSignatures,
  dispatchWithdrawalFileNameUpdate,
  dispatchWithdrawalSignaturesUpdate,
}: // dispatchDepositStatusUpdate,
// dispatchBeaconChainAPIStatusUpdate,
Props): JSX.Element => {
  const routerHistory = useHistory()
  const { consensusLayerName } = useIntlNetworkName()
  const [hasAcceptedTerms, setHasAcceptedTerms] = useState(false)
  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false)
  const [isFileStaged, setIsFileStaged] = useState(withdrawalSignatures.length > 0)
  const [isFileAccepted, setIsFileAccepted] = useState(withdrawalSignatures.length > 0)
  const [fileError, setFileError] = useState<React.ReactElement | null>(null)
  const [jsonFilesArr, setJsonFilesArr] = useState<any[]>([])
  const [validatorCount, setValidatorCount] = useState(0)
  const {
    acceptedFiles, // all JSON files will pass this check (including BLS failures
    inputRef,
  } = useDropzone({
    accept: 'application/json',
  })

  const { chainId } = useWeb3React<Web3Provider>()

  const jsonFilesRef = useRef<any[]>()
  jsonFilesRef.current = jsonFilesArr

  const flushDropzoneCache = useCallback(() => {
    acceptedFiles.length = 0
    acceptedFiles.splice(0, acceptedFiles.length)
    if (inputRef.current) {
      inputRef.current.value = ''
    }
  }, [acceptedFiles, inputRef])

  const checkJsonStructure = (withdrawalDataJson: { [field: string]: any }) => {
    return !!(
      withdrawalDataJson.message ||
      withdrawalDataJson.message?.epoch ||
      withdrawalDataJson.message?.validator_index ||
      withdrawalDataJson.signature
    )
  }

  const onBroadcastVoluntaryExit = useCallback(() => {
    // TODO need to dispatch something here to notifiy the user it has broadcasted
    // forwardVoluntaryExit(withdrawalSignatures)
    // routeToCorrectWorkflowStep(WorkflowStep.congratulations)
    routerHistory.push('/withdrawal-congratulations')
  }, [withdrawalSignatures, routerHistory])

  const handleToggleTermsAccepted = () => setHasAcceptedTerms(!hasAcceptedTerms)

  const handleTermsModalClose = () => {
    setIsTermsModalOpen(false)
    saveToLocalStorage('acceptedTerms', 'true')
  }

  const handleWrongNetwork = () => {
    setFileError(
      <Text>
        <FormattedMessage
          defaultMessage="This JSON file isn't for the right network. Upload a file generated for your current network: {consensusLayerName}."
          values={{ consensusLayerName }}
        />
      </Text>
    )
  }

  const handleSevereError = () => {
    setFileError(
      <Text>
        <FormattedMessage
          defaultMessage="Couldn't upload {withdrawalFileName} due to an error. Open an issue in GitHub so we can investigate. "
          values={{
            withdrawalFileName: <Code>{withdrawalFileName}</Code>,
          }}
        />
        <Link
          primary
          inline
          to="https://github.com/ethereum/staking-launchpad/issues/new"
        >
          Open issue
        </Link>
      </Text>
    )
  }

  const onFileDrop = async (jsonFiles: Array<any>, rejectedFiles: Array<any>) => {
    if (rejectedFiles?.length) {
      setFileError(
        <>
          <FormattedMessage defaultMessage="That is not a valid deposit_data JSON file." />
        </>
      )
      return
    }

    // check if the file is JSON
    if (jsonFiles.length > 0) {
      // Store the files in state in case of multiple uploads consecutively
      setJsonFilesArr((prev) => [...prev, ...jsonFiles])
      let _jsonFilesArr = jsonFiles
      // If there are multiple JSON files
      if (jsonFilesRef.current && jsonFilesRef.current.length > 1) {
        dispatchWithdrawalFileNameUpdate(`${jsonFilesRef.current[0].name} and ${jsonFilesRef.current.length - 1} more`)
        // Combine contents of all JSON files into one piece of text. They are of type File
        const newArray = []
        for (let i = 0; i < jsonFilesRef.current.length; i += 1) {
          const file = jsonFilesRef.current[i]
          // eslint-disable-next-line no-await-in-loop
          const text = await file.text()
          const parsedArr = JSON.parse(text)
          newArray.push(...parsedArr)
        }
        _jsonFilesArr = [new Blob([JSON.stringify(newArray)])]
      } else {
        dispatchWithdrawalFileNameUpdate(_jsonFilesArr[0].name)
      }

      setIsFileStaged(true) // unstaged via handleFileDelete
      setIsFileAccepted(true) // rejected if BLS check fails
      const reader = new FileReader()
      reader.onload = async (event) => {
        if (event.target) {
          try {
            const fileData: any[] = JSON.parse(event.target.result as string)
            const validatorCount = JSON.parse(event.target.result as string).length
            setValidatorCount(validatorCount)
            // perform BLS check
            if (await validateWithdrawalRequest(fileData as VoluntaryExitInterface[])) {
              // add valid files to redux
              dispatchWithdrawalSignaturesUpdate(
                fileData.map((file: VoluntaryExitInterface) => ({
                  ...file,
                  // transactionStatus: TransactionStatus.READY, // initialize each file with ready state for transaction
                  // depositStatus: DepositStatus.VERIFYING, // assign to verifying status until the pubkey is checked via beaconscan
                }))
              )

              // Check for duplicates, throw error if there are any
              const uniqueKeys = new Set(fileData.map((item) => JSON.stringify(item)))
              if (uniqueKeys.size !== fileData.length) {
                setFileError(<FormattedMessage defaultMessage="Duplicate keys found in JSON files." />)
                return
              }

              // perform double deposit check
              try {
                const existingDeposits = await getExistingDepositsForPubkeys(fileData)
                const existingDepositPubkeys = existingDeposits.data.flatMap((x) => x.publickey.substring(2))
                ;(fileData as VoluntaryExitInterface[]).forEach(async (file) => {
                  if (existingDepositPubkeys.includes((file as any).pubkey)) {
                    // dispatchDepositStatusUpdate(file.pubkey, DepositStatus.ALREADY_DEPOSITED, validatorCount)
                  } else {
                    // dispatchDepositStatusUpdate(file.pubkey, DepositStatus.READY_FOR_DEPOSIT, validatorCount)
                  }
                })
              } catch (error) {
                // dispatchBeaconChainAPIStatusUpdate(BeaconChainStatus.DOWN)
              }
            } else {
              // file is JSON but did not pass BLS, so leave it "staged" but not "accepted"
              setIsFileAccepted(false)
              dispatchWithdrawalSignaturesUpdate([])
              flushDropzoneCache()

              // there are a couple special cases that can occur
              const { fork_version: forkVersion } = fileData[0] || {}
              const hasCorrectStructure = validateWithdrawalRequestJsonStructure(fileData[0] || {})
              if (hasCorrectStructure && forkVersion !== GENESIS_FORK_VERSION.toString()) {
                // file doesn't match the correct network
                handleWrongNetwork()
              }
            }
          } catch (e) {
            // possible error example: json is invalid or empty so it cannot be parsed
            // TODO think about other possible errors here, and consider if we might want to set "isFileStaged"
            setValidatorCount(0)
            setIsFileAccepted(false)
            handleSevereError()
            dispatchWithdrawalSignaturesUpdate([])
            flushDropzoneCache()
          }
        }
      }
      reader.readAsText(_jsonFilesArr[0])
    }
  }

  const handleFileDelete = useCallback(
    (e: SyntheticEvent) => {
      e.preventDefault()
      dispatchWithdrawalFileNameUpdate('')
      dispatchWithdrawalSignaturesUpdate([])
      setFileError(null)
      setIsFileStaged(false)
      setIsFileAccepted(false)
      setJsonFilesArr([])
      flushDropzoneCache()
    },
    [dispatchWithdrawalSignaturesUpdate, dispatchWithdrawalFileNameUpdate, flushDropzoneCache]
  )

  const { isDragActive, isDragAccept, isDragReject, getRootProps, getInputProps } = useDropzone({
    accept: 'application/json',
    // noClick: isFileStaged || isFileAccepted,
    onDrop: onFileDrop,
  })

  const renderMessage = useMemo(() => {
    if (isDragReject && !isFileStaged) {
      return (
        <div>
          <FormattedMessage defaultMessage="Upload a valid json file." />
        </div>
      )
    }

    if (isFileStaged || fileError) {
      return (
        <ButtonContainer>
          <DeleteBtn onClick={handleFileDelete}>
            <Close size="15px" />
          </DeleteBtn>
          {fileError || (
            <>
              {isFileAccepted && <Text>{withdrawalFileName}</Text>}
              {!isFileAccepted && (
                <Text>
                  <FormattedMessage
                    defaultMessage="{withdrawalFileName} isn't a valid withdrawal_data JSON file. Try again."
                    values={{
                      withdrawalFileName: <span>{withdrawalFileName}</span>,
                    }}
                  />
                </Text>
              )}
            </>
          )}
        </ButtonContainer>
      )
    }

    return (
      <div>
        <FormattedMessage defaultMessage="Drag file to upload or browse" />
      </div>
    )
  }, [isDragReject, isFileStaged, isFileAccepted, fileError, withdrawalFileName, handleFileDelete])

  useEffect(() => {
    const hasAcceptedTermsStored = getFromLocalStorage('acceptedTerms')
    if (!hasAcceptedTermsStored) {
      setIsTermsModalOpen(true)
    } else {
      setHasAcceptedTerms(true)
    }
  }, [])

  return (
    <>
      <TermsModal
        isOpen={isTermsModalOpen}
        hasAcceptedTerms={hasAcceptedTerms}
        onClose={handleTermsModalClose}
        handleToggleTermsAccepted={handleToggleTermsAccepted}
      />
      <WorkflowPageTemplate>
        <div className="mt-[108px] flex flex-col items-center gap-y-4 text-center">
          <h1 className="text-[40px] leading-[60px]">Exit Validators</h1>
          <Text>
            Request a withdrawal.json file from the PierTwo team and{' '}
            <Link to={createBroadcastLink(chainId) as string}>broadcast it to the network</Link>
          </Text>
          {/*
          <h1 className="text-[40px] leading-[60px]">
            {isFileAccepted ? `Your Withdrawal Summary` : `Drop Message to Withdraw`}
          </h1>
          <div className="mx-auto">
            <Dropzone
              isFileStaged={isFileStaged}
              isFileAccepted={isFileAccepted && !fileError}
              {...getRootProps({ isDragActive, isDragAccept, isDragReject })}
            >
              <input {...getInputProps()} />
              <FileUploadAnimation
                isDragAccept={isDragAccept}
                isDragReject={isDragReject}
                isDragActive={isDragActive}
                isFileStaged={!!(isFileStaged || fileError)}
                isFileAccepted={isFileAccepted && !fileError}
              />

              <Text
                className="mt20"
                textAlign="center"
              >
                {renderMessage}
              </Text>
            </Dropzone>
          </div>
          <div
            className={cx('mx-auto mb-2 w-full max-w-[570px] justify-center gap-x-4', {
              flex: !isFileAccepted,
              'grid grid-cols-2': isFileAccepted,
            })}
          >
            {isFileAccepted && (
              <Button
                onClick={handleFileDelete}
                variant="secondary"
              >
                Clear
              </Button>
            )}
            <Button
              disabled={!isFileAccepted}
              onClick={onBroadcastVoluntaryExit}
              className="w-full"
            >
              Execute Withdrawal
            </Button>
          </div>
          */}
        </div>
      </WorkflowPageTemplate>
    </>
  )
}

_ManualWithdrawalsPage.displayName = 'ManualWithdrawalsPage'

const mapStateToProps = (state: StoreState): StateProps => ({
  withdrawalSignatures: state.withdrawalFile.signatures,
  withdrawalFileName: state.withdrawalFile.name,
})

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  // dispatchDepositStatusUpdate: (pubkey, depositStatus, validatorCount) =>
  // dispatch(updateDepositStatus(pubkey, depositStatus, validatorCount)),
  dispatchWithdrawalFileNameUpdate: (name) => dispatch(updateWithdrawalFileName(name)),
  dispatchWithdrawalSignaturesUpdate: (files: any) => dispatch(updateWithdrawalSignatures(files)),
  // dispatchBeaconChainAPIStatusUpdate: (status) => dispatch(updateBeaconChainAPIStatus(status)),
})

export const ManualWithdrawalsPage = connect<StateProps, DispatchProps, OwnProps, StoreState>(
  mapStateToProps,
  mapDispatchToProps
)(_ManualWithdrawalsPage)

export default ManualWithdrawalsPage

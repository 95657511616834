import { useMemo } from 'react'
import useSWR from 'swr'
import { BEACONCHAIN_URL } from '../utils/envVars'
import { fetcher, multiFetcher } from '../utils/data'
import { convertNumberToEthBn } from '../utils/numberFormatters'
import { BigNumber } from '@ethersproject/bignumber'
import { parseUnits } from '@ethersproject/units'
import { roundToMidnight } from '../utils/common'

interface IncomeHistory {
  // "attester_slashings": 0,
  // "day": 884,
  validatorindex: number
  day_end: string
  day_start: string
  end_balance: number
  start_balance: number
  withdrawals_amount: number
  deposits: number
  deposits_amount: number
  // end_effective_balance: number,
  // "max_balance": 32008093839,
  // "max_effective_balance": 32008093839,
  // "min_balance": 32005350440,
  // "min_effective_balance": 32000000000,
  // "missed_attestations": 0,
  // "missed_blocks": 0,
  // "missed_sync": 0,
  // "orphaned_attestations": 0,
  // "orphaned_blocks": 0,
  // "orphaned_sync": 0,
  // "participated_sync": 0,
  // "proposed_blocks": 0,
  // "proposer_slashings": 0,
  // "start_effective_balance": 32000000000,
  // "withdrawals": 0,
}

export enum EvntType {
  Deposit = 'Staking Deposit',
  Withdrawal = 'Staking Rewards - Withdraw',
  Reward = 'Staking Rewards',
  Block = 'Staking Rewards - Block',
}

export type ParsedIncomeHistory = {
  validatorIndex: number
  timestamp: string
  changeInBalance: BigNumber
  balance: BigNumber
  type: EvntType
  // ethPrice at time of event
  ethPrice: BigNumber
}

const parseIncomeHistory = (
  data: IncomeHistory[],
  ethPriceMap: Record<number, number>
): {
  allEvents: ParsedIncomeHistory[]
  deposits: ParsedIncomeHistory[]
  withdrawals: ParsedIncomeHistory[]
  isLoading: boolean
} => {
  const allEvents: ParsedIncomeHistory[] = []
  const deposits: ParsedIncomeHistory[] = []
  const withdrawals: ParsedIncomeHistory[] = []
  data.forEach((d) => {
    // TODO decide if I want start or end
    const day = d.day_end

    const ethPrice = convertNumberToEthBn(ethPriceMap[roundToMidnight(Date.parse(day) / 1000)])
    if (d.deposits_amount > 0) {
      const depsosit = {
        validatorIndex: d.validatorindex,
        timestamp: day,
        changeInBalance: parseUnits(d.deposits_amount.toString(), 9),
        type: EvntType.Deposit,
        balance: parseUnits((d.end_balance ?? d.deposits_amount).toString(), 9),
        ethPrice,
      }
      allEvents.push(depsosit)
      deposits.push(depsosit)
    } else {
      if (d.withdrawals_amount > 0) {
        const withdrawal = {
          validatorIndex: d.validatorindex,
          timestamp: day,
          changeInBalance: parseUnits(d.withdrawals_amount.toString(), 9).mul(-1),
          type: EvntType.Withdrawal,
          balance: parseUnits(d.end_balance.toString(), 9),
          ethPrice,
        }
        allEvents.push(withdrawal)
        withdrawals.push(withdrawal)
      }
      allEvents.push({
        validatorIndex: d.validatorindex,
        timestamp: day,
        changeInBalance: parseUnits((d.end_balance + d.withdrawals_amount - d.start_balance).toString(), 9),
        type: EvntType.Reward,
        balance: parseUnits(d.end_balance.toString(), 9),
        ethPrice,
      })
    }
  })

  return {
    allEvents: allEvents.sort((a, b) => Date.parse(a.timestamp) - Date.parse(b.timestamp)),
    deposits,
    withdrawals,
    isLoading: false,
  }
}

export const useValidatorsIncomeData = (
  validators: string,
  ethPrices: any,
  shouldFetchData: boolean
): {
  allEvents: ParsedIncomeHistory[]
  deposits: ParsedIncomeHistory[]
  withdrawals: ParsedIncomeHistory[]
  isLoading: boolean
} => {
  const {
    data: withdrawalsData,
    error: withdrawalsError,
    isLoading: withdrawalsIsLoading,
  } = useSWR(
    !!validators && shouldFetchData ? `${BEACONCHAIN_URL}/api/v1/validator/${validators}/withdrawals` : null,
    fetcher
  )

  const {
    data: incomeData,
    // error: incomeDataError,
    // isLoading: incomeDataIsLoading,
  } = useSWR(
    !!validators && shouldFetchData && !withdrawalsIsLoading && !withdrawalsError
      ? validators.split(',').map((v) => `${BEACONCHAIN_URL}/api/v1/validator/stats/${v}`)
      : null,
    multiFetcher
  )

  return useMemo(() => {
    if (!withdrawalsData || !incomeData || !ethPrices.length) {
      return {
        allEvents: [],
        deposits: [],
        withdrawals: [],
        isLoading: true,
      }
    }

    const joinedIncomeHistory = incomeData.reduce((a: any, h: any) => a.concat(h.data), [])

    console.debug('Withdrawals', withdrawalsData)
    console.debug('Income History', joinedIncomeHistory)

    const ethPriceMap: Record<number, number> = ethPrices.reduce(
      (o: Record<number, number>, price: any) => ({ ...o, [price[0] / 1000]: price[1] }),
      {} as Record<number, number>
    )

    return parseIncomeHistory(joinedIncomeHistory, ethPriceMap)
  }, [withdrawalsData, incomeData, ethPrices])
}

export default useValidatorsIncomeData

import ArrowDownRedIcon from '../../static/arrow-down-red.svg'
import ArrowUpGreenIcon from '../../static/arrow-up-green.svg'

const UpOrDownArrow = ({ difference }: { difference: number }) => {
  if (difference > 0) {
    return (
      <img
        src={ArrowUpGreenIcon}
        alt="arrow up"
        className="h-6 w-6"
      />
    )
  } else if (difference < 0) {
    return (
      <img
        src={ArrowDownRedIcon}
        alt="arrow down"
        className="h-6 w-6"
      />
    )
  } else {
    return null
  }
}

export default UpOrDownArrow

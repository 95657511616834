// @ts-nocheck
import { Alert, Checkmark } from 'grommet-icons'
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import styled from 'styled-components'
import Laptop from '../static/laptop.svg'
import Mobile from '../static/mobile.svg'
import { Button } from './Button'
import { Heading } from './Heading'
import { Link } from './Link'
import { Text } from './Text'

const RainbowBackground = styled.div`
  background-image: ${(p) => `radial-gradient(circle at 100% -80%, ${p.theme.rainbowLight}`});
  min-height: 100vh;
`

const Container = styled.div`
  padding: 150px 30px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
const Image = styled.img`
  width: 70px;
  height: 60px;
`
const ImageContainer = styled.div`
  display: flex;
  justify-content: space-around;
  background-color: ${(p) => p.theme.gray.light};
  border: 1px solid ${(p: any) => p.theme.gray.medium};
  padding: 20px;
  border-radius: ${(p) => p.theme.borderRadius};
  margin: 50px 0;
`
const ImageBackground = styled.div`
  background-color: ${(p: { mobile?: boolean }) =>
    p.mobile ? (p as any).theme.red.light : (p as any).theme.green.medium};
  padding: 25px;
  border-radius: 50%;
`
const IconStyles = {
  margin: 'auto',
  marginTop: '10px',
}

export const DesktopOnlyModal = (): JSX.Element => {
  const { formatMessage } = useIntl()

  return (
    <RainbowBackground>
      <Container>
        <Heading center>
          <FormattedMessage defaultMessage="Desktop only" />
        </Heading>
        <Text center>
          <FormattedMessage defaultMessage="You must be on a desktop device to use this app." />
        </Text>
        <ImageContainer>
          <div className="flex-column flex">
            <ImageBackground mobile>
              <Image
                src={Mobile}
                alt=""
              />
            </ImageBackground>
            <Alert
              size="large"
              color="redLight"
              style={IconStyles}
            />
          </div>
          <div className="flex-column flex">
            <ImageBackground>
              <Image
                src={Laptop}
                alt=""
              />
            </ImageBackground>
            <Checkmark
              size="large"
              color="green"
              style={IconStyles}
            />
          </div>
        </ImageContainer>
        <Link to="/">
          <Button
            fullWidth
            label={formatMessage({ defaultMessage: 'Close' })}
          />
        </Link>
      </Container>
    </RainbowBackground>
  )
}

// @ts-nocheck
import React from 'react'
import ReactMarkdown from 'react-markdown'
import RemarkMathPlugin from 'remark-math'
import rehypeRaw from 'rehype-raw'
import remarkGfm from 'remark-gfm'
import rehypeSlug from 'rehype-slug'

export const MarkdownContent = (props) => {
  const newProps = {
    ...props,
    plugins: [RemarkMathPlugin],
    components: {
      // eslint-disable-next-line no-shadow
      h1({ children, ...props }) {
        return (
          <h2
            {...props}
            style={{
              lineHeight: 1.5,
            }}
          >
            {children}
          </h2>
        )
      },
      // eslint-disable-next-line no-shadow
      img({ children, ...props }) {
        // eslint-disable-next-line react/destructuring-assignment
        return (
          <img
            className="rounded"
            alt={props.alt ?? ''}
            {...props}
          />
        )
      },
      // eslint-disable-next-line no-shadow
      a({ children, ...props }) {
        return (
          <a
            target="_blank"
            rel="noopener noreferrer"
            // eslint-disable-next-line react/destructuring-assignment
            href={props.href}
            {...props}
          >
            {children}
          </a>
        )
      },
      // eslint-disable-next-line no-shadow
      pre({ children, ...props }) {
        return (
          <p
            {...props}
            style={{
              whiteSpace: 'break-spaces',
            }}
          >
            {children}
          </p>
        )
      },
    },
  }

  return (
    <ReactMarkdown
      {...newProps}
      rehypePlugins={[rehypeRaw, remarkGfm, rehypeSlug]}
    />
  )
}

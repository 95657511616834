/*
  eslint-disable camelcase
 */
import { PUBLIC_CL_CLIENT } from './envVars'
import _every from 'lodash/every'
// import { initBLS } from '@chainsafe/bls'
// import compareVersions from 'compare-versions'
// import axios from 'axios'
// import { verifySignature } from '../../utils/verifySignature'
// import { verifyDepositRoots } from '../../utils/SSZ'
// import { DepositKeyInterface, BeaconchainDepositInterface } from '../../store/reducers'
// import { ETHER_TO_GWEI, BEACONCHAIN_URL, MIN_DEPOSIT_AMOUNT, MIN_DEPOSIT_CLI_VERSION } from '../../utils/envVars'

export type VoluntaryExitInterface = {
  message: {
    epoch: string
    validator_index: string
  }
  signature: string
}

export const validateWithdrawalRequestJsonStructure = (withdrawalDatum: VoluntaryExitInterface): boolean => {
  // check existence of required keys
  if (
    !withdrawalDatum.message ||
    !withdrawalDatum.message?.epoch ||
    !withdrawalDatum.message?.validator_index ||
    !withdrawalDatum.signature
  ) {
    return false
  }

  // check type of values
  if (
    typeof withdrawalDatum.message !== 'object' ||
    typeof withdrawalDatum.message?.epoch !== 'string' ||
    typeof withdrawalDatum.message?.validator_index !== 'string' ||
    typeof withdrawalDatum.signature !== 'string'
  ) {
    return false
  }

  // check length of strings (note: using string length, so 1 byte = 2 chars)
  if (withdrawalDatum.signature.length !== 192) {
    return false
  }

  return true
}

export const validateWithdrawalRequest = async (files: VoluntaryExitInterface[]): Promise<boolean> => {
  // await initBLS()

  if (!Array.isArray(files)) return false
  if (files.length <= 0) return false

  const withdrawalStatuses: boolean[] = files.map((withdrawalDatum) => {
    if (!validateWithdrawalRequestJsonStructure(withdrawalDatum)) {
      return false
    }
    // if (!verifyDepositRoots(withdrawalDatum)) {
    // return false
    // }
    // return verifySignature(withdrawalDatum)
    return true
  })
  return _every(withdrawalStatuses)
}

// export const getExistingDepositsForPubkeys = async (
// files: DepositKeyInterface[]
// ): Promise<BeaconchainDepositInterface> => {
// const pubkeys = files.flatMap((x) => x.pubkey)
// const beaconScanUrl = `${BEACONCHAIN_URL}/api/v1/validator/${pubkeys.join(',')}/deposits`
// const { data: beaconScanCheck } = await axios.get<BeaconchainDepositInterface>(beaconScanUrl)

// if (!beaconScanCheck.data || beaconScanCheck.status !== 'OK') {
// throw new Error('Beaconchain API is down')
// }
// return beaconScanCheck
// }

enum BroadcastExitResult {
  SUCCESS,
  INVALID,
  FAILED,
}

export const broadcastVoluntaryExitInterface = async (
  exitMessage: VoluntaryExitInterface
): Promise<BroadcastExitResult> => {
  try {
    const res = await fetch(`${PUBLIC_CL_CLIENT}/eth/v1/beacon/pool/voluntary_exits`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(exitMessage),
    }).then((res) => {
      if (!res.ok) {
        throw new Error(`Exit request failed ${res.status}`)
      }
      return res.json()
    })
    console.debug('Successfully broadcastes voluntary exit', res)
    return BroadcastExitResult.SUCCESS
  } catch (err) {
    console.debug('Failed to broadcast voluntary exit', err)
    return BroadcastExitResult.FAILED
  }
}

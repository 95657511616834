import React from 'react'
// Types
import { Link } from 'react-router-dom'
import { RoutesEnum } from '../../Routes'
import { Container } from '../../components/Container'
import { Footer } from '../../components/Footer'
import SuccessVideo from '../../static/success-globe.mp4'
import { IS_MAINNET } from '../../utils/envVars'

type Props = {
  type: 'Deposit' | 'Withdraw'
}

export const CongratulationsPage: React.FC<Props> = ({ type }) => (
  <main className="flex min-h-[calc(100vh-48px)] flex-col">
    <section className="h-full flex-1 pb-20">
      <Container>
        <div className="mx-auto mt-[150px] flex w-max flex-col items-center gap-y-10">
          <h1 className="text-center text-[40px] leading-[60px]">{type} Successful</h1>
          <div className="relative h-[219px] w-[268px] overflow-hidden px-8 py-5">
            <div className="relative z-10 flex flex-col gap-y-1 text-2xl tracking-[-0.44px]">
              <span className="w-max bg-pt-light-indigo pl-1 pr-4">Providing trust</span>
              <span className="w-max bg-pt-light-indigo pl-1 pr-5">to transactions</span>
            </div>
            <video
              autoPlay
              loop
              muted
              className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
            >
              <source
                src={SuccessVideo}
                type="video/mp4"
              />
            </video>
          </div>
          <div className="flex flex-col items-center gap-y-4">
            <span className="text-center text-[40px] leading-[60px]">Now in Queue</span>
            {IS_MAINNET && (
              <Link to={RoutesEnum.validatorsPage}>
                <span className="text-center text-[40px] leading-[60px]">
                  Track your <span className="underline">Validators</span>
                </span>
              </Link>
            )}
          </div>
        </div>
      </Container>
    </section>
    <Footer />
  </main>
)

export default CongratulationsPage

import { Heading as H } from 'grommet'
import styled from 'styled-components'

export const Heading = styled(H)`
  color: ${(p) => (p.color ? p.color : p.theme.blue.dark)};
  margin: 0;
  display: block;
  text-align: ${(p: { center?: boolean }) => p.center && 'center'};
  max-width: inherit;
`

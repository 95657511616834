import cx from 'classnames'
import React, { FC } from 'react'
import { Container } from '../../../components/Container'
import MeshBarLeft from '../../../static/bar-left.png'
import MeshBarRight from '../../../static/bar-right.png'
import { InternalPages } from '../'

interface SwitchViewBarProps {
  currentView: InternalPages
  handleSwitchView: (view: InternalPages) => void
  isDisabled: boolean
}

export const SwitchViewBar: FC<SwitchViewBarProps> = ({ currentView, handleSwitchView, isDisabled }) => (
  <nav className="fixed left-0 top-12 z-40 h-10 w-full overflow-hidden border-b border-pt-dark-indigo bg-white font-sans">
    <Container className="flex h-full items-center">
      <img
        src={MeshBarLeft}
        className="hidden h-9 w-[102px] md:inline-block"
      />
      <div className="mx-auto grid min-w-[300px] grid-cols-2 whitespace-nowrap px-12 text-xs md:mx-0 md:gap-x-24">
        <button
          onClick={() => handleSwitchView(InternalPages.List)}
          disabled={isDisabled}
          className={cx({
            'font-bold text-pt-dark-indigo': currentView === InternalPages.List,
          })}
        >
          My List
        </button>
        <button
          onClick={() => handleSwitchView(InternalPages.Stats)}
          disabled={isDisabled}
          className={cx({
            'font-bold text-pt-dark-indigo': currentView === InternalPages.Stats,
          })}
        >
          My Stats
        </button>
      </div>
      <img
        src={MeshBarRight}
        className="hidden h-9 w-[990px] min-w-[990px] translate-x-20 object-cover md:inline-block"
      />
    </Container>
  </nav>
)

export default SwitchViewBar

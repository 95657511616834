import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { BigNumber } from '@ethersproject/bignumber'
import { Web3Provider } from '@ethersproject/providers'
import { formatEther, formatUnits, parseEther } from '@ethersproject/units'
import { useWeb3React } from '@web3-react/core'
import cx from 'classnames'
import { produce } from 'immer'
import { modules as ReactExport } from 'react-data-export'
import ReactTooltip from 'react-tooltip'
import { Box, DateInput } from 'grommet'
import { useIntl } from 'react-intl'
import { connect, useSelector } from 'react-redux'
import { Dispatch } from 'redux'
import styled from 'styled-components'
import { Container } from '../../components/Container'
import { Button } from '../../components/CustomButton/index'
import { Spinner } from '../../components/Spinner'
import { WorkflowPageTemplate } from '../../components/WorkflowPage/WorkflowPageTemplate'
import { EthPriceData, useHistoricalEthPrices } from '../../hooks/useHistoricalEthPrices'
import { UserData, useUserData } from '../../hooks/useUserData'
import { usePendingValidators, PendingValidator, RemovePendingValidators } from '../../hooks/usePendingValidators'
import { useUsersValidators, UserValidator } from '../../hooks/useUsersValidators'
import { useValidatorWaitTimes } from '../../hooks/useValidatorWaitTimes'
import { useValidatorGraphs, GraphData } from '../../hooks/useValidatorGraphs'
import { EvntType, ParsedIncomeHistory } from '../../hooks/useValidatorsIncomeData'
import { useValidatorsRewardsBreakdown, ValidatorRewardsBreakdown } from '../../hooks/useValidatorsRewardsBreakdown'
import AudCurrencyIcon from '../../static/aud.png'
import ChevronDownIcon from '../../static/chevron-down.svg'
import EthCurrencyIcon from '../../static/eth-currency.svg'
import UsdCurrencyIcon from '../../static/usd.png'
import {
  DispatchUpdateMaxIndex,
  DispatchUpdateValidators,
  DispatchUpdateValidatorsName,
  IndexValidationAction,
  NameValidatorsAction,
  ValidatorsAction,
  updateIndexValidation,
  updateValidators,
  updateValidatorsName,
} from '../../store/actions/validatorsActions'
import { DispatchConnectWallet, WalletAction, updateWalletAction } from '../../store/actions/walletActions'
import { DepositKeyInterface, StoreState, ValidatorsState } from '../../store/reducers'
import { clearUrlValidators, getFromLocalStorage, getFromUrl } from '../../utils/localStorage'
import { convertDecimalStringToEthBn } from '../../utils/numberFormatters'
import AddValidators from './AddValidators'
import { CheckBox } from './CheckBox'
import { SwitchViewBar } from './SwitchViewBar'
import ValidatorList from './ValidatorList'
import {
  Currency,
  DateRangeType,
  DateRange,
  GraphAprData,
  GraphIncomeData,
  GraphRewardsBreakdown,
  GraphStakingData,
} from './types'
import { WaitTimes } from '../../types/validators'
import { ValidatorStats } from './ValidatorStats'
import { ValidatorStatsHeader } from './ValidatorStatsHeader'
import {
  allRewards,
  calcApr,
  getFilterTimestamp,
  getInitialFilterTimestamp,
  timeFilter,
  CustomDateRange,
  getCurrentWeekISOStrings,
  parseCustomDateRange,
  formatCustomDateRange,
} from './validatorUtils'

// AMOUNT (18 decimals) * ETH_PRICE (18 decimals)
const ETH_PRICE_DECIMALS = 36
const DEFAULT_CURRENCY = Currency.AUD

const LoadingScreen = () => {
  return <Spinner className="mt-10" />
}

const StyledDatePicker = styled(DateInput)`
  height: 30px;
  max-height: 30px;
`

type ValidatorExcelData = {
  pubKey: string
  totalEthEarnt: number
  apr: number
}

const formatValidatorOverview: (
  validators: UserData['validators'],
  depositsAndWithdraws: ParsedIncomeHistory[],
  ethPrices: EthPriceData['prices'],
  currency: Currency,
  dateRange: DateRange
) => any[] = (validators, depositsAndWithdraws, ethPrices, currency_, dateRange) => {
  const currency = currency_ !== Currency.ETH ? currency_ : DEFAULT_CURRENCY
  let ethPrice = BigNumber.from('0')
  let to = new Date().toISOString().split('T')[0]
  const rangeFilter = getFilterTimestamp(dateRange)
  let from = rangeFilter ? new Date(rangeFilter.from).toISOString().split('T')[0] : to

  if (ethPrices.length !== 0) {
    ethPrice = convertDecimalStringToEthBn(ethPrices[ethPrices.length - 1][1])
  }

  const validatorTotals: Record<
    number,
    {
      index: number
      earntTotalEth: BigNumber
      earntTotal: BigNumber
      balanceEth: BigNumber
      balance: BigNumber
    }
  > = {}
  const totalStakingIncome: any[] = [],
    validatorBalances: any[] = []

  let summedBalance = BigNumber.from('0'),
    summedBalanceEth = BigNumber.from('0')

  validators.forEach(
    ({
      pubKey,
      index,
      balance = BigNumber.from('0'),
      earntTotal = {
        el: BigNumber.from('0'),
        cl: BigNumber.from('0'),
      },
    }) => {
      const parsedValidator = {
        index: index,
        earntTotalEth: allRewards(earntTotal),
        earntTotal: allRewards(earntTotal).mul(ethPrice),
        balanceEth: balance,
        balance: balance.mul(ethPrice),
      }

      summedBalance = summedBalance.add(parsedValidator.balance)
      summedBalanceEth = summedBalanceEth.add(parsedValidator.balanceEth)

      totalStakingIncome.push([
        { value: parsedValidator.index.toString() },
        { value: formatEther(parsedValidator.earntTotalEth) }, // totalEthEarnt,
        // TODO this is current valuation of ETH
        { value: formatUnits(parsedValidator.earntTotal, ETH_PRICE_DECIMALS) }, // toatlEarnt
      ])
      validatorBalances.push([
        { value: parsedValidator.index.toString() },
        { value: formatEther(parsedValidator.balanceEth) },
        { value: formatUnits(parsedValidator.balance, ETH_PRICE_DECIMALS) },
      ])
      validatorTotals[index] = parsedValidator
    }
  )

  const totalValidatorBalance = [
    boldCell('Total Validator Balance'),
    boldCell(+formatEther(summedBalanceEth)),
    boldCell(+formatUnits(summedBalance, ETH_PRICE_DECIMALS)),
  ]

  const validatorAggregatedWithdraws = depositsAndWithdraws.reduce((o, v) => {
    const ethPrice = v.ethPrice
    let validatorInfo = o[v.validatorIndex]
      ? o[v.validatorIndex]
      : {
          currentEval: validatorTotals[v.validatorIndex]?.balance ?? BigNumber.from('0'),
          costOfDeposit: BigNumber.from('0'),
          unrealisedGain: BigNumber.from('0'),
          withdrawals: BigNumber.from('0'),
        }
    if (v.type === EvntType.Deposit) {
      validatorInfo.costOfDeposit = v.balance.mul(ethPrice)
      validatorInfo.unrealisedGain = validatorInfo.currentEval.sub(validatorInfo.costOfDeposit)
    } else if (v.type === EvntType.Withdrawal) {
      // withdrawals have a negative changeInBalance
      validatorInfo.withdrawals = validatorInfo.withdrawals.add(v.changeInBalance.mul(ethPrice).abs())
    }
    return {
      ...o,
      [v.validatorIndex]: validatorInfo,
    }
  }, {} as Record<string, { withdrawals: BigNumber; costOfDeposit: BigNumber; currentEval: BigNumber; unrealisedGain: BigNumber }>)

  let totalDeposited = BigNumber.from('0'),
    totalRealisedGains = BigNumber.from('0'),
    totalWithdrawn = BigNumber.from('0'),
    totalUnrealisedGains = BigNumber.from('0')
  const realisedGains = Object.keys(validatorAggregatedWithdraws).map((index: string) => {
    const v = validatorAggregatedWithdraws[index]

    // TODO this is referring to full withdraws
    totalWithdrawn = totalWithdrawn.add(0)
    totalRealisedGains = totalRealisedGains.add(v.withdrawals)
    totalDeposited = totalDeposited.add(v.costOfDeposit)
    totalUnrealisedGains = totalRealisedGains.add(v.unrealisedGain)

    return [
      { value: index },
      { value: +formatUnits(v.costOfDeposit, ETH_PRICE_DECIMALS) }, // cost of deposit,
      { value: 0 }, // cost of withdrawal
      { value: +formatUnits(v.withdrawals, ETH_PRICE_DECIMALS) }, // realised gains (cost of deposit - cost of withdrawal)
      { value: +formatUnits(v.unrealisedGain, ETH_PRICE_DECIMALS) }, // unrealised gains (current valuation - cost of deposit)
    ]
  })

  const totalGains = [
    boldCell('Total'),
    boldCell(+formatUnits(totalDeposited, ETH_PRICE_DECIMALS)),
    boldCell(+formatUnits(totalWithdrawn, ETH_PRICE_DECIMALS)),
    boldCell(+formatUnits(totalRealisedGains, ETH_PRICE_DECIMALS)),
    boldCell(+formatUnits(totalUnrealisedGains, ETH_PRICE_DECIMALS)),
  ]

  const t = [
    {
      columns: [
        {
          title: `Summary Income Report (${from} - ${to})`,
          width: { wpx: 300 },
          style: {
            font: { color: { rgb: 'FFFFFF' } },
            fill: {
              patternType: 'solid',
              fgColor: { rgb: '000FFF' },
            },
          },
        },
        {
          title: '',
          width: { wpx: 130 },
        },
        {
          title: '',
          width: { wpx: 130 },
        },
        {
          title: '',
          width: { wpx: 180 },
        },
        {
          title: '',
          width: { wpx: 180 },
        },
      ],
      data: [
        [
          titleCell('Staking Income'),
          boldCell('ETH'),
          boldCell(currency),
          // TODO dont really know what these are
          // boldCell("ETH Claimed"),
          // boldCell("ETH Staking Receivable"),
        ],
        ...totalStakingIncome,
        [],
        [titleCell(`Current Validator Balances at ${to}`), boldCell('ETH'), boldCell(currency)],
        ...validatorBalances,
        totalValidatorBalance,
        [],
        [
          titleCell('Gains and Losses on Validator'),
          boldCell('Cost at Deposit'),
          boldCell('Cost at Withdrawal'),
          boldCell('Realised Gains / Losses'),
          boldCell('Unrealised Gains / Losses'),
        ],
        ...realisedGains,
        totalGains,
      ],
    },
  ]
  return t
}

const titleCell = (v: string) => ({
  value: v,
  style: { font: { color: { rgb: '000FFF' } } },
})
const boldCell = (v: string | number) => ({
  value: v,
  style: { font: { bold: true } },
})

const formatValidatorTransactions: (
  allEvents: UserData['validatorIncomeData']['allEvents'],
  currency: Currency
) => any[] = (allEvents, currency_) => {
  const currency = currency_ !== Currency.ETH ? currency_ : DEFAULT_CURRENCY
  const data = allEvents.map((data) => {
    const ethPrice = data.ethPrice
    return [
      {
        value: new Date(data.timestamp).toISOString().split('T')[0],
        style: {
          border: {
            right: { style: 'thin', color: { rgb: 'BEC0BF' } },
            bottom: { style: 'thin', color: { rgb: 'BEC0BF' } },
          },
          font: { bold: true },
          fill: { patternType: 'solid', fgColor: { rgb: 'DCDCDC' } },
        },
      },
      { value: data.validatorIndex },
      { value: data.type },
      { value: +formatEther(ethPrice) },
      { value: +formatEther(data.changeInBalance) },
      { value: +formatEther(data.balance) },
      { value: +formatUnits(data.changeInBalance.mul(ethPrice), ETH_PRICE_DECIMALS) },
      { value: +formatUnits(data.balance.mul(ethPrice), ETH_PRICE_DECIMALS) },
    ]
  })

  return [
    {
      columns: [
        {
          title: 'Timestamp',
          width: { wpx: 90 },
          style: {
            font: { bold: true },
            fill: { patternType: 'solid', fgColor: { rgb: 'BEC0BF' } },
          },
        },
        {
          title: 'Validator ID',
          width: { wpx: 80 },
          style: {
            font: { bold: true },
            fill: { patternType: 'solid', fgColor: { rgb: 'BEC0BF' } },
          },
        },
        {
          title: 'Type',
          width: { wpx: 180 },
          style: {
            font: { bold: true },
            fill: { patternType: 'solid', fgColor: { rgb: 'BEC0BF' } },
          },
        },
        {
          title: `ETH/${currency}`,
          width: { wpx: 130 },
          style: {
            font: { bold: true },
            fill: { patternType: 'solid', fgColor: { rgb: 'BEC0BF' } },
          },
        },
        {
          title: 'Amount (ETH)',
          width: { wpx: 90 },
          style: {
            font: { bold: true },
            fill: { patternType: 'solid', fgColor: { rgb: 'BEC0BF' } },
          },
        },
        {
          title: 'Total Validator Balance (ETH)',
          width: { wpx: 180 },
          style: {
            font: { bold: true },
            fill: { patternType: 'solid', fgColor: { rgb: 'BEC0BF' } },
          },
        },
        {
          title: `Amount (${currency})`,
          width: { wpx: 110 },
          style: {
            font: { bold: true },
            fill: { patternType: 'solid', fgColor: { rgb: 'BEC0BF' } },
          },
        },
        {
          title: `Total Validator Balance (${currency})`,
          width: { wpx: 180 },
          style: {
            font: { bold: true },
            fill: { patternType: 'solid', fgColor: { rgb: 'BEC0BF' } },
          },
        },
      ],
      data,
    },
  ]
}

type DownloadProps = {
  validators: UserData['validators']
  validatorIncomeData: UserData['validatorIncomeData']
  ethPrices: EthPriceData['prices']
  currency: Currency
  selectedValidators: Record<string, boolean>
  dateRange: DateRange
}

const Download: React.FC<DownloadProps> = ({
  validators,
  validatorIncomeData,
  ethPrices,
  currency,
  selectedValidators,
  dateRange,
}) => {
  const { allEvents, depositsAndWithdraws } = useMemo(() => {
    const filteredAll = validatorIncomeData.allEvents.filter((v) => selectedValidators[v.validatorIndex])
    const timeFilteredAll = timeFilter(dateRange, filteredAll)

    const timeFilteredDepositsAndWithdraws = timeFilteredAll.filter(
      (v) => v.type === EvntType.Withdrawal || v.type === EvntType.Deposit
    )

    return {
      allEvents: timeFilteredAll,
      depositsAndWithdraws: timeFilteredDepositsAndWithdraws,
    }
  }, [validatorIncomeData.allEvents, selectedValidators, dateRange])

  const filteredValidators = useMemo(
    () => validators.filter((v) => selectedValidators[v.pubKey]),
    [validators, selectedValidators]
  )

  const validatorOverview = formatValidatorOverview(
    filteredValidators,
    depositsAndWithdraws,
    ethPrices,
    currency,
    dateRange
  )
  const validatorTxns = formatValidatorTransactions(allEvents, currency)

  return (
    <ReactExport.ExcelFile
      filename="validators-report"
      element={<button className="text-white">CSV Download</button>}
    >
      <ReactExport.ExcelSheet
        dataSet={validatorOverview}
        name="Validators Report"
      />
      <ReactExport.ExcelSheet
        dataSet={validatorTxns}
        name="Full Transaction History"
      />
    </ReactExport.ExcelFile>
  )
}

const newListButton =
  'inline-flex h-[30px] min-w-[116px] w-[116px] items-center transition-colors whitespace-nowrap justify-center rounded-[7px] bg-pt-orange font-sourceSansPro font-semibold text-white shadow-button hover:bg-pt-orange-dark'

export enum InternalPages {
  Upload,
  List,
  Stats,
}

type SetState<G> = React.Dispatch<React.SetStateAction<G>>

// Prop definitions
interface OwnProps {
  waitTimes: WaitTimes | undefined
  usersValidators: UserValidator[]
  rewardsBreakdown: Record<string, ValidatorRewardsBreakdown>
  currentEthPrice: EthPriceData['currentEthPrice']
  ethPrices: EthPriceData['prices']
  validators: UserData['validators']
  validatorIncomeData: UserData['validatorIncomeData']
  isLoadingPerformance: boolean
  isLoadingIncomeData: boolean
  graphIncomeData: GraphData['graphIncomeData']
  graphStakingData: GraphData['graphStakingData']
  graphAprData: GraphData['graphAprData']
  rewardTotals: GraphData['rewardTotals']
  dateRange: DateRange
  setDateRange: SetState<DateRange>
  currency: Currency
  setCurrency: SetState<Currency>
  setShouldGenerateList: SetState<boolean>
}
interface StateProps {
  depositKeys: DepositKeyInterface[]
  validatorsList: ValidatorsState['validatorsList']
  validatorUploads: ValidatorsState['uploads']
  validatorsNames: ValidatorsState['names']

  invalidValidators: Record<string, boolean>
  hasValidatedList: boolean
  hasInvalidList: boolean
}
interface DispatchProps {
  addValidators: DispatchUpdateValidators
  removeValidators: DispatchUpdateValidators
  nameValidators: DispatchUpdateValidatorsName
  clearValidators: () => void
  setMaxIndex: DispatchUpdateMaxIndex
}

type InjectedProps = StateProps & DispatchProps & OwnProps
export const _InjectedValidatorPage = ({
  // page props
  depositKeys,
  validatorsList,
  addValidators,
  removeValidators,
  clearValidators,
  setMaxIndex,
  validatorsNames,
  validatorUploads,
  nameValidators,
  hasValidatedList,
  hasInvalidList,
  invalidValidators,

  // own props
  waitTimes,
  usersValidators,
  rewardsBreakdown,
  currentEthPrice,
  ethPrices,
  validators,
  validatorIncomeData,
  isLoadingPerformance,
  isLoadingIncomeData,
  graphIncomeData,
  graphStakingData,
  graphAprData,
  rewardTotals,
  dateRange,
  setDateRange,
  currency,
  setCurrency,
  setShouldGenerateList,
}: InjectedProps): JSX.Element => {
  const { locale } = useIntl()

  const [internalPage, setInternalPage] = useState(InternalPages.Upload)
  const [selectedValidators, setSelectedValidators] = useState<Record<string, boolean>>({})

  // pending validators which dont yet have a public key
  const { pendingValidators, removePendingValidators } = usePendingValidators()
  useEffect(() => {
    const v1 = validators.map((v) => v.pubKey)
    const v2 = usersValidators
      .filter((v: { index: number | null }) => !!v.index)
      .map((v: { pubKey: string }) => v.pubKey)
    // prunes any duplicates that might exist
    removePendingValidators(v1.concat(v2))
  }, [validators, usersValidators])

  useEffect(() => {
    if (!!usersValidators.length) {
      addValidators(
        usersValidators.reduce((s: string, v: { index: number }) => (!s ? `${v.index}` : `${s},${v.index}`), ``)
      )
    }
  }, [usersValidators])

  // load storage validators in localStorage
  useEffect(() => {
    const urlParams = getFromUrl('validators')
    const locallyStoredValidators = getFromLocalStorage('validators')
    if (!!urlParams) {
      addValidators(urlParams)
    } else if (!!locallyStoredValidators) {
      addValidators(locallyStoredValidators)
    }

    const storedNames = getFromLocalStorage('names')
    if (storedNames) {
      try {
        const parsed = JSON.parse(storedNames)
        nameValidators(parsed)
      } catch (_err) {
        // do nothing
      }
    }
  }, [])

  useEffect(() => {
    if (waitTimes?.maxIndex) {
      setMaxIndex(waitTimes.maxIndex)
    }
  }, [waitTimes?.maxIndex])

  const parsedRewardsBreakdown: GraphRewardsBreakdown[] = useMemo(() => {
    const totals = Object.values(rewardsBreakdown).reduce(
      (o, v) => {
        return {
          ...o,
          head: o.head.add(v.headRewards),
          target: o.target.add(v.targetRewards),
          source: o.source.add(v.sourceRewards),
        }
      },
      {
        head: BigNumber.from('0'),
        source: BigNumber.from('0'),
        target: BigNumber.from('0'),
        sync: BigNumber.from('0'),
      }
    )

    return Object.keys(totals).map((k) => ({
      type: k as GraphRewardsBreakdown['type'],
      rewards: Number(formatUnits(totals[k as GraphRewardsBreakdown['type']])),
    }))
  }, [rewardsBreakdown])

  const { numberOfValidators, validatorShare, blocksProposed, biggestBlockReward } = useMemo(() => {
    if (!waitTimes) {
      return {
        numberOfValidators: 0,
        validatorShare: 0,
        blocksProposed: 0,
        biggestBlockReward: BigNumber.from('0'),
      }
    }
    const numberOfValidators = validators.length
    const validatorShare = numberOfValidators / waitTimes.activeValidators
    const { blocksProposed, biggestBlockReward } = validatorIncomeData.allEvents.reduce(
      (o, e) => {
        if (e.type === EvntType.Block) {
          o.blocksProposed = o.blocksProposed + 1
          // store the max
          if (o.biggestBlockReward.lt(e.changeInBalance)) {
            o.biggestBlockReward = e.changeInBalance
          }
          return o
        }
        return o
      },
      { blocksProposed: 0, biggestBlockReward: BigNumber.from('0') }
    )

    return {
      numberOfValidators,
      validatorShare,
      blocksProposed,
      biggestBlockReward,
    }
  }, [validators, validatorIncomeData.allEvents, waitTimes?.activeValidators])

  const handleValidatorSelect = useCallback(
    (index: string, checked: boolean) => {
      setSelectedValidators(
        produce((draftState) => {
          draftState[index] = checked
        })
      )
    },
    [selectedValidators, setSelectedValidators]
  )

  const onSelectAllChange = useCallback(
    (checked: boolean) => {
      setSelectedValidators(
        produce(selectedValidators, (draftState) => {
          validators.forEach((v) => {
            draftState[v.index] = checked
          })
        })
      )
    },
    [validators, selectedValidators, setSelectedValidators]
  )

  const onDateRangeSelect = (range: DateRangeType) => {
    const { from, to } = getInitialFilterTimestamp(range) ?? {}

    setDateRange({
      t: range,
      from,
      to,
    })
    // if (range === DateRange.CUSTOM) {
    // setCustomDateRange(customDateRange)
    // } else {
    // clear date range
    // setCustomDateRange(undefined)
    // }
  }

  const handleNewList = () => {
    clearValidators()
    clearUrlValidators()
    setInternalPage(InternalPages.Upload)
  }

  const generateList = (page: InternalPages) => {
    setShouldGenerateList(true)
    setInternalPage(page)
  }

  const numberOfSelectedValidators = useMemo(
    () => Object.values(selectedValidators).filter((c) => !!c).length,
    [selectedValidators]
  )

  const isValidatorListEmpty = validatorsList === ''
  const isListDisabled = isValidatorListEmpty || hasInvalidList
  const getButtonChildren = () => {
    if (pendingValidators.length !== 0) {
      return 'Show My Validator List'
    } else if (isValidatorListEmpty) {
      return 'Add Validators'
    } else if (hasInvalidList) {
      return 'Remove invalid validators'
    } else {
      return 'Show My Validator Statistics'
    }
  }

  const CURRENCY_ICON: Record<Currency, string> = {
    [Currency.AUD]: AudCurrencyIcon,
    [Currency.USD]: UsdCurrencyIcon,
    [Currency.ETH]: EthCurrencyIcon,
  }

  return (
    <WorkflowPageTemplate title="My Validators">
      <SwitchViewBar
        currentView={internalPage}
        handleSwitchView={generateList}
        isDisabled={isListDisabled}
      />
      {[InternalPages.Stats, InternalPages.List].includes(internalPage) && (
        <ValidatorStatsHeader
          locale={locale}
          numberOfValidators={numberOfValidators}
          validatorShare={validatorShare}
          blocksProposed={blocksProposed}
          biggestBlockReward={biggestBlockReward}
        />
      )}
      <div className="mx-auto mt-[180px] flex flex-col items-center justify-center">
        {internalPage === InternalPages.Upload && (
          <>
            <AddValidators
              setExtraValidators={addValidators}
              validatorUploads={validatorUploads}
              validatorsList={validatorsList}
              removeValidators={removeValidators}
              hasInvalidList={hasInvalidList}
              invalidValidators={invalidValidators}
              pendingValidators={pendingValidators}
              removePendingValidators={removePendingValidators}
            />
            <Button
              onClick={() => generateList(InternalPages.List)}
              disabled={isListDisabled && pendingValidators.length === 0}
              className="mx-auto mt-4 w-[570px]"
            >
              {getButtonChildren()}
            </Button>
          </>
        )}
        {internalPage !== InternalPages.Upload && (
          <div className="w-full">
            <div className="mx-auto mx-auto mb-3 flex w-max items-center">
              <div className="flex max-h-[28px] items-center gap-x-2 rounded-[7px] bg-pt-orange-inactive">
                {Object.values(DateRangeType).map((option) => (
                  <button
                    onClick={() => onDateRangeSelect(option)}
                    className={cx(
                      'inline-flex min-h-[28px] min-w-[28px] items-center justify-center rounded-[7px] px-1.5 text-white transition-colors',
                      {
                        'bg-pt-orange': option === dateRange.t,
                        'hover:bg-pt-orange': option !== dateRange.t,
                      }
                    )}
                  >
                    {option}
                  </button>
                ))}
              </div>
              {dateRange.t === DateRangeType.CUSTOM && (
                <div className="no-border ml-2 flex h-[30px] max-h-[30px] appearance-none gap-x-1.5 rounded-[7px] bg-white font-sourceSansPro font-semibold text-pt-dark-indigo">
                  <style>
                    {`
                      .no-border > * {
                        border: none;
                      }
                  `}
                  </style>
                  <StyledDatePicker
                    value={formatCustomDateRange(dateRange)}
                    onChange={(e) => {
                      setDateRange({
                        t: DateRangeType.CUSTOM,
                        ...parseCustomDateRange(e.value as [string, string]),
                      })
                    }}
                    format="mm/dd/yyyy-mm/dd/yyyy"
                  />
                </div>
              )}
              <div className="ml-2 flex gap-x-1.5">
                <div className="relative">
                  <select
                    value={currency}
                    onChange={(e) => setCurrency(e.target.value as Currency)}
                    className="h-[30px] max-h-[30px] appearance-none rounded-[7px] bg-white pl-3 pr-6 font-sourceSansPro font-semibold text-pt-dark-indigo"
                  >
                    {Object.values(Currency).map((option) => (
                      <option value={option}>{option}</option>
                    ))}
                  </select>
                  <img
                    src={ChevronDownIcon}
                    alt="chevron-down"
                    className="absolute right-2 top-1/2 h-3 w-3 -translate-y-1/2"
                  />
                </div>
                <img
                  src={CURRENCY_ICON[currency]}
                  alt="currency-icon"
                  className="h-[30px] w-[30px]"
                />
              </div>
            </div>
            {internalPage === InternalPages.List && (
              <Container>
                <ValidatorList
                  validators={validators}
                  pendingValidators={pendingValidators}
                  validatorsNames={validatorsNames}
                  nameValidators={nameValidators}
                  dateRange={dateRange}
                  currency={currency}
                  currentEthPrice={currentEthPrice}
                  waitTimes={waitTimes}
                  locale={locale}
                  selectedValidators={selectedValidators}
                  handleValidatorSelect={handleValidatorSelect}
                  rewardTotals={rewardTotals}
                />
                <div className="flex flex-col gap-y-[90px]">
                  <div className="flex items-center justify-end bg-pt-orange py-2">
                    {validators.length ? (
                      <>
                        <Download
                          validators={validators}
                          validatorIncomeData={validatorIncomeData}
                          ethPrices={ethPrices}
                          currency={currency}
                          dateRange={dateRange}
                          selectedValidators={selectedValidators}
                        />
                        <div className="mx-2 my-auto">
                          <CheckBox
                            checked={validators.length === numberOfSelectedValidators}
                            onChange={(event) => onSelectAllChange(event.target.checked)}
                          />
                        </div>
                      </>
                    ) : null}
                  </div>
                  <button
                    className={cx('mx-auto', newListButton)}
                    onClick={handleNewList}
                  >
                    Change List
                  </button>
                </div>
              </Container>
            )}
            {internalPage === InternalPages.Stats && (
              <>
                <ValidatorStats
                  locale={locale}
                  validators={validators}
                  graphIncomeData={graphIncomeData}
                  graphStakingData={graphStakingData}
                  graphAprData={graphAprData}
                  // rewardsBreakdown={parsedRewardsBreakdown}
                  currency={currency}
                  dateRange={dateRange}
                />
                <button
                  className={cx(newListButton, 'mx-auto mt-[90px] xxs:block')}
                  onClick={handleNewList}
                >
                  Change List
                </button>
              </>
            )}
            {internalPage === InternalPages.List && (waitTimes?.isLoading || isLoadingPerformance) && <LoadingScreen />}
            {internalPage === InternalPages.Stats && isLoadingIncomeData && <LoadingScreen />}
          </div>
        )}
      </div>
    </WorkflowPageTemplate>
  )
}

const mapStateToProps = ({ depositFile, validatorsState }: StoreState): StateProps => ({
  depositKeys: depositFile.keys,
  validatorsList: validatorsState.validatorsList,
  validatorsNames: validatorsState.names,
  validatorUploads: validatorsState.uploads,
  hasValidatedList: validatorsState.hasValidatedList,
  invalidValidators: validatorsState.invalidValidators,
  hasInvalidList: Object.keys(validatorsState.invalidValidators).length !== 0,
})

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  addValidators: (validators: string, fileName?: string) =>
    dispatch(updateValidators(validators, ValidatorsAction.ADD_VALIDATORS, fileName)),
  removeValidators: (validators: string, fileName?: string) =>
    dispatch(updateValidators(validators, ValidatorsAction.REMOVE_VALIDATORS, fileName)),
  clearValidators: () => dispatch(updateValidators('', ValidatorsAction.CLEAR_VALIDATORS)),
  nameValidators: (records) => dispatch(updateValidatorsName(records, NameValidatorsAction.NAME_VALIDATOR)),
  setMaxIndex: (maxIndex) => dispatch(updateIndexValidation(maxIndex, IndexValidationAction.SET_MAX_INDEX)),
})

export const InjectedValidatorPage = connect<StateProps, DispatchProps, {}, StoreState>(
  mapStateToProps,
  mapDispatchToProps
)(_InjectedValidatorPage)

// Seperating this page makes it possible to generate stories on InjectedValidatorPage with mocked hooks
export const ValidatorPage = () => {
  const { validatorsList, hasInvalidList, hasValidatedList } = useSelector(mapStateToProps)

  const { account } = useWeb3React<Web3Provider>()
  const [dateRange, setDateRange] = useState<DateRange>({
    t: DateRangeType.ALL,
    from: undefined,
    to: undefined,
  })
  const [currency, setCurrency] = useState(Currency.AUD)
  const [shouldGenerateList, setShouldGenerateList] = useState<boolean>(false)
  const [generatingList, setGeneratingList] = useState<boolean>(false)

  const waitTimes = useValidatorWaitTimes()
  const usersValidators = useUsersValidators(account)

  const shouldFetchData = !hasInvalidList && hasValidatedList && shouldGenerateList

  const { currentEthPrice, prices: ethPrices } = useHistoricalEthPrices(currency)
  const { validators, validatorIncomeData, isLoadingPerformance, isLoadingIncomeData } = useUserData(
    validatorsList,
    ethPrices,
    shouldFetchData
  )
  const { rewardsBreakdown } = useValidatorsRewardsBreakdown(validatorsList, shouldFetchData)

  const { graphIncomeData, graphStakingData, graphAprData, rewardTotals } = useValidatorGraphs(
    currency,
    dateRange,
    validatorIncomeData
  )

  return (
    <InjectedValidatorPage
      // own props
      waitTimes={waitTimes}
      usersValidators={usersValidators}
      rewardsBreakdown={rewardsBreakdown}
      currentEthPrice={currentEthPrice}
      ethPrices={ethPrices}
      validators={validators}
      validatorIncomeData={validatorIncomeData}
      isLoadingPerformance={isLoadingPerformance}
      isLoadingIncomeData={isLoadingIncomeData}
      graphIncomeData={graphIncomeData}
      graphStakingData={graphStakingData}
      graphAprData={graphAprData}
      rewardTotals={rewardTotals}
      dateRange={dateRange}
      setDateRange={setDateRange}
      currency={currency}
      setCurrency={setCurrency}
      setShouldGenerateList={setShouldGenerateList}
    />
  )
}

export default ValidatorPage

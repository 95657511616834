import { useMemo } from 'react'
import { BigNumber } from '@ethersproject/bignumber'
import { formatUnits } from '@ethersproject/units'
import { UserData } from './useUserData'
import { EvntType, ParsedIncomeHistory } from './useValidatorsIncomeData'
import {
  Currency,
  DateRange,
  DateRangeType,
  GraphAprData,
  GraphIncomeData,
  GraphRewardsBreakdown,
  GraphStakingData,
} from '../pages/Validators/types'
import { calcApr, timeFilter } from '../pages/Validators/validatorUtils'

export type GraphData = {
  graphIncomeData: GraphIncomeData[]
  graphStakingData: GraphStakingData[]
  graphAprData: GraphAprData[]
  rewardTotals: Record<number, BigNumber>
}

export const useValidatorGraphs = (
  currency: Currency,
  dateRange: DateRange,
  validatorIncomeData: UserData['validatorIncomeData']
): GraphData =>
  useMemo(() => {
    let cumulative = BigNumber.from('0')

    const rangeFilteredAllEvents = timeFilter(dateRange, validatorIncomeData.allEvents)
    const allRewards = validatorIncomeData.allEvents.filter(
      (e) => e.type === EvntType.Reward || e.type === EvntType.Block
    )
    const graphIncomeData_ = allRewards
      .map((v) => {
        const reward = currency === Currency.ETH ? v.changeInBalance : v.changeInBalance.mul(v.ethPrice)
        cumulative = cumulative.add(reward)
        const decimals = currency === Currency.ETH ? 18 : 36
        return {
          index: v.validatorIndex,
          timestamp: v.timestamp,
          date: v.timestamp.split('T')[0],
          reward: +formatUnits(reward, decimals),
          cumulative: +formatUnits(cumulative, decimals),
          rawEthReward: v.changeInBalance,
        } as GraphIncomeData
      })
      .filter((v) => v.cumulative !== 0)
    const graphIncomeData = timeFilter(dateRange, graphIncomeData_)

    const rewardTotals = graphIncomeData.reduce((o, v: { index: number; rawEthReward: BigNumber }) => {
      const index = v.index
      if (!o[index]) {
        o[index] = BigNumber.from(0)
      }
      o[index] = o[index].add(v.rawEthReward)
      return o
    }, {} as Record<number, BigNumber>)

    const stakingDataMap = rangeFilteredAllEvents
      .filter((e) => e.type === EvntType.Reward)
      .reduce((o, v) => {
        const staked = currency === Currency.ETH ? v.balance : v.balance.mul(v.ethPrice)
        const decimals = currency === Currency.ETH ? 18 : 36
        const date = v.timestamp.split('T')[0]
        if (o[date]) {
          o[date] = {
            date,
            staked: o[date].staked + +formatUnits(staked, decimals),
          }
        } else {
          o[date] = {
            date,
            staked: +formatUnits(staked, decimals),
          }
        }
        return o
      }, {} as Record<string, GraphStakingData>)
    const graphStakingData = Object.values(stakingDataMap)

    type DateAprMap = Record<
      string,
      {
        changeInBalance: BigNumber
        ethPrice: BigNumber
      }
    >

    const graphAprData_: DateAprMap = rangeFilteredAllEvents
      .filter((e) => e.type === EvntType.Reward)
      .reduce((o: DateAprMap, v: any) => {
        const timestamp = v.timestamp.split('T')[0]
        if (!o[timestamp]) {
          o[timestamp] = {
            changeInBalance: v.changeInBalance,
            ethPrice: v.ethPrice,
          }
        } else {
          o[timestamp] = {
            ...o[timestamp],
            changeInBalance: o[timestamp].changeInBalance.add(v.changeInBalance),
          }
        }
        return o
      }, {} as DateAprMap)

    const graphAprData = Object.keys(graphAprData_)
      .map((date) => {
        const v = graphAprData_[date]
        return {
          date,
          apr: calcApr(v.changeInBalance, v.ethPrice, { t: DateRangeType.DAY }),
        }
      })
      .filter((v) => v.apr !== 0)

    return {
      graphIncomeData,
      graphStakingData,
      graphAprData,
      rewardTotals,
    }
  }, [validatorIncomeData.allEvents, currency, dateRange])

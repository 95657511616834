import React from 'react'
import styled from 'styled-components'
import { Button } from '../Button'

const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  display: ${(p) => (p.hidden ? 'none' : 'flex')};
  justify-content: center;
  align-items: center;
  padding: 20px;
`

const ModalContent = styled.div`
  background-color: #fff;
  border-radius: 4px;
  padding: 20px;
  width: 100%;
  max-width: 600px;
  max-height: 100%;
  overflow-y: auto;

  h1 {
    line-height: 1.2;
    font-size: 20px;
  }

  button {
    display: block;
    margin-top: 20px !important;
    margin: 0 auto;
  }

  label {
    display: block;
    width: max-content;
    cursor: pointer;
    margin: 0 auto;
  }
`

export const TermsModal = ({
  isOpen,
  onClose,
  handleToggleTermsAccepted,
  hasAcceptedTerms,
}: {
  isOpen: boolean
  onClose: () => void
  handleToggleTermsAccepted: () => void
  hasAcceptedTerms: boolean
}) => (
  <ModalContainer hidden={!isOpen}>
    <ModalContent>
      <h1>Terms and Conditions</h1>
      <p>
        Please read and accept the{' '}
        <a
          href="https://piertwo.com/terms-and-conditions"
          target="_blank"
          className="underline"
        >
          Terms and Conditions
        </a>{' '}
        before proceeding.
      </p>
      <label htmlFor="termsCheckbox">
        <input
          type="checkbox"
          id="termsCheckbox"
          checked={hasAcceptedTerms}
          value={hasAcceptedTerms ? 'true' : 'false'}
          onClick={handleToggleTermsAccepted}
        />{' '}
        <span className="select-none">I accept the terms and conditions</span>
      </label>
      {hasAcceptedTerms && (
        <Button onClick={onClose}>
          <span className="select-none">Proceed</span>
        </Button>
      )}
    </ModalContent>
  </ModalContainer>
)

export const saveToLocalStorage = (key: string, value: string) => window.localStorage.setItem(key, value)
export const getFromLocalStorage = (key: string) => window.localStorage.getItem(key)
export const clearLocalStorage = () => window.localStorage.clear()

export const getFromUrl = (key: string): string | null => {
  // @ts-ignore
  let params = new URL(window.document.location).searchParams
  return params.get(key)
}

// @dev if value is undefined then clear the param
export const setUrlParams = (key: string, value?: string): void => {
  // @ts-ignore
  let url = new URL(window.document.location)
  if (!value) {
    url.searchParams.delete(key)
  } else {
    url.searchParams.set(key, value)
  }
  window.history.replaceState({}, 'Set validators', url.toString())
}

export const clearUrlValidators = () => setUrlParams('validators')

import { socialLinks } from '../../constants/links'
import GitHubIcon from '../../static/github.svg'
// import LinkedInIcon from '../../static/linkedin.svg';
// import MediumIcon from '../../static/medium.svg';
import TwitterIcon from '../../static/twitter.svg'

export const socialLinkRowContent = [
  // {
  //   label: 'LinkedIn',
  //   href: socialLinks.linkedin,
  //   icon: LinkedInIcon,
  // },
  {
    label: 'Twitter',
    href: socialLinks.twitter,
    icon: TwitterIcon,
  },
  // {
  //   label: 'Medium',
  //   href: socialLinks.medium,
  //   icon: MediumIcon,
  // },
  {
    label: 'GitHub',
    href: socialLinks.github,
    icon: GitHubIcon,
  },
]

export const legalLinks = [
  {
    label: 'Privacy Policy',
    href: 'https://piertwo.com/privacy-policy',
  },
  // {
  //   label: 'Terms & Conditions',
  //   href: 'https://piertwo.com/terms-and-conditions',
  // },
]

import { useMemo } from 'react'
import useSWR from 'swr'
import { BEACONCHAIN_URL } from '../utils/envVars'
import { fetcher } from '../utils/data'
import { BigNumber } from '@ethersproject/bignumber'
import { parseUnits } from '@ethersproject/units'

export type ValidatorRewardsBreakdown = {
  validatorIndex: number
  headRewards: BigNumber
  sourceRewards: BigNumber
  targetRewards: BigNumber
}

export type RawIncomeDetailHistory = {
  income: {
    attestation_head_reward: number
    attestation_source_reward: number
    attestation_target_reward: number
  }
  validatorindex: number
  // "epoch": 0,
  // "week": 0,
  // "week_end": "string",
  // "week_start": "string"
}

export const useValidatorsRewardsBreakdown = (
  validators: string,
  shouldFetchData: boolean
): {
  rewardsBreakdown: Record<string, ValidatorRewardsBreakdown>
} => {
  const {
    data: rewardsData,
    // error: rewardsDataError,
    // isLoading: rewardsDataIsLoading,
  } = useSWR(
    !!validators && shouldFetchData ? `${BEACONCHAIN_URL}/api/v1/validator/${validators}/incomedetailhistory` : null,
    fetcher
  )

  return useMemo(() => {
    if (!rewardsData) {
      return { rewardsBreakdown: [] }
    }

    const rewardsBreakdown = rewardsData.data.reduce((o: any, v: RawIncomeDetailHistory) => {
      if (!o[v.validatorindex]) {
        o[v.validatorindex] = {
          validatorIndex: v.validatorindex,
          headRewards: BigNumber.from('0'),
          sourceRewards: BigNumber.from('0'),
          targetRewards: BigNumber.from('0'),
        }
      }
      return {
        ...o,
        [v.validatorindex]: {
          ...v,
          headRewards: o[v.validatorindex].headRewards.add(
            parseUnits(v.income['attestation_head_reward']?.toString() ?? '0', 9)
          ),
          sourceRewards: o[v.validatorindex].sourceRewards.add(
            parseUnits(v.income['attestation_source_reward']?.toString() ?? '0', 9)
          ),
          targetRewards: o[v.validatorindex].targetRewards.add(
            parseUnits(v.income['attestation_target_reward']?.toString() ?? '0', 9)
          ),
        },
      }
    }, {} as Record<number, ValidatorRewardsBreakdown>)
    return { rewardsBreakdown }
  }, [rewardsData])
}

export default useValidatorsRewardsBreakdown

import { BigNumber } from '@ethersproject/bignumber'
import { parseUnits } from '@ethersproject/units'

export const formatPercent = (value: number, locale: string): string =>
  new Intl.NumberFormat(locale, {
    style: 'percent',
    minimumSignificantDigits: 3,
    maximumSignificantDigits: 3,
  }).format(value)

export const formatNumber = (value: number, locale: string): string => new Intl.NumberFormat(locale).format(value)

export const formatEth = (value: number, unit?: 'gwei' | 'wei'): string => {
  let amount
  if (unit === 'gwei') {
    amount = value * 1e-9
  } else {
    amount = value * 1e-18
  }
  return amount.toFixed(3)
}

export const convertDecimalStringToEthBn = (s: string): BigNumber => {
  const n: number = Math.floor(Number(s) * 10 ** 18)
  return BigNumber.from(n.toLocaleString('fullwide', { useGrouping: false }))
}

export const convertNumberToEthBn = (n: number = 0, precision: number = 6) => {
  const parsedN = n * 10 ** precision
  return parseUnits(parsedN.toString(), 18 - precision)
}

import cx from 'classnames'
import React, { FC, ReactNode, useEffect, useRef } from 'react'
import { disableScroll, enableScroll } from '../../helpers/common'
import { useOutsideClick } from '../../hooks/useOutsideClick'

interface ModalProps {
  isOpen: boolean
  onClose: () => void
  containerClassName?: string
  className?: string
  children: ReactNode
}

export const Modal: FC<ModalProps> = ({ isOpen, onClose, containerClassName, className, children }) => {
  const containerRef = useRef<HTMLDivElement>(null)
  useOutsideClick(containerRef, onClose, true)

  useEffect(() => {
    isOpen ? disableScroll() : enableScroll()
  }, [isOpen])

  return (
    <div
      ref={containerRef}
      className={cx(
        'fixed left-0 top-0 z-[51] flex h-full min-h-screen w-full items-center justify-center bg-pt-modal-bg/80 px-4 transition-opacity',
        {
          'pointer-events-none opacity-0': !isOpen,
        },
        containerClassName
      )}
    >
      <div
        className={cx(
          'relative w-full max-w-[570px] transition-all',
          {
            'scale-95 opacity-0': !isOpen,
          },
          className
        )}
      >
        {children}
      </div>
    </div>
  )
}

import { BigNumber } from '@ethersproject/bignumber'
import { formatUnits } from '@ethersproject/units'
import React from 'react'
import { Validator } from '../../../hooks/useUserData'
import { PendingValidator } from '../../../hooks/usePendingValidators'
import { BEACONCHAIN_URL } from '../../../utils/envVars'
import { formatPercent } from '../../../utils/numberFormatters'
import { CheckBox } from '../CheckBox'
import ValidatorStatus from '../ValidatorStatus'
import { Currency, DateRange, DateRangeType } from '../types'
import { allRewards, calcApr } from '../validatorUtils'
import { toLocaleString } from '../../../helpers/common'
import { WaitTimes } from '../../../types/validators'

import cx from 'classnames'

// TYPES
const headerKeys = ['Validator', 'APR', 'Rewards', 'Status', /* Empty head for checkbox */ '']

// HELPERS
const DEFAULT_AMOUNT = {
  el: BigNumber.from('0'),
  cl: BigNumber.from('0'),
}

const getRewards = (v: Validator, rewardTotals: Record<number, BigNumber>, dateRange: DateRangeType): BigNumber => {
  switch (dateRange) {
    case DateRangeType.ALL:
      return allRewards(v?.earntTotal ?? DEFAULT_AMOUNT)
    case DateRangeType.MONTH:
      return allRewards(v?.earnt31D ?? DEFAULT_AMOUNT)
    case DateRangeType.WEEK:
      return allRewards(v?.earnt7D ?? DEFAULT_AMOUNT)
    case DateRangeType.DAY:
      return allRewards(v?.earnt1D ?? DEFAULT_AMOUNT)
    case DateRangeType.CUSTOM:
      return rewardTotals[v.index] ?? allRewards(DEFAULT_AMOUNT)
    default:
      return allRewards(v?.earntTotal ?? DEFAULT_AMOUNT)
  }
}

// STYLES
const tableCellStyles = 'p-4'

// PROPS
type Props = {
  validators: Validator[]
  pendingValidators: PendingValidator[]
  validatorsNames: Record<string, string>
  nameValidators: (records: Record<string, string>) => void
  currentEthPrice: BigNumber
  waitTimes: WaitTimes | undefined
  locale: string
  handleValidatorSelect: (index: string, checked: boolean) => void
  selectedValidators: Record<string, boolean>
  rewardTotals: Record<number, BigNumber>
  currency: Currency
  dateRange: DateRange
}

// IMPLEMENTATION
export const ValidatorList = ({
  validators,
  pendingValidators,
  validatorsNames,
  nameValidators,
  currentEthPrice,
  waitTimes,
  locale,
  handleValidatorSelect,
  selectedValidators,
  currency,
  dateRange,
  rewardTotals,
}: Props) => {
  return (
    <div className="mx-auto w-full overflow-x-auto overflow-y-hidden shadow-card">
      <div className="h-full max-h-[calc(100vh-400px)] w-full overflow-auto">
        <table className="w-full table-auto bg-white">
          <thead>
            <tr>
              {headerKeys.map((key, index) => (
                <th
                  key={index}
                  className="sticky top-0 whitespace-nowrap bg-pt-light-indigo p-4 text-left font-normal"
                >
                  {key}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {validators.map((validator, i) => {
              const {
                status,
                name,
                pubKey,
                index,
                earntTotal,
                earnt1D,
                earnt7D,
                earnt31D,
                depositTime,
                // withdrawalTime,
                // transactionStatus,
                // txHash,
                // depositStatus,
              } = validator

              let displayName
              if (!!validatorsNames[pubKey]) {
                displayName = validatorsNames[pubKey]
              } else if (!!name) {
                // name from beaconcha.in
                displayName = name
              }

              let totalRewards = getRewards(validator, rewardTotals, dateRange.t)
              let formattedTotalRewards
              if (currency !== Currency.ETH) {
                formattedTotalRewards = `$${toLocaleString(
                  (+formatUnits(totalRewards.mul(currentEthPrice), 18 + 18)).toFixed(4)
                )}`
              } else {
                formattedTotalRewards = toLocaleString((+formatUnits(totalRewards)).toFixed(4))
              }

              const apr = calcApr(totalRewards, currentEthPrice, dateRange)

              return (
                <tr key={pubKey}>
                  <td className={tableCellStyles}>
                    {' '}
                    <a
                      href={`${BEACONCHAIN_URL}/validator/0x${pubKey}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="mr5"
                      data-tip
                    >
                      {`${pubKey.slice(0, 10)}...${pubKey.slice(-10)}`}
                    </a>
                  </td>
                  <td className={cx('w-[140px]', tableCellStyles)}>{formatPercent(apr, locale)}</td>
                  <td className={cx('w-[240px]', tableCellStyles)}>{formattedTotalRewards}</td>
                  <td className={tableCellStyles}>
                    {' '}
                    <ValidatorStatus
                      status={status}
                      waitTimes={waitTimes}
                      depositTime={depositTime}
                    />
                  </td>
                  <td>
                    <div className="m-auto flex justify-end pr-2">
                      <CheckBox
                        checked={selectedValidators[index]}
                        onChange={(event: any) => handleValidatorSelect(index.toString(), event.target?.checked)}
                      />
                    </div>
                  </td>
                </tr>
              )
            })}
            {pendingValidators.map((pendingValidator) => {
              const { pubKey, depositTime } = pendingValidator
              return (
                <tr key={pubKey}>
                  <td className={tableCellStyles}>
                    {' '}
                    <a
                      href={`${BEACONCHAIN_URL}/validator/0x${pubKey}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="mr5"
                      data-tip
                    >
                      {`${pubKey.slice(0, 10)}...${pubKey.slice(-10)}`}
                    </a>
                  </td>
                  <td className={cx('w-[140px]', tableCellStyles)}>{'0.00%'}</td>
                  <td className={cx('w-[240px]', tableCellStyles)}>{'0.00'}</td>
                  <td className={tableCellStyles}>
                    {' '}
                    <ValidatorStatus
                      status={'pending'}
                      waitTimes={waitTimes}
                      depositTime={depositTime}
                    />
                  </td>
                  <td>
                    <div className="m-auto flex justify-end pr-2"></div>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default ValidatorList

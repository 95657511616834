import { Web3Provider } from '@ethersproject/providers'
import { useWeb3React } from '@web3-react/core'
import { NoMetaMaskError } from '@web3-react/metamask'
import { Connector } from '@web3-react/types'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { RoutesEnum } from '../../Routes'
import { Button } from '../../components/CustomButton/index'
import { Link } from '../../components/Link'
import { WorkflowPageTemplate } from '../../components/WorkflowPage/WorkflowPageTemplate'
import { NetworkChainId } from '../../constants/web3'
import useEthBalance from '../../hooks/useEthBalance'
import useIntlNetworkName from '../../hooks/useIntlNetworkName'
import coinbaseWalletLogo from '../../static/coinbase.svg'
import ConnectIcon from '../../static/connect.svg'
import metamaskLogo from '../../static/metamask.svg'
import walletConnectLogo from '../../static/wallet-connect.png'
import { DispatchWorkflowUpdateType, WorkflowStep, updateWorkflow } from '../../store/actions/workflowActions'
import { DepositKeyInterface, StoreState } from '../../store/reducers'
import { IS_NON_INFURA_TESTNET, PRICE_PER_VALIDATOR, TICKER_NAME } from '../../utils/envVars'
import { changeToTestnet } from './Chains'
import { WalletButtonV2 } from './WalletButtonV2'
import { coinbaseWallet, metamask, walletConnect } from './connectors'
import { AllowedELNetworks, TARGET_NETWORK_CHAIN_ID, isValidNetwork, useMetamaskListener } from './web3Utils'

// Prop definitions
interface OwnProps {}
interface StateProps {
  workflow: WorkflowStep
  depositKeys: DepositKeyInterface[]
}
interface DispatchProps {
  dispatchWorkflowUpdate: DispatchWorkflowUpdateType
}
type Props = StateProps & DispatchProps & OwnProps

const _ConnectWalletPage = ({ depositKeys, dispatchWorkflowUpdate }: Props): JSX.Element => {
  // get wallet info from Web3React
  const { isActive: walletConnected, chainId, connector: walletProvider, account } = useWeb3React<Web3Provider>()
  const { executionLayerName } = useIntlNetworkName()

  // initialize state
  const [lowBalance, setLowBalance] = useState<boolean>(false)
  const [selectedWallet, setSelectedWallet] = useState<Connector | null | undefined>(null)
  const [network, setNetwork] = useState<string>('')
  const [networkAllowed, setNetworkAllowed] = useState<boolean>(false)
  const [status, setStatus] = useState<string>('')
  const [error, setError] = useState<any>(null)

  const isInvalidNetwork = !!chainId && !isValidNetwork(chainId)
  const { formatMessage } = useIntl()

  // setup RPC event listener
  // const attemptedMMConnection: boolean = useMetamaskEagerConnect()
  const attemptedMMConnection: boolean = false
  useMetamaskListener(!attemptedMMConnection)

  // sets the balance to the current wallet on provider or network change
  const balance = useEthBalance()

  useEffect(() => {
    // @ts-ignore (type check performed in envVars.ts)
    const requiredBalance = depositKeys.length * PRICE_PER_VALIDATOR
    if (balance < requiredBalance || balance === 0) {
      setLowBalance(true)
    } else {
      setLowBalance(false)
    }
  }, [balance])

  const getWalletName = (provider?: Connector) => {
    if (!provider) return ''
    if (provider === walletConnect) return 'WalletConnect'
    if (provider === metamask) return 'Metamask'
    if (provider === coinbaseWallet) return 'Coinbase'
    // if (provider === portis) return 'Portis'
    // if (provider === fortmatic) return 'Fortmatic'
    return ''
  }

  // sets the status copy on provider or network change
  useEffect(() => {
    if (chainId) {
      setNetwork(NetworkChainId[chainId])
      setNetworkAllowed(AllowedELNetworks.includes(network))
    }

    if (walletConnected && networkAllowed && (balance || balance === 0)) {
      setStatus(`${balance} ${TICKER_NAME}`)
    } else if (walletConnected) {
      setStatus(formatMessage({ defaultMessage: 'Error' }))
    } else if (!networkAllowed) {
      setStatus(
        formatMessage(
          { defaultMessage: 'Connect {wallet} to {executionLayerName}' },
          {
            wallet: getWalletName(walletProvider),
            executionLayerName,
          }
        )
      )
    }
  }, [chainId, walletConnected, networkAllowed, balance, network, formatMessage, walletProvider, executionLayerName])

  // const withdrawalAddress = useMemo<string>(() => {
  //   // eslint-disable-next-line camelcase
  //   const credentials = depositKeys[0]?.withdrawal_credentials ?? '';
  //   if (credentials.startsWith('01')) return `0x${credentials.slice(-40)}`;
  //   return '';
  // }, [depositKeys]);
  // const withdrawalAddressShort = useMemo<string>(
  //   () => `${withdrawalAddress.slice(0, 6)}...${withdrawalAddress.slice(-4)}`,
  //   [withdrawalAddress]
  // );

  useEffect(() => {
    if (walletConnected && !lowBalance) {
      dispatchWorkflowUpdate(WorkflowStep.TRANSACTION_SIGNING)
    }
  }, [walletConnected, lowBalance])

  const connect = async () => {
    try {
      if (!walletConnected) {
        if (!selectedWallet) return
        if (IS_NON_INFURA_TESTNET) {
          await changeToTestnet(TARGET_NETWORK_CHAIN_ID)
        }
        !!selectedWallet?.activate && (await selectedWallet.activate())
      } else {
        !!selectedWallet?.deactivate && selectedWallet.deactivate()
        walletProvider?.deactivate && walletProvider.deactivate()
        walletProvider.resetState()
        setSelectedWallet(undefined)
      }
    } catch (error) {
      console.error(error)
      setError(error)
    }
  }

  return (
    <WorkflowPageTemplate title="Connect Wallet">
      <div className="mx-auto mt-20 flex w-full max-w-[530px] flex-col items-center gap-y-4 rounded-[16px] bg-white p-6 pt-12 shadow-modal">
        <img
          src={ConnectIcon}
          alt="Connect"
          className="h-12 w-12"
        />
        <h1 className="text-xl font-bold">Connect Wallet</h1>
        <WalletButtonV2
          title="MetaMask"
          provider={metamask}
          selectedWallet={selectedWallet}
          setSelectedWallet={setSelectedWallet}
          providerLogo={metamaskLogo}
        />
        <WalletButtonV2
          title="Wallet Connect"
          provider={walletConnect}
          selectedWallet={selectedWallet}
          setSelectedWallet={setSelectedWallet}
          providerLogo={walletConnectLogo}
        />
        <WalletButtonV2
          title="Coinbase Wallet"
          provider={coinbaseWallet}
          selectedWallet={selectedWallet}
          setSelectedWallet={setSelectedWallet}
          providerLogo={coinbaseWalletLogo}
        />
        <div className="mx-auto mb-2 grid w-full max-w-[464px] grid-cols-2 justify-center gap-x-4">
          <Link to={RoutesEnum.uploadValidatorPage}>
            <Button variant="secondary">Go Back</Button>
          </Link>
          <Button
            disabled={!walletConnected && !selectedWallet}
            onClick={connect}
            className="w-full"
          >
            {walletConnected ? 'Disconnect' : 'Connect'}
          </Button>
        </div>
        <div className="mx-auto mb-2 grid w-full max-w-[464px] grid-cols-1 justify-center gap-x-4">
          <Link to={RoutesEnum.transactionsPage}>
            <Button disabled={!walletConnected || lowBalance}>Continue</Button>
          </Link>
        </div>
        {walletConnected && lowBalance && !isInvalidNetwork && (
          <p>
            You do not have enough {TICKER_NAME} in this account for&nbsp;
            {depositKeys.length} validator{depositKeys.length > 1 ? 's' : ''}
          </p>
        )}
      </div>
      {error && error instanceof NoMetaMaskError && (
        <p className="mx-auto text-center">
          We can't detect MetaMask. Switch browsers or install MetaMask.
          <Link
            isTextLink={false}
            to="https://metamask.io/"
          >
            Download MetaMask
          </Link>
        </p>
      )}
      {isInvalidNetwork && (
        <div className="center mt20 flex">Your wallet is on the wrong network. Switch to {executionLayerName}</div>
      )}
    </WorkflowPageTemplate>
  )
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  dispatchWorkflowUpdate: (step) => dispatch(updateWorkflow(step)),
})

const mapStateToProps = ({ workflow, depositFile }: StoreState): StateProps => ({
  workflow,
  depositKeys: depositFile.keys,
})

export const ConnectWalletPage = connect<StateProps, DispatchProps, OwnProps, StoreState>(
  mapStateToProps,
  mapDispatchToProps
)(_ConnectWalletPage)

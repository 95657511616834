import { useMemo } from 'react'
import useSWR from 'swr'
import { BEACONCHAIN_URL } from '../utils/envVars'
import { fetcher } from '../utils/data'
import { ValidatorStatusType } from '../types/validators'

type RawValidatorInfo = {
  pubkey: string
  name: string
  validatorindex: number
  status: ValidatorStatusType
  // "activation_eligibility_epoch": 0,
  // "activation_epoch": 0,
  // "balance": 0,
  // "effective_balance": 0,
  // "exit_epoch": 0,
  // "last_attestation_slot": 0,
  // "slashed": true,
  // "withdrawable_epoch": 0,
  // "withdrawal_credentials": "string"
}

interface ValidatorInfoReturn {
  data: RawValidatorInfo[] | RawValidatorInfo
}

export type ValidatorInfo = {
  name: string
  pubKey: string
  index: number
  status: ValidatorStatusType
  depositTime: number
}

export const useValidatorsInfo = (validatorsList: string, shouldFetchData: boolean): ValidatorInfo[] => {
  const {
    data: validators,
    // error: validatorsError,
    // isLoading
  } = useSWR(
    !!validatorsList && shouldFetchData ? `${BEACONCHAIN_URL}/api/v1/validator/${validatorsList}` : null,
    fetcher
  )

  const {
    data: depositsData,
    // error: depositsError,
    // isLoading: depositsIsLoading,
  } = useSWR(
    !!validatorsList && shouldFetchData ? `${BEACONCHAIN_URL}/api/v1/validator/${validatorsList}/deposits` : null,
    fetcher
  )

  return useMemo(() => {
    if (!validators || !validators?.data || !depositsData || !depositsData?.data) {
      return []
    }

    const deposits: Record<string, number> = depositsData.data.reduce(
      (o: any, d: any) => ({
        ...o,
        [d.publickey]: d.block_ts,
      }),
      {} as Record<string, number>
    )

    let parsedValidators = []
    if (Array.isArray(validators.data)) {
      parsedValidators = validators.data.map((v: RawValidatorInfo) => ({
        pubKey: v.pubkey,
        index: v.validatorindex,
        name: v.name,
        status: v.status,
        depositTime: deposits[v.pubkey],
      }))
    } else {
      try {
        // this is tempoarary since it returns
        // a single object if you only pass 1 validator in the request
        // set to change in https://github.com/gobitfly/eth2-beaconchain-explorer/issues/1673
        parsedValidators = [
          {
            pubKey: validators.data.pubkey,
            index: validators.data.validatorindex,
            name: validators.data.name,
            status: validators.data.status,
            depositTime: deposits[validators.data.pubkey],
          },
        ]
      } catch (err) {
        console.error('Error processing validators', err)
      }
    }

    return parsedValidators
  }, [validators, depositsData])
}

export default useValidatorsInfo

export const getCurrentYear = (): string => new Date().getFullYear().toString()

export const disableScroll = (): string => (document.documentElement.style.overflow = 'hidden')

export const enableScroll = (): void => document.documentElement.removeAttribute('style')

export const isValidEmail = (email: string): boolean => /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,20}$/.test(email)

export const toLocaleString = (value: number | string): string => {
  if (typeof value === 'number') {
    return value.toLocaleString('en-US')
  }
  return Number(value).toLocaleString('en-US')
}

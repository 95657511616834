/*
  eslint-disable camelcase
*/
import { ActionTypes } from './index'
import { WithdrawalRequestInterface } from '../reducers'

export enum TransactionStatus {
  'READY',
  'PENDING',
  'STARTED',
  'SUCCEEDED',
  'FAILED',
  'REJECTED',
}

export enum WithdrawalStatus {
  VERIFYING,
  ALREADY_DEPOSITED,
  READY_FOR_DEPOSIT,
}

export enum BeaconChainStatus {
  HEALTHY,
  DOWN,
}

export interface UpdateWithdrawalFileNameAction {
  type: ActionTypes.updateWithdrawalFileName
  payload: string
}
export interface UpdateWithdrawalSignaturesAction {
  type: ActionTypes.updateWithdrawalSignatures
  payload: WithdrawalRequestInterface[]
}
// export interface UpdateTransactionStatusAction {
// type: ActionTypes.updateTransactionStatus
// payload: {
// pubkey: string
// status: TransactionStatus
// txHash?: string
// }
// }
// export interface UpdateWithdrawalStatusAction {
// type: ActionTypes.updateWithdrawalStatus
// payload: {
// pubkey: string
// depositStatus: WithdrawalStatus
// validatorCount: number
// }
// }
// export interface UpdateBeaconChainAPIStatusAction {
// type: ActionTypes.updateBeaconChainAPIStatus
// payload: BeaconChainStatus
// }

export type DispatchWithdrawalKeysUpdateType = (files: WithdrawalRequestInterface[]) => void
export type DispatchWithdrawalFileNameUpdateType = (fileName: string) => void

export const updateWithdrawalSignatures = (files: WithdrawalRequestInterface[]): UpdateWithdrawalSignaturesAction => {
  return {
    type: ActionTypes.updateWithdrawalSignatures,
    payload: files,
  }
}

export const updateWithdrawalFileName = (fileName: string): UpdateWithdrawalFileNameAction => {
  return {
    type: ActionTypes.updateWithdrawalFileName,
    payload: fileName,
  }
}

// export const updateBeaconChainAPIStatus = (status: BeaconChainStatus): UpdateBeaconChainAPIStatusAction => {
// return {
// type: ActionTypes.updateBeaconChainAPIStatus,
// payload: status,
// }
// }

// export const updateTransactionStatus = (
// pubkey: string,
// status: TransactionStatus,
// txHash?: string
// ): UpdateTransactionStatusAction => {
// return {
// type: ActionTypes.updateTransactionStatus,
// payload: {
// pubkey,
// status,
// txHash,
// },
// }
// }

// export type DispatchTransactionStatusUpdateType = (pubkey: string, status: TransactionStatus, txHash?: string) => void

// export const updateWithdrawalStatus = (
// pubkey: string,
// depositStatus: WithdrawalStatus,
// validatorCount: number
// ): UpdateWithdrawalStatusAction => {
// return {
// type: ActionTypes.updateWithdrawalStatus,
// payload: {
// pubkey,
// depositStatus,
// validatorCount,
// },
// }
// }

// export type DispatchWithdrawalStatusUpdateType = (
// pubkey: string,
// depositStatus: WithdrawalStatus,
// validatorCount: number
// ) => void

// export type DispatchBeaconChainAPIStatusUpdateType = (status: BeaconChainStatus) => void

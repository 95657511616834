import { combineReducers } from 'redux'
import { acknowledgementReducer, AcknowledgementStateInterface } from './acknowledgementReducer'
import { DepositFileInterface, depositFileReducer } from './depositFileReducer'
import { workflowReducer } from './workflowReducer'
import { WorkflowStep } from '../actions/workflowActions'
import { WalletAction } from '../actions/walletActions'
import { walletReducer } from './walletReducer'
import { clientReducer, clientState } from './clientReducer'
import { validatorsReducer, ValidatorsState } from './validatorsReducer'
import { withdrawalFileReducer, WithdrawalFileInterface } from './withdrawalFileReducer'

export * from './acknowledgementReducer'
export * from './depositFileReducer'
export * from './workflowReducer'
export * from './walletReducer'
export * from './validatorsReducer'
export * from './withdrawalFileReducer'

export interface StoreState {
  acknowledgementState: AcknowledgementStateInterface
  depositFile: DepositFileInterface
  withdrawalFile: WithdrawalFileInterface
  workflow: WorkflowStep
  client: clientState
  walletState: WalletAction
  validatorsState: ValidatorsState
}

export const reducers = combineReducers<StoreState>({
  acknowledgementState: acknowledgementReducer,
  depositFile: depositFileReducer,
  withdrawalFile: withdrawalFileReducer,
  workflow: workflowReducer,
  client: clientReducer,
  walletState: walletReducer,
  validatorsState: validatorsReducer,
})

import { UpdateAcknowledgementStateAction } from './acknowledgementActions'
import {
  UpdateDepositFileNameAction,
  UpdateDepositFileKeysAction,
  UpdateTransactionStatusAction,
  UpdateDepositStatusAction,
  UpdateBeaconChainAPIStatusAction,
} from './depositFileActions'
import { UpdateWorkflowAction } from './workflowActions'
import { UpdateClientAction } from './clientActions'
import { UpdateWalletAction } from './walletActions'
import { UpdateValidatorsAction, UpdateValidatorsNameAction, UpdateIndexValidationAction } from './validatorsActions'
import { UpdateWithdrawalFileNameAction, UpdateWithdrawalSignaturesAction } from './withdrawalFileActions'

export enum ActionTypes {
  updateAcknowledgementState,
  updateDepositFileKeys,
  updateDepositFileName,
  updateTransactionStatus,
  updateDepositStatus,
  updateWorkflow,
  updateClient,
  updateBeaconChainAPIStatus,
  updateWalletAction,
  // validators
  updateValidatorsAction,
  updateValidatorsNameAction,
  updateIndexValidationAction,
  // withdrawals
  updateWithdrawalSignatures,
  updateWithdrawalFileName,
}

export type Action =
  | UpdateAcknowledgementStateAction
  | UpdateDepositFileKeysAction
  | UpdateTransactionStatusAction
  | UpdateDepositStatusAction
  | UpdateWorkflowAction
  | UpdateDepositFileNameAction
  | UpdateClientAction
  | UpdateBeaconChainAPIStatusAction
  | UpdateWalletAction
  | UpdateValidatorsAction
  | UpdateValidatorsNameAction
  | UpdateIndexValidationAction
  | UpdateWithdrawalFileNameAction
  | UpdateWithdrawalSignaturesAction

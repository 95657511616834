// @ts-nocheck
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { fetchFromDatoAPI } from '../utils/cms'
import { WorkflowPageTemplate } from '../components/WorkflowPage/WorkflowPageTemplate'
import { MarkdownContent } from '../components/MarkdownContent'

export interface PrivacyPolicyContent {
  stakingTC: {
    bodyText: string
  }
}

const PRIVACY_POLICY_QUERY = `{
    pierTwoPrivacyPolicy {
        bodyText
    }
}`

const TextContainer = styled.div`
  color: white;
  margin: 0 auto;

  a {
    color: white;
  }
`

export const PrivacyPolicy = (): JSX.Element => {
  const [data, setData] = useState<PrivacyPolicyContent>(undefined)

  useEffect(() => {
    const fetchData = async () => {
      const content = await fetchFromDatoAPI(PRIVACY_POLICY_QUERY)
      setData(content)
    }
    fetchData()
  }, [])

  return (
    <WorkflowPageTemplate title="Privacy Policy">
      <TextContainer>
        {data && data.pierTwoPrivacyPolicy && <MarkdownContent>{data.pierTwoPrivacyPolicy.bodyText}</MarkdownContent>}
      </TextContainer>
    </WorkflowPageTemplate>
  )
}

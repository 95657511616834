import cx from 'classnames'
import React, { FC, ReactNode } from 'react'
import CloseIcon from '../../static/close.svg'

interface ParagraphProps {
  largeText?: boolean
  className?: string
  children: ReactNode
}

export const Paragraph: FC<ParagraphProps> = ({ largeText, className, children }) => {
  const smallStyles = 'text-xs text-myc-text-secondary'
  return (
    <p
      className={cx('mb-4', className, {
        [smallStyles]: !largeText,
      })}
    >
      {children}
    </p>
  )
}

interface CloseButtonProps {
  onClose: () => void
  className?: string
}

export const CloseButton: FC<CloseButtonProps> = ({ onClose, className }) => (
  <button
    type="button"
    className={cx('text-pt-dark-indigo transition-colors hover:text-pt-light-indigo', className)}
    onClick={onClose}
  >
    <img
      src={CloseIcon}
      alt="close"
      className="h-5 w-5"
    />
  </button>
)

interface HeaderProps {
  title: string
  onClose: () => void
}

export const Header: FC<HeaderProps> = ({ title, onClose }) => (
  <div className="relative mb-4 flex w-full items-center justify-between">
    <span className="mr-10 block">{title}</span>
    <CloseButton onClose={onClose} />
  </div>
)

import React, { useMemo } from 'react'
import { Text } from '../../../components/Text'
import { ValidatorStatusType, WaitTimes } from '../../../types/validators'

import ReactTooltip from 'react-tooltip'
import Countdown from 'react-countdown'

type Props = {
  status: ValidatorStatusType | undefined
  waitTimes: WaitTimes | undefined
  depositTime: number
}

const ValidatorStatus: React.FC<Props> = ({ status, waitTimes, depositTime }) => {
  const { waitTime, estimatedDate } = useMemo(() => {
    const now = Date.now()
    let estimatedDate: number = now + 10000000
    let waitTime = ``

    if (!!waitTimes && !!depositTime) {
      if (status === 'pending') {
        waitTime = waitTimes.entryTimes.entryWaitingTime
        estimatedDate = (depositTime + waitTimes.entryTimes.entryWaitingTimeSeconds) * 1000
      } else if (status === 'exiting_online') {
        waitTime = waitTimes.exitTimes.exitWaitingTime
        // TODO need to know withdrawal time
        // estimatedDate =
      }
    }
    return {
      estimatedDate,
      waitTime,
    }
  }, [status, waitTimes, depositTime])

  const { statusText, showCountdown } = useMemo(() => {
    let statusText: string = status ?? '--'
    let showCountdown = false
    if (status === 'active_online') {
      statusText = 'Active'
    } else if (status === 'exited') {
      statusText = 'Withdrawn'
    } else if (status === 'exiting_online') {
      statusText = 'Withdrawing'
      showCountdown = true
    } else if (status === 'deposited') {
      statusText = 'Deposited'
    } else if (status === 'pending') {
      statusText = 'Pending activation'
      showCountdown = true
    }
    return {
      statusText,
      showCountdown,
    }
  }, [status])

  return (
    <div
      data-tip
      data-for="wait-time"
    >
      <Text>{statusText}</Text>
      {showCountdown && (
        <>
          <ReactTooltip id="wait-time">{`Estimated wait time ${waitTime}`}</ReactTooltip>
          <Countdown
            data-tip
            data-for="wait-time"
            date={estimatedDate}
          />
        </>
      )}
    </div>
  )
}

export default ValidatorStatus

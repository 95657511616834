import { Web3Provider } from '@ethersproject/providers'
import { useWeb3React } from '@web3-react/core'
import { Connector } from '@web3-react/types'
import { FC, useState } from 'react'
import { Button } from '../../components/CustomButton/index'
import { Link } from '../../components/Link'
import useIntlNetworkName from '../../hooks/useIntlNetworkName'
import { changeToTestnet } from '../../pages/ConnectWallet/Chains'
import { WalletButtonV2 } from '../../pages/ConnectWallet/WalletButtonV2'
import { coinbaseWallet, metamask, walletConnect } from '../../pages/ConnectWallet/connectors'
import { TARGET_NETWORK_CHAIN_ID, isValidNetwork } from '../../pages/ConnectWallet/web3Utils'

import { NoMetaMaskError } from '@web3-react/metamask'
import CloseIcon from '../../static/close.svg'
import coinbaseWalletLogo from '../../static/coinbase.svg'
import ConnectIcon from '../../static/connect.svg'
import metamaskLogo from '../../static/metamask.svg'
import walletConnectLogo from '../../static/wallet-connect.png'
import { IS_NON_INFURA_TESTNET } from '../../utils/envVars'

interface ConnectWalletModalProps {
  onClose: () => void
}

export const ConnectWalletModal: FC<ConnectWalletModalProps> = ({ onClose }) => {
  const { isActive: walletConnected, connector: walletProvider, chainId } = useWeb3React<Web3Provider>()

  const { executionLayerName } = useIntlNetworkName()
  const [selectedWallet, setSelectedWallet] = useState<Connector | null | undefined>(null)
  const [error, setError] = useState<any>(null)

  const isInvalidNetwork = !!chainId && !isValidNetwork(chainId)

  const connect = async () => {
    try {
      if (!walletConnected) {
        if (!selectedWallet) return
        if (IS_NON_INFURA_TESTNET) {
          await changeToTestnet(TARGET_NETWORK_CHAIN_ID)
        }
        !!selectedWallet?.activate && (await selectedWallet.activate())
      } else {
        !!selectedWallet?.deactivate && selectedWallet.deactivate()
        walletProvider?.deactivate && walletProvider.deactivate()
        walletProvider.resetState()
        setSelectedWallet(undefined)
      }
    } catch (error) {
      console.error(error)
      setError(error)
    }
  }
  return (
    <>
      <div className="mx-auto mt-20 flex w-full flex-col items-center gap-y-4 rounded-[16px] bg-white p-6 shadow-modal">
        <div className="flex w-full justify-end">
          <button onClick={onClose}>
            <img
              src={CloseIcon}
              alt="Close"
              className="h-6 w-6"
            />
          </button>
        </div>
        <img
          src={ConnectIcon}
          alt="Connect"
          className="h-12 w-12"
        />
        <h1 className="text-xl font-bold">Connect Wallet</h1>
        <WalletButtonV2
          title="MetaMask"
          provider={metamask}
          selectedWallet={selectedWallet}
          setSelectedWallet={setSelectedWallet}
          providerLogo={metamaskLogo}
        />
        <WalletButtonV2
          title="Wallet Connect"
          provider={walletConnect}
          selectedWallet={selectedWallet}
          setSelectedWallet={setSelectedWallet}
          providerLogo={walletConnectLogo}
        />
        <WalletButtonV2
          title="Coinbase Wallet"
          provider={coinbaseWallet}
          selectedWallet={selectedWallet}
          setSelectedWallet={setSelectedWallet}
          providerLogo={coinbaseWalletLogo}
        />
        <div className="mx-auto mb-2 w-full max-w-[464px]">
          <Button
            disabled={!selectedWallet}
            onClick={connect}
            className="w-full"
          >
            {walletConnected ? 'Connected' : 'Connect'}
          </Button>
        </div>
        {error && error instanceof NoMetaMaskError ? (
          <p className="mx-auto text-center">
            We can't detect MetaMask. Switch browsers or install MetaMask.
            <Link
              isTextLink={false}
              to="https://metamask.io/"
            >
              Download MetaMask
            </Link>
          </p>
        ) : null}
        {isInvalidNetwork && (
          <div className="mx-auto w-max">Your wallet is on the wrong network. Switch to {executionLayerName}</div>
        )}
      </div>
    </>
  )
}

// @ts-nocheck
import { useWeb3React } from '@web3-react/core'
import BigNumber from 'bignumber.js'
import cx from 'classnames'
import { Close } from 'grommet-icons'
import React, { SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { FormattedMessage, useIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import styled from 'styled-components'
import { RoutesEnum } from '../../Routes'
import { Code } from '../../components/Code'
import { Button } from '../../components/CustomButton/index'
import { DepositSummary } from '../../components/DepositSummary'
import { Link } from '../../components/Link'
import { StakeModal } from '../../components/Modal/Stake'
import { TermsModal } from '../../components/TermsModal'
import { Text } from '../../components/Text'
import { WorkflowPageTemplate } from '../../components/WorkflowPage/WorkflowPageTemplate'
import useIntlNetworkName from '../../hooks/useIntlNetworkName'
import ArrowRightIcon from '../../static/arrow-right.svg'
import {
  BeaconChainStatus,
  DepositStatus,
  DispatchBeaconChainAPIStatusUpdateType,
  DispatchDepositFileNameUpdateType,
  DispatchDepositKeysUpdateType,
  DispatchDepositStatusUpdateType,
  TransactionStatus,
  updateBeaconChainAPIStatus,
  updateDepositFileKeys,
  updateDepositFileName,
  updateDepositStatus,
} from '../../store/actions/depositFileActions'
import { DispatchWorkflowUpdateType, WorkflowStep, updateWorkflow } from '../../store/actions/workflowActions'
import { DepositKeyInterface, StoreState } from '../../store/reducers'
import { GENESIS_FORK_VERSION } from '../../utils/envVars'
import { getFromLocalStorage, saveToLocalStorage } from '../../utils/localStorage'
import { FileUploadAnimation } from './FileUploadAnimation'
import { getExistingDepositsForPubkeys, validateDepositKey } from './validateDepositKey'

const Dropzone = styled.div`
  outline: none;
  :focus {
    outline: none;
  }
  width: 570px;
  height: 260px;
  cursor: ${(p: { isFileAccepted: boolean; isFileStaged: boolean }) =>
    p.isFileAccepted || p.isFileStaged ? 'inherit' : 'pointer'};
  padding: 30px;
  border-radius: 6px;
  background-color: white;
  box-shadow: 0px 4px 4px 0px #00000040;
`
// @ts-ignore

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
`

const PaddedButtonContainer = styled(ButtonContainer)`
  padding: 30px;
`

const DeleteBtn = styled.span`
  cursor: pointer;
  padding: 3px;
`

interface OwnProps {}
interface StateProps {
  depositKeys: DepositKeyInterface[]
  workflow: WorkflowStep
  depositFileName: string
}
interface DispatchProps {
  dispatchDepositFileKeyUpdate: DispatchDepositKeysUpdateType
  dispatchWorkflowUpdate: DispatchWorkflowUpdateType
  dispatchDepositFileNameUpdate: DispatchDepositFileNameUpdateType
  dispatchDepositStatusUpdate: DispatchDepositStatusUpdateType
  dispatchBeaconChainAPIStatusUpdate: DispatchBeaconChainAPIStatusUpdateType
}
type Props = StateProps & DispatchProps & OwnProps

const _UploadValidatorPage = ({
  workflow,
  depositKeys,
  dispatchDepositFileKeyUpdate,
  depositFileName,
  dispatchDepositFileNameUpdate,
  dispatchWorkflowUpdate,
  dispatchDepositStatusUpdate,
  dispatchBeaconChainAPIStatusUpdate,
}: Props): JSX.Element => {
  const { formatMessage } = useIntl()
  const { consensusLayerName } = useIntlNetworkName()
  const [hasAcceptedTerms, setHasAcceptedTerms] = useState(false)
  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false)
  const [isStakeModalOpen, setIsStakeModalOpen] = useState(false)
  const [isFileStaged, setIsFileStaged] = useState(depositKeys.length > 0)
  const [isFileAccepted, setIsFileAccepted] = useState(depositKeys.length > 0)
  const [fileError, setFileError] = useState<React.ReactElement | null>(null)
  const [jsonFilesArr, setJsonFilesArr] = useState<any[]>([])
  const [validatorCount, setValidatorCount] = useState(0)
  const {
    acceptedFiles, // all JSON files will pass this check (including BLS failures
    inputRef,
  } = useDropzone({
    accept: 'application/json',
  })

  const { account }: web3ReactInterface = useWeb3React<Web3Provider>()

  const handleStakeModalOpen = () => setIsStakeModalOpen(true)
  const handleStakeModalClose = () => setIsStakeModalOpen(false)

  const jsonFilesRef = useRef<any[]>()
  jsonFilesRef.current = jsonFilesArr

  const withdrawalAddress = useMemo<string>(() => {
    // eslint-disable-next-line camelcase
    const credentials = depositKeys[0]?.withdrawal_credentials ?? ''
    if (credentials.startsWith('01')) return `0x${credentials.slice(-40)}`
    return ''
  }, [depositKeys])

  // const withdrawalAddressShort = useMemo<string>(
  //   () => `${withdrawalAddress.slice(0, 6)}...${withdrawalAddress.slice(-4)}`,
  //   [withdrawalAddress]
  // );

  const totalDepositAmount = useMemo<string>(() => {
    const total = depositKeys.reduce((acc, key) => acc.plus(new BigNumber(key.amount)), new BigNumber(0))
    // Convert to readable format (ether)
    return total.dividedBy(new BigNumber(10).pow(9)).toString()
  }, [depositKeys])

  const flushDropzoneCache = useCallback(() => {
    acceptedFiles.length = 0
    acceptedFiles.splice(0, acceptedFiles.length)
    if (inputRef.current) {
      inputRef.current.value = ''
    }
  }, [acceptedFiles, inputRef])

  const checkJsonStructure = (depositDataJson: { [field: string]: any }) => {
    return !!(
      depositDataJson.pubkey ||
      depositDataJson.withdrawal_credentials ||
      depositDataJson.amount ||
      depositDataJson.signature ||
      depositDataJson.deposit_message_root ||
      depositDataJson.deposit_data_root ||
      depositDataJson.fork_version
    )
  }

  const handleToggleTermsAccepted = () => setHasAcceptedTerms(!hasAcceptedTerms)

  const handleTermsModalClose = () => {
    setIsTermsModalOpen(false)
    saveToLocalStorage('acceptedTerms', true)
  }

  const handleWrongNetwork = () => {
    setFileError(
      <Text>
        <FormattedMessage
          defaultMessage="This JSON file isn't for the right network. Upload a file generated for your current network: {consensusLayerName}."
          values={{ consensusLayerName }}
        />
      </Text>
    )
  }

  const handleSevereError = () => {
    setFileError(
      <Text>
        <FormattedMessage
          defaultMessage="Couldn't upload {depositFileName} due to an error. Open an issue in GitHub so we can investigate. "
          values={{
            depositFileName: <Code>{depositFileName}</Code>,
          }}
        />
        <Link
          primary
          inline
          to="https://github.com/ethereum/staking-launchpad/issues/new"
        >
          Open issue
        </Link>
      </Text>
    )
  }

  const handleTriggerTermsModal = () => setIsTermsModalOpen(true)

  const onFileDrop = async (jsonFiles: Array<any>, rejectedFiles: Array<any>) => {
    if (rejectedFiles?.length) {
      setFileError(
        <>
          <FormattedMessage defaultMessage="That is not a valid deposit_data JSON file." />
        </>
      )
      return
    }

    // check if the file is JSON
    if (jsonFiles.length > 0) {
      // Store the files in state in case of multiple uploads consecutively
      setJsonFilesArr((prev) => [...prev, ...jsonFiles])
      let _jsonFilesArr = jsonFiles
      // If there are multiple JSON files
      if (jsonFilesRef.current && jsonFilesRef.current.length > 1) {
        dispatchDepositFileNameUpdate(`${jsonFilesRef.current[0].name} and ${jsonFilesRef.current.length - 1} more`)
        // Combine contents of all JSON files into one piece of text. They are of type File
        const newArray = []
        for (let i = 0; i < jsonFilesRef.current.length; i += 1) {
          const file = jsonFilesRef.current[i]
          // eslint-disable-next-line no-await-in-loop
          const text = await file.text()
          const parsedArr = JSON.parse(text)
          newArray.push(...parsedArr)
        }
        _jsonFilesArr = [new Blob([JSON.stringify(newArray)])]
      } else {
        dispatchDepositFileNameUpdate(_jsonFilesArr[0].name)
      }

      setIsFileStaged(true) // unstaged via handleFileDelete
      setIsFileAccepted(true) // rejected if BLS check fails
      const reader = new FileReader()
      reader.onload = async (event) => {
        if (event.target) {
          try {
            const fileData: any[] = JSON.parse(event.target.result as string)
            const validatorCount = JSON.parse(event.target.result as string).length
            setValidatorCount(validatorCount)
            // perform BLS check
            if (await validateDepositKey(fileData as DepositKeyInterface[])) {
              // add valid files to redux
              dispatchDepositFileKeyUpdate(
                fileData.map((file: DepositKeyInterface) => ({
                  ...file,
                  transactionStatus: TransactionStatus.READY, // initialize each file with ready state for transaction
                  depositStatus: DepositStatus.VERIFYING, // assign to verifying status until the pubkey is checked via beaconscan
                }))
              )

              // Check for duplicates, throw error if there are any
              const uniqueKeys = new Set(fileData.map((item) => JSON.stringify(item)))
              if (uniqueKeys.size !== fileData.length) {
                setFileError(<FormattedMessage defaultMessage="Duplicate keys found in JSON files." />)
                return
              }

              // perform double deposit check
              try {
                const existingDeposits = await getExistingDepositsForPubkeys(fileData)
                const existingDepositPubkeys = existingDeposits.data.flatMap((x) => x.publickey.substring(2))
                ;(fileData as DepositKeyInterface[]).forEach(async (file) => {
                  if (existingDepositPubkeys.includes(file.pubkey)) {
                    dispatchDepositStatusUpdate(file.pubkey, DepositStatus.ALREADY_DEPOSITED, validatorCount)
                  } else {
                    dispatchDepositStatusUpdate(file.pubkey, DepositStatus.READY_FOR_DEPOSIT, validatorCount)
                  }
                })
              } catch (error) {
                dispatchBeaconChainAPIStatusUpdate(BeaconChainStatus.DOWN)
              }

              if (!hasAcceptedTerms) handleTriggerTermsModal()
            } else {
              // file is JSON but did not pass BLS, so leave it "staged" but not "accepted"
              setIsFileAccepted(false)
              dispatchDepositFileKeyUpdate([])
              flushDropzoneCache()

              // there are a couple special cases that can occur
              const { fork_version: forkVersion } = fileData[0] || {}
              const hasCorrectStructure = checkJsonStructure(fileData[0] || {})
              if (hasCorrectStructure && forkVersion !== GENESIS_FORK_VERSION.toString()) {
                // file doesn't match the correct network
                handleWrongNetwork()
              }
            }
          } catch (e) {
            // possible error example: json is invalid or empty so it cannot be parsed
            // TODO think about other possible errors here, and consider if we might want to set "isFileStaged"
            setValidatorCount(0)
            setIsFileAccepted(false)
            handleSevereError()
            dispatchDepositFileKeyUpdate([])
            flushDropzoneCache()
          }
        }
      }
      reader.readAsText(_jsonFilesArr[0])
    }
  }

  function handleSubmit() {
    if (account) {
      dispatchWorkflowUpdate(WorkflowStep.TRANSACTION_SIGNING)
    } else {
      dispatchWorkflowUpdate(WorkflowStep.CONNECT_WALLET)
    }
  }

  const handleFileDelete = useCallback(
    (e: SyntheticEvent) => {
      e.preventDefault()
      dispatchDepositFileNameUpdate('')
      dispatchDepositFileKeyUpdate([])
      setFileError(null)
      setIsFileStaged(false)
      setIsFileAccepted(false)
      setJsonFilesArr([])
      flushDropzoneCache()
    },
    [dispatchDepositFileKeyUpdate, dispatchDepositFileNameUpdate, flushDropzoneCache]
  )

  const { isDragActive, isDragAccept, isDragReject, getRootProps, getInputProps } = useDropzone({
    accept: 'application/json',
    // noClick: isFileStaged || isFileAccepted,
    onDrop: onFileDrop,
  })

  const renderMessage = useMemo(() => {
    if (isDragReject && !isFileStaged) {
      return (
        <div>
          <FormattedMessage defaultMessage="Upload a valid json file." />
        </div>
      )
    }

    if (isFileStaged || fileError) {
      return (
        <ButtonContainer>
          <DeleteBtn onClick={handleFileDelete}>
            <Close size="15px" />
          </DeleteBtn>
          {fileError || (
            <>
              {isFileAccepted && <Text>{depositFileName}</Text>}
              {!isFileAccepted && (
                <Text>
                  <FormattedMessage
                    defaultMessage="{depositFileName} isn't a valid deposit_data JSON file. Try again."
                    values={{
                      depositFileName: <span>{depositFileName}</span>,
                    }}
                  />
                </Text>
              )}
            </>
          )}
        </ButtonContainer>
      )
    }

    return (
      <div>
        <FormattedMessage defaultMessage="Drag file to upload or browse" />
      </div>
    )
  }, [isDragReject, isFileStaged, isFileAccepted, fileError, depositFileName, handleFileDelete])

  useEffect(() => {
    dispatchWorkflowUpdate(WorkflowStep.UPLOAD_VALIDATOR_FILE)
  }, [dispatchWorkflowUpdate])

  useEffect(() => {
    const hasAcceptedTermsStored = getFromLocalStorage('acceptedTerms')
    if (hasAcceptedTermsStored) {
      setHasAcceptedTerms(true)
    }
  }, [])

  return (
    <>
      <TermsModal
        isOpen={isTermsModalOpen}
        setIsOpen={setIsTermsModalOpen}
        hasAcceptedTerms={hasAcceptedTerms}
        onClose={handleTermsModalClose}
        handleToggleTermsAccepted={handleToggleTermsAccepted}
      />
      <StakeModal
        isOpen={isStakeModalOpen}
        onClose={handleStakeModalClose}
      />
      <WorkflowPageTemplate>
        <div className="mt-[108px] flex flex-col items-center gap-y-4 text-center">
          <h1 className="text-[40px] leading-[60px]">
            {isFileAccepted ? `Your Deposit Summary` : `Use your Pier Two generated file`}
          </h1>
          <div className="mx-auto">
            {isFileAccepted ? (
              <DepositSummary
                totalDepositAmount={totalDepositAmount}
                validatorCount={depositKeys.length}
                withdrawalAddress={withdrawalAddress}
              />
            ) : (
              <Dropzone
                isFileStaged={isFileStaged}
                isFileAccepted={isFileAccepted && !fileError}
                {...getRootProps({ isDragActive, isDragAccept, isDragReject })}
              >
                <input {...getInputProps()} />
                <FileUploadAnimation
                  isDragAccept={isDragAccept}
                  isDragReject={isDragReject}
                  isDragActive={isDragActive}
                  isFileStaged={!!(isFileStaged || fileError)}
                  isFileAccepted={isFileAccepted && !fileError}
                />

                <Text
                  className="mt20"
                  textAlign="center"
                >
                  {renderMessage}
                </Text>
              </Dropzone>
            )}
          </div>
          <div
            className={cx('mx-auto mb-2 w-full max-w-[570px] justify-center gap-x-4', {
              flex: !isFileAccepted,
              'grid grid-cols-2': isFileAccepted,
            })}
          >
            {isFileAccepted && (
              <Button
                onClick={handleFileDelete}
                variant="secondary"
              >
                Clear
              </Button>
            )}
            <Link
              to={account ? RoutesEnum.transactionsPage : RoutesEnum.connectWalletPage}
              onClick={handleSubmit}
              disabled={!hasAcceptedTerms || !hasAcceptedTerms}
              className="w-full"
            >
              <Button
                disabled={!isFileAccepted || !hasAcceptedTerms}
                className="w-full"
              >
                Continue
              </Button>
            </Link>
          </div>
          {!isFileAccepted && (
            <button
              onClick={handleStakeModalOpen}
              className="flex h-14 w-[360px] items-center justify-between gap-x-4 whitespace-nowrap bg-white p-4 text-base"
            >
              <span>Don’t have one? Stake with Pier Two</span>
              <img
                src={ArrowRightIcon}
                alt="arrow"
                className="h-4 w-[30px]"
              />
            </button>
          )}
        </div>
      </WorkflowPageTemplate>
    </>
  )
}

_UploadValidatorPage.displayName = 'UploadValidatorPage'

const mapStateToProps = (state: StoreState): StateProps => ({
  depositKeys: state.depositFile.keys,
  depositFileName: state.depositFile.name,
  workflow: state.workflow,
})

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  dispatchDepositFileNameUpdate: (name) => dispatch(updateDepositFileName(name)),
  dispatchDepositStatusUpdate: (pubkey, depositStatus, validatorCount) =>
    dispatch(updateDepositStatus(pubkey, depositStatus, validatorCount)),
  dispatchDepositFileKeyUpdate: (files) => dispatch(updateDepositFileKeys(files)),
  dispatchWorkflowUpdate: (step) => dispatch(updateWorkflow(step)),
  dispatchBeaconChainAPIStatusUpdate: (status) => dispatch(updateBeaconChainAPIStatus(status)),
})

export const UploadValidatorPage = connect<StateProps, DispatchProps, OwnProps, StoreState>(
  mapStateToProps,
  mapDispatchToProps
)(_UploadValidatorPage)

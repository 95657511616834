// @ts-nocheck
// @ts-nocheck
import React from 'react'
import styled from 'styled-components'
import { Button as GrommetButton, ButtonProps } from 'grommet'

interface CustomButtonProps {
  className?: string
  width?: number
  fullWidth?: boolean
  rainbow?: boolean
  onClick?: () => any
}

const calculateWidth = (p: { width?: number; fullWidth?: boolean }) => {
  if (p.width) {
    return `${p.width}px`
  }
  if (p.fullWidth) {
    return '100%'
  }
}

const StyledButton = styled(GrommetButton)`
  display: block;
  padding: 15px;
  text-transform: uppercase;
  width: ${calculateWidth};
  font-size: 18px;
  letter-spacing: 1.5px;
  border-radius: 8px;
  &:hover {
    box-shadow: 0px 8px 17px rgba(0, 0, 0, 0.15);
    background-color: ${(p) => `${p.theme.gray.light}`};
    color: ${(p) => `${p.theme.pierTwo.blue}`};
  }
  pointer-events: ${(p) => (p.disabled ? 'none' : 'auto')};
  // Disabled styles
  ${(p) =>
    // @ts-ignore
    p.disabled &&
    `pointer-events: none;
   `}
  ${(p) => `color: ${p.color === 'blueDark' ? 'white' : p.theme.pierTwo.blue};`}
  ${(p) => `border: 1px solid ${p.color === 'blueDark' ? 'white' : ''};`}
  // rainbow styles
  ${(p) =>
    // @ts-ignore
    p.rainbow &&
    `background-color: white;
   `}
`

export const Button = (props: CustomButtonProps & ButtonProps) => {
  const { className } = props
  return (
    <StyledButton
      className={className}
      {...props}
    />
  )
}

// Import libraries
import { useMemo } from 'react'
import { parseEther, parseUnits } from '@ethersproject/units'
import { BigNumber, FixedNumber } from '@ethersproject/bignumber'
// Utilities + types
import useSWR from 'swr'
import { fetcher } from '../utils/data'
import { convertDecimalStringToEthBn } from '../utils/numberFormatters'
import { COINGECKO_URL } from '../utils/envVars'
import { Currency } from '../pages/Validators/types'

export interface EthPriceData {
  // [timestamp, price in AUD]
  prices: any[]
  currentEthPrice: BigNumber
}

const ONE_DAY = 60 * 60 * 24

interface CoingeckoPriceData {
  prices: number[][]
}

export const useHistoricalEthPrices: (currency: Currency) => EthPriceData = (currency) => {
  // only refetch if the currency changes
  const { to, from } = useMemo(() => {
    const to = Math.round(Date.now() / 1000)
    return {
      to,
      // TODO make this less hacky and fetch on range specified by validators
      from: to - ONE_DAY * 365 * 4,
    }
  }, [currency])

  const { data: prices } = useSWR(
    `${COINGECKO_URL}/api/v3/coins/ethereum/market_chart/range?vs_currency=${currency}&from=${from}&to=${to}`,
    fetcher
  )

  return useMemo(() => {
    if (!prices) {
      return {
        prices: [],
        currentEthPrice: BigNumber.from('0'),
      }
    }
    const prices_ = prices.prices
    const currentEthPrice = prices_.length
      ? convertDecimalStringToEthBn(prices_[prices_.length - 1][1])
      : BigNumber.from('0')

    return {
      prices: prices_,
      currentEthPrice,
    }
  }, [prices])
}

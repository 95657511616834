import cx from 'classnames'
import React, { FC, useRef, useState } from 'react'
import { Connector } from '@web3-react/types'
import { useOutsideClick } from '../../hooks/useOutsideClick'
import ChevronDownIcon from '../../static/chevron-down.svg'
import metamaskLogo from '../../static/metamask.svg'
import walletConnectLogo from '../../static/wallet-connect-icon.svg'
import WalletIcon from '../../static/wallet.svg'
import { EL_EXPLOER_URL } from '../../utils/envVars'

const ICONS = {
  metamask: metamaskLogo,
  'wallet-connect': walletConnectLogo,
}

interface WalletDropdownProps {
  walletProviderName: 'metamask' | 'wallet-connect' | undefined
  connector: Connector | undefined
  walletConnected: boolean
  account: string | null | undefined
  deactivate: ((...args: unknown[]) => void) | undefined
  handleOpenConnectModal: () => void
}

export const WalletDropdown: FC<WalletDropdownProps> = ({
  walletProviderName,
  connector,
  walletConnected,
  account,
  deactivate,
  handleOpenConnectModal,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  const containerRef = useRef<HTMLDivElement>(null)

  useOutsideClick(containerRef, () => setIsDropdownOpen(false))

  return (
    <div
      ref={containerRef}
      className="relative h-12"
    >
      <button
        onClick={() => (!walletConnected ? handleOpenConnectModal() : setIsDropdownOpen(!isDropdownOpen))}
        className={cx('flex h-12 items-center gap-x-2 px-2.5', {
          'pr-6': !account,
        })}
      >
        {walletConnected && walletProviderName ? (
          <img
            src={ICONS[walletProviderName]}
            alt="Wallet Icon"
            className="h-8 w-8"
          />
        ) : (
          <img
            src={WalletIcon}
            alt="Wallet Icon"
            className="h-[22px] w-[22px]"
          />
        )}
        <span className="text-pt-dark-indigo">{walletConnected ? `${String(account).slice(0, 5)}...` : `Connect`}</span>
        {walletConnected && (
          <img
            src={ChevronDownIcon}
            alt="Chevron Down"
            className="h-[14px] w-[14px]"
          />
        )}
      </button>
      <div
        className={cx(
          'absolute left-0 top-[calc(100%)] flex w-full flex-col border-t border-pt-dark-indigo bg-pt-light-indigo text-center transition-opacity',
          {
            'pointer-events-none opacity-0': !isDropdownOpen || !walletConnected,
          }
        )}
      >
        <a
          href={`${EL_EXPLOER_URL}/address/${account}`}
          target="_blank"
          rel="noreferrer"
          className="border-b border-pt-dark-indigo"
        >
          <button className="h-[39px] whitespace-nowrap px-4">View in Explorer</button>
        </a>
        <button
          onClick={() => {
            deactivate ? deactivate() : console.error('Deactivate not implemented')
          }}
          className="h-[39px] px-4"
        >
          Disconnect
        </button>
      </div>
    </div>
  )
}

export default WalletDropdown

import { BigNumber } from '@ethersproject/bignumber'
import { formatEther } from '@ethersproject/units'
import React from 'react'
import { Container } from '../../../components/Container'
import { formatPercent } from '../../../utils/numberFormatters'

type Props = {
  locale: any
  numberOfValidators: number
  validatorShare: number
  blocksProposed: number
  biggestBlockReward: BigNumber
}

export const ValidatorStatsHeader = ({
  locale,
  numberOfValidators,
  validatorShare,
  blocksProposed,
  biggestBlockReward,
}: Props) => {
  return (
    <div className="auto-cols-4 fixed left-0 top-[88px] z-50 grid w-full grid-flow-col bg-pt-light-indigo py-2 text-pt-dark-indigo">
      <Container className="flex justify-between gap-x-10">
        <div>Validators: {numberOfValidators}</div>
        <div>Network share: {formatPercent(validatorShare, locale)}</div>
        <div>Blocks proposed: {blocksProposed}</div>
        <div>Biggest block: {Number(formatEther(biggestBlockReward)).toFixed(4)} ETH</div>
      </Container>
    </div>
  )
}

export default ValidatorStatsHeader

// @ts-nocheck
import React from 'react'
import { Redirect } from 'react-router-dom'
import { RoutesEnum } from '../Routes'
import { WorkflowStep } from '../store/actions/workflowActions'

export const routeToCorrectWorkflowStep = (step: WorkflowStep): JSX.Element => {
  if (step === WorkflowStep.UPLOAD_VALIDATOR_FILE) {
    return (
      <Redirect
        push
        to={RoutesEnum.uploadValidatorPage}
      />
    )
  }
  if (step === WorkflowStep.CONNECT_WALLET) {
    return (
      <Redirect
        push
        to={RoutesEnum.connectWalletPage}
      />
    )
  }
  // if (step === WorkflowStep.SUMMARY) {
  //   return <Redirect push to={RoutesEnum.summaryPage} />;
  // }
  if (step === WorkflowStep.TRANSACTION_SIGNING) {
    return (
      <Redirect
        push
        to={RoutesEnum.transactionsPage}
      />
    )
  }
  if (step === WorkflowStep.CONGRATULATIONS) {
    return (
      <Redirect
        push
        to={RoutesEnum.congratulationsPage}
      />
    )
  }
  return (
    <Redirect
      push
      to={RoutesEnum.notFoundPage}
    />
  )
}

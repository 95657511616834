import cx from 'classnames'
import React from 'react'
import CheckIcon from '../../../static/check.svg'

type Props = {
  onChange: (event: { target: { checked: boolean } }) => any
  checked: boolean
}

export const CheckBox = (props: Props) => (
  <div className="relative h-4 w-4">
    <img
      src={CheckIcon}
      alt="Check"
      className={cx(
        'pointer-events-none absolute left-1/2 top-1/2 h-3 w-3 -translate-x-1/2 -translate-y-[calc(50%-1px)]',
        {
          'opacity-0': !props.checked,
        }
      )}
    />
    <input
      type="checkbox"
      onChange={props.onChange}
      checked={props.checked}
      className={cx('h-4 w-4 cursor-pointer appearance-none rounded', {
        'bg-pt-dark-indigo': props.checked,
        'bg-pt-light-indigo': !props.checked,
      })}
    />
  </div>
)

export default CheckBox

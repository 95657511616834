import { Web3Provider } from '@ethersproject/providers'
import { useWeb3React } from '@web3-react/core'
import BigNumber from 'bignumber.js'
import _every from 'lodash/every'
import _some from 'lodash/some'
import { useEffect, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Dispatch } from 'redux'
import { Button } from '../../components/CustomButton/index'
import { DepositSummary } from '../../components/DepositSummary'
import { Spinner } from '../../components/Spinner'
import { WorkflowPageTemplate } from '../../components/WorkflowPage/WorkflowPageTemplate'
import { usePendingValidators } from '../../hooks/usePendingValidators'
import {
  DepositStatus,
  DispatchTransactionStatusUpdateType,
  TransactionStatus,
  updateTransactionStatus,
} from '../../store/actions/depositFileActions'
import { DispatchWorkflowUpdateType, WorkflowStep, updateWorkflow } from '../../store/actions/workflowActions'
import { DepositKeyInterface, StoreState } from '../../store/reducers'
import { routeToCorrectWorkflowStep } from '../../utils/RouteToCorrectWorkflowStep'
import { WrongNetwork } from '../ConnectWallet/WrongNetwork'
import { TARGET_NETWORK_CHAIN_ID } from '../ConnectWallet/web3Utils'
import { handleMultipleTransactions } from './transactionUtils'

interface OwnProps {}
interface StateProps {
  depositKeys: DepositKeyInterface[]
  workflow: WorkflowStep
}
interface DispatchProps {
  dispatchTransactionStatusUpdate: DispatchTransactionStatusUpdateType
  dispatchWorkflowUpdate: DispatchWorkflowUpdateType
}
type Props = StateProps & DispatchProps & OwnProps

const _TransactionsPage = ({
  depositKeys,
  workflow,
  dispatchTransactionStatusUpdate,
  dispatchWorkflowUpdate,
}: Props): JSX.Element => {
  const { formatMessage } = useIntl()
  const { account, chainId, connector } = useWeb3React<Web3Provider>()

  const { addPendingValidators } = usePendingValidators()

  const totalTxCount = depositKeys.filter((key) => key.depositStatus !== DepositStatus.ALREADY_DEPOSITED).length

  const remainingTxCount = depositKeys.filter(
    (file) =>
      file.depositStatus !== DepositStatus.ALREADY_DEPOSITED &&
      (file.transactionStatus === TransactionStatus.READY || file.transactionStatus === TransactionStatus.REJECTED)
  ).length

  const allTxConfirmed = _every(depositKeys.map((file) => file.transactionStatus === TransactionStatus.SUCCEEDED))

  // const allDeposited = _every(depositKeys.map((file) => file.depositStatus === DepositStatus.ALREADY_DEPOSITED))

  const oneTxConfirmed = _some(depositKeys.map((file) => file.transactionStatus === TransactionStatus.SUCCEEDED))
  const waitingForTxConfirmation = _some(
    depositKeys.map((file) => file.transactionStatus === TransactionStatus.STARTED)
  )

  const createButtonText = (): string => {
    if (totalTxCount === 1) {
      return formatMessage({ defaultMessage: 'Confirm' })
    }
    if (totalTxCount === remainingTxCount && totalTxCount > 0) {
      return formatMessage(
        {
          defaultMessage: `Send deposit for {totalTxCount} validators`,
        },
        { totalTxCount }
      )
    }
    if (remainingTxCount > 1) {
      return formatMessage(
        {
          defaultMessage: `Send remaining {remainingTxCount} deposits`,
        },
        { remainingTxCount }
      )
    }
    if (remainingTxCount === 1) {
      return formatMessage({ defaultMessage: `Send last deposit` })
    }
    return formatMessage({ defaultMessage: 'This deposit file has already been used' })
  }

  const createContinueButtonText = (): string => {
    if (!oneTxConfirmed) {
      return formatMessage({ defaultMessage: 'Continue' })
    }
    return allTxConfirmed
      ? formatMessage({ defaultMessage: 'Continue' })
      : formatMessage({
          defaultMessage: 'Continue without all transactions confirmed',
        })
  }

  const handleAllTransactionsClick = () => {
    handleMultipleTransactions(
      depositKeys.filter((key) => key.depositStatus !== DepositStatus.ALREADY_DEPOSITED),
      connector,
      account,
      dispatchTransactionStatusUpdate,
      dispatchWorkflowUpdate
    )
  }

  const routerHistory = useHistory()

  const addValidators = (routeToValidators: boolean) => {
    addPendingValidators(
      depositKeys.map((d) => ({
        pubKey: `0x${d.pubkey}`,
        // manually add deposit time as now
        depositTime: Math.floor(Date.now() / 1000),
      }))
    )

    if (routeToValidators) {
      routerHistory.push('/validators')
    }
  }

  const handleSubmit = () => {
    if (workflow === WorkflowStep.TRANSACTION_SIGNING) {
      // saveToLocalStorage('hasCompletedTransactionSigning', 'true')
      dispatchWorkflowUpdate(WorkflowStep.CONGRATULATIONS)
    }
  }

  const totalDepositAmount = useMemo<string>(() => {
    const total = depositKeys.reduce((acc, key) => acc.plus(new BigNumber(key.amount)), new BigNumber(0))
    // Convert to readable format (ether)
    return total.dividedBy(new BigNumber(10).pow(9)).toString()
  }, [depositKeys])

  const withdrawalAddress = useMemo<string>(() => {
    // eslint-disable-next-line camelcase
    const credentials = depositKeys[0]?.withdrawal_credentials ?? ''
    if (credentials.startsWith('01')) return `0x${credentials.slice(-40)}`
    return ''
  }, [depositKeys])

  if (!account || !connector)
    return (
      <div className="mx-auto mt-[150px] w-max rounded-md bg-white p-4 shadow-modal">
        <p>Please reconnect your wallet to continue</p>
      </div>
    )

  if (chainId !== TARGET_NETWORK_CHAIN_ID) return <WrongNetwork />

  useEffect(() => {
    if (totalTxCount > 0 && remainingTxCount === 0 && allTxConfirmed) {
      handleSubmit()
    }
  }, [totalTxCount, remainingTxCount, allTxConfirmed])

  if (workflow !== WorkflowStep.TRANSACTION_SIGNING) {
    return routeToCorrectWorkflowStep(workflow)
  }

  return (
    <WorkflowPageTemplate title="Transactions">
      <div className="mx-auto mt-[150px] flex w-max flex-col items-center gap-y-4">
        <h1 className="text-center text-[40px] leading-[60px]">Your Deposit Summary</h1>
        <DepositSummary
          totalDepositAmount={totalDepositAmount}
          validatorCount={depositKeys.length}
          withdrawalAddress={withdrawalAddress}
        />
        <Button
          className="w-full"
          onClick={handleAllTransactionsClick}
          disabled={remainingTxCount === 0}
        >
          {waitingForTxConfirmation ? (
            <Spinner
              width="20px"
              height="20px"
            />
          ) : (
            createButtonText()
          )}
        </Button>
        {/* {totalTxCount > 0 && remainingTxCount === 0 && (
          <div
            className="cursor-pointer"
            onClick={() => addValidators(true)}
          >
            View validators
          </div>
        )} */}
        {/* {!oneTxConfirmed ? (
          <Button
            className="w-full"
            onClick={handleAllTransactionsClick}
            disabled={remainingTxCount === 0}
          >
            {createButtonText()}
          </Button>
        ) : (
          <Button
            className="w-full"
            disabled={!oneTxConfirmed}
          >
            {createContinueButtonText()}
          </Button>
        )} */}
      </div>
      {/* <Paper className="mt20">
        <Heading level={3} size="small" color="blueMedium">
          {depositKeys.length === 1 ? (
            <FormattedMessage defaultMessage="Confirm deposit" />
          ) : (
            <FormattedMessage
              defaultMessage="Confirm deposits ({depositKeys})"
              values={{ depositKeys: depositKeys.length }}
            />
          )}
        </Heading>
        {depositKeys.length === 1 && (
          <Text className="mt20">
            <FormattedMessage defaultMessage="Submit a transaction to finish your deposit." />
          </Text>
        )}
        {depositKeys.length > 1 && (
          <>
            <Text className="mt20">
              <FormattedMessage defaultMessage="You must sign a transaction for every deposit you want to make." />
            </Text>
            <Text className="mt10">
              <FormattedMessage defaultMessage="You can start all the transactions at once, or start them individually." />
            </Text>
          </>
        )}
        {depositKeys.length >= 1 && <KeyList />}
        <div className="center mt30 flex">
          <Button
            onClick={handleAllTransactionsClick}
            disabled={remainingTxCount === 0}
          >
            {createButtonText()}
            </Button>
        </div>
      </Paper>
      <ButtonContainer>
        <Link to={RoutesEnum.connectWalletPage}>
          <Button
            width={100}
            color="blueDark"
            label={formatMessage({ defaultMessage: 'Back' })}
          />
        </Link>
        <Link to={RoutesEnum.congratulationsPage} onClick={handleSubmit}>
          <Button
            disabled={!oneTxConfirmed}
          >
            {createContinueButtonText()}
          </Button>
        </Link>
      </ButtonContainer> */}
    </WorkflowPageTemplate>
  )
}

const mapStateToProps = ({ depositFile, workflow }: StoreState): StateProps => ({
  depositKeys: depositFile.keys,
  workflow,
})

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  dispatchWorkflowUpdate: (step) => dispatch(updateWorkflow(step)),
  dispatchTransactionStatusUpdate: (pubkey, status, txHash) =>
    dispatch(updateTransactionStatus(pubkey, status, txHash)),
})

export const TransactionsPage = connect<StateProps, DispatchProps, OwnProps, StoreState>(
  mapStateToProps,
  mapDispatchToProps
)(_TransactionsPage)

import { Action, ActionTypes } from '../actions'
import { ValidatorsAction, UpdateValidatorsAction, IndexValidationAction } from '../actions/validatorsActions'
import { produce } from 'immer'
import { saveToLocalStorage, getFromLocalStorage, setUrlParams } from '../../utils/localStorage'

// ensures a validatorsList is correct
export const parseValidatorsList = (validators: string, maxIndex?: number): string[] => {
  const v = validators.replace(' ', '')
  return v.split(',').filter((v) => {
    let n = Number(v)
    return !isNaN(n) && ((maxIndex && n <= maxIndex) || !maxIndex)
  })
}

const getInvalidIndices = (validators: string, maxIndex: number): Record<string, boolean> =>
  validators
    .split(',')
    .filter((v: string) => Number.isNaN(Number(v)) || Number(v) > maxIndex || Number(v) < 1)
    .reduce((o, v) => {
      o[v] = true
      return o
    }, {} as Record<string, boolean>)

export type ValidatorsState = {
  validatorsList: string
  uploads: Record<string, string>
  names: Record<string, string>

  invalidValidators: Record<string, boolean>
  hasValidatedList: boolean
  maxIndex?: number
}

const initialState: ValidatorsState = {
  validatorsList: '',
  uploads: {},
  names: {},

  hasValidatedList: false,
  invalidValidators: {},
}

export const validatorsReducer = (state: ValidatorsState = initialState, action: Action) => {
  if (action.type === ActionTypes.updateValidatorsAction) {
    return produce(state, (draftState) => {
      if (action.payload.action === ValidatorsAction.CLEAR_VALIDATORS) {
        saveToLocalStorage('validators', '')
        draftState.uploads = {}
        draftState.validatorsList = ''
        draftState.invalidValidators = {}
      } else if (action.payload.action === ValidatorsAction.ADD_VALIDATORS) {
        console.debug(`Adding validators ${action.payload.validators}, with current max index: ${draftState.maxIndex}`)
        const givenList = new Set(parseValidatorsList(action.payload.validators, draftState.maxIndex))
        const tmpSet = new Set(draftState.validatorsList.split(','))

        givenList.forEach((v) => {
          if (!tmpSet.has(v)) {
            tmpSet.add(v)
          } else {
            givenList.delete(v)
          }
        })
        const uniqueGiven: string = [...givenList].filter((s) => !!s).join(',')
        const uniqueValidators: string = [...tmpSet].filter((s) => !!s).join(',')

        saveToLocalStorage('validators', uniqueValidators)
        setUrlParams('validators', uniqueValidators)

        draftState.validatorsList = uniqueValidators

        if (draftState?.maxIndex) {
          draftState.invalidValidators = getInvalidIndices(uniqueValidators, draftState.maxIndex)
        }

        if (!!uniqueGiven) {
          const src = action.payload.src
          if (src) {
            const addedFromSrc = draftState.uploads[src] ? `${uniqueGiven},${draftState.uploads[src]}` : uniqueGiven
            draftState.uploads[src] = addedFromSrc
          }
        }
      } else if (action.payload.action === ValidatorsAction.REMOVE_VALIDATORS) {
        const src = action.payload.src
        if (src && draftState.uploads[src]) {
          delete draftState.uploads[src]
        }
        const givenList = new Set(parseValidatorsList(action.payload.validators))
        const tmpSet = new Set(draftState.validatorsList.split(','))
        givenList.forEach((index) => {
          tmpSet.delete(index)
        })
        const uniqueValidators: string = [...tmpSet].filter((s) => !!s).join(',')
        draftState.validatorsList = uniqueValidators

        if (draftState?.maxIndex) {
          draftState.invalidValidators = getInvalidIndices(uniqueValidators, draftState.maxIndex)
        }
        setUrlParams('validators', uniqueValidators)
      }
    })
  } else if (action.type === ActionTypes.updateValidatorsNameAction) {
    console.debug('Saving name to local storage', action.payload.records)
    return produce(state, (draftState) => {
      Object.keys(action.payload.records).forEach((k) => {
        draftState.names[k] = action.payload.records[k]
      })
      saveToLocalStorage('names', JSON.stringify(draftState.names))
    })
  } else if (action.type === ActionTypes.updateIndexValidationAction) {
    if (action.payload.action === IndexValidationAction.SET_MAX_INDEX) {
      const maxIndex = action.payload.maxIndex
      return produce(state, (draftState) => {
        draftState.maxIndex = maxIndex

        draftState.invalidValidators = getInvalidIndices(draftState.validatorsList, maxIndex)
        draftState.hasValidatedList = true
      })
    }
  }
  return state
}

import React, { FC } from 'react'
import InfoIcon from '../../static/info.svg'

interface DepositSummaryProps {
  totalDepositAmount: string
  validatorCount: number
  withdrawalAddress: string
}

export const DepositSummary: FC<DepositSummaryProps> = ({ totalDepositAmount, validatorCount, withdrawalAddress }) => (
  <div className="flex w-[570px] flex-col overflow-hidden rounded-[10px] bg-white">
    <div className="grid grid-cols-2">
      <div className="flex items-center justify-center py-10">{totalDepositAmount} ETH</div>
      <div className="flex items-center justify-center py-10">
        {validatorCount} Validator{validatorCount > 1 && 's'}
      </div>
    </div>
    <div className="mb-8 px-4 text-center">Withdrawal Address: {withdrawalAddress}</div>
    <div className="flex items-center gap-x-2 bg-pt-light-indigo p-4">
      <img
        src={InfoIcon}
        alt="Info"
        className="h-4 w-4"
      />
      <span> Your withdrawal address cannot be changed once deposited </span>
    </div>
  </div>
)

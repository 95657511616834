import { PRICE_PER_VALIDATOR } from '../../utils/envVars'
import { roundToMidnight } from '../../utils/common'
import { BigNumber } from '@ethersproject/bignumber'
import { formatEther } from '@ethersproject/units'
import { Rewards } from '../../types/validators'
import { DateRange, DateRangeType } from './types'
import { ParsedIncomeHistory } from '../../hooks/useValidatorsIncomeData'

export type CustomDateRange = [string, string]

export const allRewards: (reward: Rewards) => BigNumber = (reward) => reward.cl.add(reward.el)

// @requires timestamps in milliseconds
const daysBetween = (t1: number, t2: number): number => {
  const msDifference = t2 - t1
  return Math.ceil(msDifference / (1000 * 60 * 60 * 24))
}

const getRangeMultiplier = (range?: DateRange): number => {
  if (!range) {
    return 1
  }
  if (range.t === DateRangeType.DAY) {
    return 365
  } else if (range.t === DateRangeType.WEEK) {
    return 52
  } else if (range.t === DateRangeType.MONTH) {
    return 12
  } else if (range.t === DateRangeType.CUSTOM && range.from && range.to) {
    const days = daysBetween(range.from, range.to)
    if (days === 0) {
      return 365
    }
    return Math.ceil(365 / days)
  }
  // default 1 year
  return 1
}
export const calcApr = (earnt: BigNumber, ethPrice: BigNumber, rewardTimeFrame?: DateRange): number => {
  if (ethPrice.eq(0)) {
    return 0
  }
  let multiplier = getRangeMultiplier(rewardTimeFrame)
  const returns = earnt.mul(ethPrice).mul(multiplier)
  return Number(formatEther(returns.div(ethPrice.mul(PRICE_PER_VALIDATOR))))
}

// Time in millisecnds
const TIME = {
  DAY: 24 * 60 * 60 * 1000,
}

// gets the past week in YYYY-MM-DD format
export const getCurrentWeekISOStrings = (): CustomDateRange => {
  const date = new Date() // Get the current date and time
  const to = date.toISOString().split('T')[0]

  date.setDate(date.getDate() - 7)
  const from = date.toISOString().split('T')[0]

  return [from, to]
}

export const getFilterTimestamp = (
  range: DateRange,
  customRange?: CustomDateRange
): { from: number; to: number } | undefined => {
  const now = roundToMidnight(Date.now() / 1000) * 1000
  if (!range.from || !range.to || range.t === DateRangeType.ALL) {
    return undefined
  }
  return {
    from: range.from,
    to: range.to,
  }
}

export const getInitialFilterTimestamp = (range: DateRangeType): { from: number; to: number } | undefined => {
  const now = roundToMidnight(Date.now() / 1000) * 1000
  if (range === DateRangeType.DAY) {
    return {
      from: now - TIME.DAY,
      to: now,
    }
  } else if (range === DateRangeType.WEEK) {
    return {
      from: now - TIME.DAY * 7,
      to: now,
    }
  } else if (range === DateRangeType.MONTH) {
    return {
      from: now - TIME.DAY * 31,
      to: now,
    }
  } else if (range === DateRangeType.CUSTOM) {
    return {
      ...parseCustomDateRange(getCurrentWeekISOStrings()),
    }
  }
  // undefined is used for all time
  return undefined
}

export const timeFilter = <G extends { timestamp: string }>(
  dateRange: DateRange,
  events: G[],
  customRange?: CustomDateRange
): G[] => {
  const rangeFilter = getFilterTimestamp(dateRange, customRange)

  if (dateRange.t === DateRangeType.CUSTOM && rangeFilter) {
    // need to filter for less than as well
    const rangeFiltered = rangeFilter
      ? events.filter(({ timestamp }) => {
          const date = Date.parse(timestamp)
          return date >= rangeFilter.from && date <= rangeFilter.to
        })
      : []
    return rangeFiltered
  }
  // else
  const rangeFiltered = rangeFilter
    ? events.filter(({ timestamp }) => Date.parse(timestamp) > rangeFilter.from)
    : events
  return rangeFiltered
}

const formatTimestamp = (t: number): string => {
  const date = new Date(t)
  return date.toISOString().split('T')[0]
}

export const formatCustomDateRange = (dateRange: DateRange): [string, string] => {
  if (!dateRange.from || !dateRange.to) {
    return getCurrentWeekISOStrings()
  }

  return [formatTimestamp(dateRange.from), formatTimestamp(dateRange.to)]
}

export const parseCustomDateRange = (customRange: [string, string]): { from: number; to: number } => {
  const from = new Date(customRange[0])
  const to = new Date(customRange[1])
  return {
    from: roundToMidnight(from.getTime() / 1000) * 1000,
    to: roundToMidnight(to.getTime() / 1000) * 1000,
  }
}

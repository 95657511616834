import { BigNumber } from '@ethersproject/bignumber'

// index
export enum Currency {
  AUD = 'AUD',
  USD = 'USD',
  ETH = 'ETH',
}

export enum DateRangeType {
  DAY = '1d',
  WEEK = '7d',
  MONTH = '30d',
  ALL = 'All Time',
  CUSTOM = 'Custom',
}

export type DateRange = {
  t: DateRangeType
  from?: number
  to?: number
}

// Validator Stats
export type GraphRewardsBreakdown = {
  type: 'head' | 'target' | 'source' | 'sync'
  rewards: number
}

export type GraphIncomeData = {
  timestamp: string
  date: string
  cumulative: number
  reward: number // in usd

  index: number
  rawEthReward: BigNumber
}

export type GraphStakingData = {
  date: string
  // cumulative: string
  staked: number
}

export type GraphAprData = {
  date: string
  apr: number
}

import React from 'react'
import { Helmet } from 'react-helmet'
import { useIntl } from 'react-intl'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import styled from 'styled-components'
import { RoutesEnum } from '../../Routes'
import { Container } from '../Container'
import { DesktopOnlyModal } from '../DesktopOnlyModal'
import { Footer } from '../Footer'

const Content = styled.div`
  width: 100%;
  max-width: 1024px;
  margin: 30px 0;
  position: relative;
`

const Gutter = styled.div`
  padding: 0 48px 5rem;
  display: flex;
  justify-content: center;
`
const Background = styled.div`
  min-height: 100vh;
`

const mapPathnameToWorkflowStep = (pathname: RoutesEnum) => {
  const workflowRoutesInOrder = [
    '',
    '',
    '',
    RoutesEnum.uploadValidatorPage,
    RoutesEnum.connectWalletPage,
    RoutesEnum.summaryPage,
    RoutesEnum.transactionsPage,
  ]
  return workflowRoutesInOrder.indexOf(pathname)
}

interface Props extends RouteComponentProps {
  children?: React.ReactNode
  title?: string
  description?: string
  history: any
}

const _WorkflowPageTemplate = ({ children, title, description, history }: Props): JSX.Element => {
  const { locale } = useIntl()
  if ((window as any).mobileCheck && (window as any).mobileCheck()) {
    return <DesktopOnlyModal />
  }

  const { pathname } = history.location
  const path = pathname.startsWith(`/${locale}`) ? pathname.substr(`/${locale}`.length) : pathname

  return (
    <Background>
      <Helmet>
        <title>{title}</title>
        <meta
          property="og:title"
          content={title}
        />
        <meta
          property="twitter:title"
          content={title}
        />
        <meta
          name="description"
          content={description}
        />
        <meta
          property="og:description"
          content={description}
        />
        <meta
          property="twitter:description"
          content={description}
        />
      </Helmet>
      <main className="flex min-h-screen flex-col">
        <section className="h-full flex-1 pb-20">
          <Container>{children}</Container>
        </section>
        <Footer />
      </main>
    </Background>
  )
}

export const WorkflowPageTemplate = withRouter(_WorkflowPageTemplate)

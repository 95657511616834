import { useEffect, useState, useCallback } from 'react'
import { saveToLocalStorage, getFromLocalStorage } from '../utils/localStorage'
import { produce } from 'immer'
import { PENDING_VALIDATORS_KEY } from '../utils/envVars'

export const storePendingValidators = (toAdd: PendingValidator[]) => {
  const unique: Record<string, PendingValidator> = {}
  const current = getFromLocalStorage(PENDING_VALIDATORS_KEY)

  const checkAndAddUnique = (a: PendingValidator[]) => {
    a.forEach((v) => {
      const lowerCaseKey = v.pubKey.toLowerCase()
      if (!unique[lowerCaseKey]) {
        unique[lowerCaseKey] = v
      }
    })
  }

  if (current) {
    checkAndAddUnique(JSON.parse(current))
  }
  checkAndAddUnique(toAdd)

  const pendingUnique = Object.values(unique)
  saveToLocalStorage(PENDING_VALIDATORS_KEY, JSON.stringify(pendingUnique))
}

export type PendingValidator = {
  pubKey: string
  depositTime: number
}
export type AddPendingValidators = (validators: PendingValidator[]) => void
export type RemovePendingValidators = (validators: PendingValidator['pubKey'][]) => void

export const usePendingValidators = (): {
  pendingValidators: PendingValidator[]
  addPendingValidators: AddPendingValidators
  removePendingValidators: RemovePendingValidators
} => {
  const [pendingValidators, setPendingValidators] = useState<PendingValidator[]>([])

  useEffect(() => {
    const validators = getFromLocalStorage(PENDING_VALIDATORS_KEY)
    if (validators) {
      const parsed = JSON.parse(validators)
      setPendingValidators(parsed.concat(pendingValidators))
    }
  }, [])

  const addPendingValidators = useCallback(
    (validators: PendingValidator[]) => {
      const newState: PendingValidator[] = []

      const exists: Record<string, boolean> = pendingValidators.reduce((o, v) => {
        o[v.pubKey] = true
        return o
      }, {} as Record<string, boolean>)

      validators.forEach((v) => {
        // dont push duplicates
        if (!exists[v.pubKey]) {
          newState.push(v)
        }
      })

      storePendingValidators(newState)
    },
    [pendingValidators, setPendingValidators]
  )

  const removePendingValidators = useCallback(
    (validators: PendingValidator['pubKey'][]) => {
      if (validators.length === 0 || pendingValidators.length === 0) {
        return
      }
      const toRemove: Record<string, boolean> = validators.reduce((o, v) => {
        o[v] = true
        return o
      }, {} as Record<string, boolean>)

      const newState = pendingValidators.filter((v) => !toRemove[v.pubKey])
      saveToLocalStorage(PENDING_VALIDATORS_KEY, JSON.stringify(newState))
      setPendingValidators(newState)
    },
    [pendingValidators, setPendingValidators]
  )

  return {
    pendingValidators,
    removePendingValidators,
    addPendingValidators,
  }
}
export default usePendingValidators

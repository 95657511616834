import { initializeConnector } from '@web3-react/core'
import { WalletConnect } from '@web3-react/walletconnect-v2'
import { WALLET_CONNECT_PROJECT_ID } from '../../../utils/envVars'

import { NetworkChainId } from '../../../constants/web3'

export const [walletConnect, walletConnectHooks] = initializeConnector<WalletConnect>(
  (actions) =>
    new WalletConnect({
      actions,
      defaultChainId: NetworkChainId.Mainnet,
      options: {
        projectId: WALLET_CONNECT_PROJECT_ID,
        chains: [NetworkChainId.Mainnet],
        showQrModal: true,
      },
    })
)

import cx from 'classnames'
import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import RemoveIcon from '../../static/remove.png'
import { PendingValidator } from '../../hooks/usePendingValidators'
import shortenAddress from '../../utils/shortenAddress'
import ReactTooltip from 'react-tooltip'

const Dropzone = styled.div`
  outline: none;
  :focus {
    outline: none;
  }
  width: 436px;
  height: 240px;
  cursor: ${(p: { isFileAccepted: boolean; isFileStaged: boolean }) =>
    p.isFileAccepted || p.isFileStaged ? 'inherit' : 'pointer'};
  padding: 30px;
  border-radius: 6px;
  background-color: white;
  box-shadow: 0px 4px 4px 0px #00000040;
`

const invalidValidator = 'bg-red-500'
const removeableValidator =
  'rounded-[7px] bg-pt-light-indigo text-pt-dark-indigo text-base pl-2 inline-flex items-center gap-x-1.5 max-h-[30px] pr-1.5 font-sourceSansPro'
const pendingValidator = `opacity-70`

type Props = {
  setExtraValidators: (s: string, fileName?: string) => void
  removeValidators: (s: string, fileName?: string) => void
  validatorUploads: Record<string, string>
  validatorsList: string
  pendingValidators: PendingValidator[]
  removePendingValidators: (pubKeys: string[]) => void
  hasInvalidList: boolean
  invalidValidators: Record<string, boolean>
  maxIndex?: number
}

const AddValidators: React.FC<Props> = ({
  setExtraValidators,
  validatorUploads,
  validatorsList,
  pendingValidators,
  removePendingValidators,
  removeValidators,
  hasInvalidList,
  invalidValidators,
  maxIndex,
}) => {
  const [validators, setValidators] = useState('')
  const [inputIsInvalid, setInputIsInvalid] = useState(false)

  const handleSetExtraValidators = () => {
    const regex = /^\s*\d+(\s*,\s*\d+)*\s*$/
    if (!regex.test(validators)) {
      alert('Please enter a valid comma-separated list of numbers.')
    } else {
      const v = validators.replace(' ', '')
      setExtraValidators(v)
    }
  }

  const handleRemoveValidators = useCallback(
    (list: string, fileName?: string) => {
      removeValidators(list, fileName)
      removePendingValidators(pendingValidators.map((p) => p.pubKey))
    },
    [removeValidators, removePendingValidators, pendingValidators]
  )

  const validateInputOnChange = (e: any) => {
    const validatorList = e.target.value

    const inputIsInvalid = validatorsList
      .split(',')
      .some((v: string) => Number.isNaN(Number(v)) || (maxIndex && Number(v) > maxIndex))

    setInputIsInvalid(inputIsInvalid)
    setValidators(e.target.value)
  }

  return (
    <div className="mx-auto flex w-full max-w-[570px] flex-col gap-y-2">
      <div className="flex gap-x-4 rounded-[10px] bg-white px-4 py-1">
        <input
          value={validators}
          onKeyPress={(event) => {
            if (!/[0-9, ]/.test(event.key)) {
              event.preventDefault()
            }
          }}
          onChange={validateInputOnChange}
          placeholder="Enter validator index, validator key or Customer ID"
          className="w-full bg-transparent pl-1 text-base placeholder:text-pt-dark-indigo/70"
        />
        <button
          disabled={inputIsInvalid}
          onClick={handleSetExtraValidators}
          className="inline-flex h-[30px] min-w-[54px] items-center justify-center rounded-[7px] bg-pt-orange font-sourceSansPro font-semibold text-white shadow-button transition-colors hover:bg-pt-orange-dark"
        >
          Add
        </button>
      </div>
      {(hasInvalidList || inputIsInvalid) && validatorsList.length > 0 && (
        <p className="text-xs italic text-red-500">Invalid validators</p>
      )}
      {(validatorsList.length > 0 || pendingValidators.length > 0) && (
        <div className="flex h-[173px] h-full flex-col justify-between gap-y-4 rounded-[10px] bg-white px-4 py-2.5">
          <div className="flex max-h-[120px] overflow-y-auto overflow-x-hidden">
            <div className="flex flex-wrap gap-x-0.5 gap-y-1.5">
              {validatorsList.split(',').map(
                (validatorIndex) =>
                  !!validatorIndex && (
                    <span
                      key={validatorIndex}
                      className={`${removeableValidator} ${invalidValidators[validatorIndex] ? invalidValidator : ''}`}
                    >
                      {validatorIndex}{' '}
                      <button onClick={() => handleRemoveValidators(validatorIndex)}>
                        <img
                          src={RemoveIcon}
                          alt="Remove"
                          className="h-2.5 w-2.5"
                        />
                      </button>
                    </span>
                  )
              )}
              {pendingValidators.map((v) => (
                <span
                  key={v.pubKey}
                  className={`${removeableValidator} ${pendingValidator}`}
                  data-tip
                  data-for="pending-validator"
                >
                  {shortenAddress(v.pubKey, 4)}{' '}
                  <button onClick={() => removePendingValidators([v.pubKey])}>
                    <img
                      src={RemoveIcon}
                      alt="Remove"
                      className="h-2.5 w-2.5"
                    />
                  </button>
                  <ReactTooltip id="pending-validator">
                    Tempoary pending validator, this indicates the <br />
                    beaconchain has not yet assigned an index.
                  </ReactTooltip>
                </span>
              ))}
            </div>
          </div>
          <button
            onClick={() => handleRemoveValidators(validatorsList)}
            className={cx('min-h-[30px] w-max min-w-[54px]', removeableValidator, {})}
          >
            Clear
          </button>
        </div>
      )}
    </div>
  )
}

export default AddValidators

import cx from 'classnames'
import React, { useState } from 'react'
import { BigNumber } from '@ethersproject/bignumber'
import { Area, AreaChart, ResponsiveContainer, Sector, Tooltip, XAxis } from 'recharts'
import { Validator } from '../../../hooks/useUserData'
import { formatPercent } from '../../../utils/numberFormatters'
import {
  Currency,
  DateRange,
  DateRangeType,
  GraphRewardsBreakdown,
  GraphStakingData,
  GraphIncomeData,
  GraphAprData,
} from '../types'
import { toLocaleString } from '../../../helpers/common'
import UpOrDownArrow from '../../../components/UpOrDownArrow'

const renderActiveShape = (props: any) => {
  const RADIAN = Math.PI / 180
  const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, rewards } = props
  const sin = Math.sin(-RADIAN * midAngle)
  const cos = Math.cos(-RADIAN * midAngle)
  const sx = cx + (outerRadius + 10) * cos
  const sy = cy + (outerRadius + 10) * sin
  const mx = cx + (outerRadius + 30) * cos
  const my = cy + (outerRadius + 30) * sin
  const ex = mx + (cos >= 0 ? 1 : -1) * 22
  const ey = my
  const textAnchor = cos >= 0 ? 'start' : 'end'

  return (
    <g>
      {/*
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        {payload.type}
      </text>

         */}
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle
        cx={ex}
        cy={ey}
        r={2}
        fill={fill}
        stroke="none"
      />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill="#333"
      >{`${rewards} ETH`}</text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={18}
        textAnchor={textAnchor}
        fill="#999"
      >
        {`(Rate ${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  )
}

const COLORS = ['#BABFE8', '#ED6C4E', '#26297D', '#ed884e']

function getDifference<K extends 'staked' | 'cumulative' | 'apr'>(
  arr: Record<K, number>[],
  key: K,
  range?: DateRangeType
): number {
  if (arr.length < 1) {
    return 0
  } else if (range === DateRangeType.ALL) {
    return arr[arr.length - 1][key]
  } else {
    const previous = arr[0][key]
    const current = arr[arr.length - 1][key]
    return current - previous
  }
}

function getPercentageDifference<K extends 'staked' | 'cumulative' | 'apr'>(arr: Record<K, number>[], key: K): number {
  if (arr.length < 1) {
    return 0
  } else {
    const previous = arr[0][key]
    const current = arr[arr.length - 1][key]
    const difference = current - previous
    return difference / previous
  }
}

const statsBoxStyles = 'bg-white rounded-[10px] overflow-hidden min-h-[330px] max-h-[330px] w-full relative'
const detailsContainer = 'px-8 pt-5'
const titleStyles = 'text-base text-pt-dark-indigo'
const statsDetailsStyles = 'flex flex-col gap-y-2'
const statsRowStyles = 'flex gap-x-10 justify-between'
const valueStyles = 'text-[30px] whitespace-nowrap leading-[45px] text-pt-dark-indigo flex'
const performanceValueContainerStyles = 'flex items-center'
const performanceValueStyles = (difference: number) =>
  cx('font-sourceSansPro font-bold leading-[45px] text-[30px]', {
    'text-pt-red': difference < 0,
    'text-pt-dark-indigo': difference === 0,
    'text-pt-green': difference > 0,
  })
const chartContainer = 'h-[234px] w-full'

type Props = {
  locale: any
  validators: Validator[]
  graphIncomeData: GraphIncomeData[]
  graphStakingData: GraphStakingData[]
  graphAprData: GraphAprData[]
  currency: Currency
  dateRange: DateRange
  // rewardsBreakdown: GraphRewardsBreakdown[]
}

export const ValidatorStats = ({
  locale,
  validators,
  graphIncomeData,
  graphStakingData,
  graphAprData,
  currency,
  dateRange,
}: Props) => {
  const [activeIndex, setActiveIndex] = useState(0)

  const allTime = dateRange.t === DateRangeType.ALL
  // const onPieEnter = (_: any, index: number) => {
  // setActiveIndex(index)
  // }

  const rewardsDifference = getDifference(graphIncomeData, 'cumulative', dateRange.t)
  const stakedDifference = getPercentageDifference(graphStakingData, 'staked')
  const aprDifference = getPercentageDifference(graphAprData, 'apr')

  return (
    <div className="h-50 mt-14 grid w-full grid-cols-3 gap-6">
      <div className={statsBoxStyles}>
        <div className={detailsContainer}>
          <div className={statsDetailsStyles}>
            <div className={titleStyles}>Total Rewards</div>
            <div className={statsRowStyles}>
              <div className={valueStyles}>
                {graphIncomeData.length
                  ? toLocaleString(graphIncomeData[graphIncomeData.length - 1].cumulative.toFixed(2))
                  : 0.0}{' '}
                {currency}
              </div>
              <div className={performanceValueContainerStyles}>
                <div className={performanceValueStyles(rewardsDifference)}>
                  {currency !== Currency.ETH && `$`}
                  {rewardsDifference.toFixed(2)}
                </div>
                <UpOrDownArrow difference={rewardsDifference} />
              </div>
            </div>
          </div>
        </div>
        <div className={chartContainer}>
          <ResponsiveContainer
            width="100%"
            height="100%"
          >
            <AreaChart
              data={graphIncomeData}
              margin={{
                top: 100,
                right: 0,
                left: 0,
                bottom: 0,
              }}
            >
              <Tooltip />
              <Area
                type="monotone"
                dataKey="cumulative"
                stroke="#252982"
                strokeWidth={2}
                fill={COLORS[0]}
              />
              <XAxis
                hide={true}
                dataKey={'date'}
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </div>
      <div className={statsBoxStyles}>
        <div className={detailsContainer}>
          <div className={statsDetailsStyles}>
            <div className={titleStyles}>Total Value Staked</div>
            <div className={statsRowStyles}>
              <div className={valueStyles}>
                {graphStakingData.length
                  ? toLocaleString(graphStakingData[graphStakingData.length - 1].staked.toFixed(2))
                  : 0.0}{' '}
                {currency}
              </div>
              <div className={performanceValueContainerStyles}>
                <div className={performanceValueStyles(stakedDifference)}>
                  {formatPercent(stakedDifference, locale)}
                </div>
                <UpOrDownArrow difference={stakedDifference} />
              </div>
            </div>
          </div>
        </div>
        <div className={chartContainer}>
          <ResponsiveContainer
            width="100%"
            height="100%"
          >
            <AreaChart
              data={graphStakingData}
              margin={{
                top: 100,
                right: 0,
                left: 0,
                bottom: 0,
              }}
            >
              <Tooltip />
              <Area
                type="monotone"
                dataKey="staked"
                stroke="#252982"
                strokeWidth={2}
                fill={COLORS[0]}
              />
              <XAxis
                hide={true}
                dataKey={'date'}
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </div>
      {/*
      <StyledPaper>
        <PieInfo>Rewards Breakdown</PieInfo>
        <ResponsiveContainer
          width="100%"
          height="100%"
        >
          <PieChart
            width={400}
            height={400}
          >
            <Legend
              align="left"
              verticalAlign="middle"
              layout="vertical"
            />
            <Pie
              activeIndex={activeIndex}
              activeShape={renderActiveShape}
              data={rewardsBreakdown}
              // data={mockPieData}
              cx="50%"
              cy="60%"
              fill="#8884d8"
              dataKey="rewards"
              onMouseEnter={onPieEnter}
            >
              {rewardsBreakdown.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                  name={entry.type}
                />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </StyledPaper>
      */}
      <div className={statsBoxStyles}>
        <div className={detailsContainer}>
          <div className={statsDetailsStyles}>
            <div className={titleStyles}>APR</div>
            <div className={statsRowStyles}>
              <div className={valueStyles}>
                {formatPercent(graphAprData.length ? graphAprData[graphAprData.length - 1].apr : 0.0, locale)}
              </div>
              <div className={performanceValueContainerStyles}>
                <div className={performanceValueStyles(aprDifference)}>{formatPercent(aprDifference, locale)}</div>
                <UpOrDownArrow difference={aprDifference} />
              </div>
            </div>
          </div>
        </div>
        <div className={chartContainer}>
          <ResponsiveContainer
            width="100%"
            height="100%"
          >
            <AreaChart
              data={graphAprData}
              margin={{
                top: 100,
                right: 0,
                left: 0,
                bottom: 0,
              }}
            >
              <Tooltip formatter={(value, name, props) => `${formatPercent(Number(value), locale)}`} />
              <Area
                type="monotone"
                dataKey="apr"
                stroke="#252982"
                strokeWidth={2}
                fill={COLORS[0]}
              />
              <XAxis
                hide={true}
                dataKey={'date'}
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  )
}

export default ValidatorStats

// @ts-nocheck
import { Box } from 'grommet'
import React from 'react'

export const Spinner = ({
  className,
  align = 'center',
  justify = 'center',
  width = '45px',
  height = '45px',
}: {
  className?: string
  align?: string
  justify?: string
  width?: string
  height?: string
}) => (
  // @ts-ignore
  <Box
    align={align}
    justify={justify}
    className={className}
  >
    <svg
      version="1.1"
      viewBox="0 0 32 32"
      width={width}
      height={height}
      // fill="#7D4CDB"
    >
      <defs>
        <linearGradient
          id="rainbow"
          x1="0"
          x2="0"
          y1="0"
          y2="100%"
          gradientUnits="userSpaceOnUse"
        >
          <stop
            stopColor="#252982"
            offset="0%"
          />
          <stop
            stopColor="#252982"
            offset="20%"
          />
          <stop
            stopColor="#252982"
            offset="40%"
          />
          <stop
            stopColor="#252982"
            offset="60%"
          />
          <stop
            stopColor="#252982"
            offset="80%"
          />
          <stop
            stopColor="#252982"
            offset="100%"
          />
        </linearGradient>
      </defs>
      <path
        opacity=".25"
        d="M16 0 A16 16 0 0 0 16 32 A16 16 0 0 0 16 0 M16 4 A12 12 0 0 1 16 28 A12 12 0 0 1 16 4"
        fill="url(#rainbow)"
      />
      <path
        d="M16 0 A16 16 0 0 1 32 16 L28 16 A12 12 0 0 0 16 4z"
        fill="url(#rainbow)"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          from="0 16 16"
          to="360 16 16"
          dur="0.8s"
          repeatCount="indefinite"
        />
      </path>
    </svg>
  </Box>
)

export default Spinner

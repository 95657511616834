import { ActionTypes } from './index'

// basic form of validator which is forcefully added without loading the index since index doesnt exist yet
export type PendingValidator = {
  pubKey: string
  depositTime: number
}

export enum IndexValidationAction {
  'SET_MAX_INDEX',
}

export interface UpdateIndexValidationAction {
  type: ActionTypes.updateIndexValidationAction
  payload: {
    action: IndexValidationAction
    maxIndex: number
  }
}

export enum ValidatorsAction {
  'ADD_VALIDATORS',
  'REMOVE_VALIDATORS',
  'CLEAR_VALIDATORS',
}

export interface UpdateValidatorsAction {
  type: ActionTypes.updateValidatorsAction
  payload: {
    action: ValidatorsAction
    src?: string // upload src
    validators: string
  }
}

export enum NameValidatorsAction {
  'NAME_VALIDATOR',
}

export interface UpdateValidatorsNameAction {
  type: ActionTypes.updateValidatorsNameAction
  payload: {
    action: NameValidatorsAction
    // key value pairs
    records: Record<string, string>
  }
}

export const updateValidatorsName = (
  records: Record<string, string>,
  action: NameValidatorsAction
): UpdateValidatorsNameAction => {
  return {
    type: ActionTypes.updateValidatorsNameAction,
    payload: {
      action,
      records,
    },
  }
}

export const updateValidators = (
  validators: string,
  action: ValidatorsAction,
  src?: string
): UpdateValidatorsAction => {
  return {
    type: ActionTypes.updateValidatorsAction,
    payload: {
      action,
      src,
      validators,
    },
  }
}

export const updateIndexValidation = (maxIndex: number, action: IndexValidationAction): UpdateIndexValidationAction => {
  return {
    type: ActionTypes.updateIndexValidationAction,
    payload: {
      action,
      maxIndex,
    },
  }
}

export type DispatchUpdateValidators = (s: string, src?: string) => void
export type DispatchUpdateValidatorsName = (records: Record<string, string>) => void
export type DispatchUpdateMaxIndex = (maxIndex: number) => void

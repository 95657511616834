import { useMemo } from 'react'
import useSWR from 'swr'
import { BEACONCHAIN_URL } from '../utils/envVars'
import { fetcher } from '../utils/data'

interface RawValidator {
  publickey: string
  valid_signature: boolean
  validatorindex: number
}

export type UserValidator = {
  pubKey: string
  index: number
}

export const useUsersValidators = (account: string | undefined | null): UserValidator[] => {
  const {
    data: validators,
    error: _error,
    isLoading: _isLoading,
  } = useSWR(!!account ? `${BEACONCHAIN_URL}/api/v1/validator/eth1/${account}` : null, fetcher)

  return useMemo(() => {
    if (!validators) {
      return []
    }
    return validators.data.map((v: RawValidator) => ({
      pubKey: v.publickey,
      index: v.validatorindex,
    }))
  }, [validators])
}

export default useUsersValidators

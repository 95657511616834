import { useEffect, useState, useMemo } from 'react'
import { useValidatorsPerformance, RawBlockData } from './useValidatorsPerformance'
import { ValidatorInfo, useValidatorsInfo } from './useValidatorsInfo'
import { ParsedIncomeHistory, useValidatorsIncomeData, EvntType } from './useValidatorsIncomeData'
import { Rewards } from '../types/validators'
import { BigNumber } from '@ethersproject/bignumber'
import { parseUnits } from '@ethersproject/units'

type ValidatorPerformance = {
  earntTotal: Rewards
  earnt1D: Rewards
  earnt7D: Rewards
  earnt31D: Rewards
  balance: BigNumber
}

type ValidatorIncomeData = {
  allEvents: ParsedIncomeHistory[] // all
  deposits: ParsedIncomeHistory[] // deposits
  withdrawals: ParsedIncomeHistory[] // withdrawals
}

export type Validator = ValidatorInfo & Partial<ValidatorPerformance>

export interface UserData {
  validators: Validator[]
  validatorIncomeData: ValidatorIncomeData
  isLoadingPerformance: boolean
  isLoadingIncomeData: boolean
}

export const useUserData = (validatorsList: string, ethPrices: any, shouldFetchData: boolean): UserData => {
  const [validators, setValidators] = useState<Validator[]>([])

  const validatorInfo = useValidatorsInfo(validatorsList, shouldFetchData)
  const {
    validatorsPerformance: performance,
    rawBlockData,
    isLoading: isLoadingPerformance,
  } = useValidatorsPerformance(validatorsList, shouldFetchData)

  const {
    allEvents: _allEvents,
    deposits,
    withdrawals,
    isLoading: isLoadingIncomeData,
  } = useValidatorsIncomeData(validatorsList, ethPrices, shouldFetchData)

  useEffect(() => {
    if (!!validatorInfo.length && performance) {
      setValidators(
        validatorInfo.map((v) => ({
          ...v,
          ...performance[v.index],
        }))
      )
    }
  }, [validatorInfo, performance])

  const allEvents = useMemo(() => {
    const producedBlocks = rawBlockData.map((b) => {
      return {
        validatorIndex: b.posConsensus.proposerIndex,
        timestamp: new Date(b.timestamp * 1000).toISOString(),
        changeInBalance: BigNumber.from(b.blockReward.toString()),
        type: EvntType.Block,
        // TODO get these from the closest neighbour
        balance: BigNumber.from('0'),
        ethPrice: BigNumber.from('0'),
      }
    })

    const combined = _allEvents.concat(producedBlocks).sort((a, b) => Date.parse(a.timestamp) - Date.parse(b.timestamp))
    let lastKnownBalance = BigNumber.from('0')
    let lastKnownEthPrice = BigNumber.from('0')
    combined.forEach((event) => {
      if (event.ethPrice.eq(0)) {
        event.ethPrice = lastKnownEthPrice
      } else {
        lastKnownEthPrice = event.ethPrice
      }
      if (event.balance.eq(0)) {
        event.balance = lastKnownBalance
      } else {
        lastKnownBalance = event.balance
      }
    })
    return combined
  }, [rawBlockData, _allEvents])

  return {
    validators,
    validatorIncomeData: {
      allEvents,
      deposits,
      withdrawals,
    },
    isLoadingIncomeData,
    isLoadingPerformance,
  }
}

import { useMemo } from 'react'
import useSWR from 'swr'
import { BEACONCHAIN_URL } from '../utils/envVars'
import { beaconChainFetcher } from '../utils/data'
import { Rewards } from '../types/validators'
import { parseUnits, formatUnits } from '@ethersproject/units'
import { BigNumber } from '@ethersproject/bignumber'

type CLPerformance = {
  balance: number
  performance1d: number
  performance31d: number
  performance365d: number
  performance7d: number
  performancetoday: number
  performancetotal: number
  rank7d: number
  validatorindex: number
}

export type RawBlockData = {
  timestamp: number
  blockReward: number
  blockMevReward: number
  posConsensus: {
    proposerIndex: number
    // "executionBlockNumber": 17520426,
    // "slot": 6702853,
    // "epoch": 209464,
    // "finalized": true
  }
  relay: {
    tag: string
    // "builderPubkey": "0xb26f96664274e15fb6fcda862302e47de7e0e2a6687f8349327a9846043e42596ec44af676126e2cacbdd181f548e681",
    // "producerFeeRecipient": "0x8306300ffd616049fd7e4b0354a64da835c1a81c"
  }
  // "blockHash": "0x3f252c1f9189162ce7a0eca5986e0663fde446b6ee8f959ac8f5a50261906edc",
  // "blockNumber": 17520426,
  // "producerReward": 36560709165250310,
  // "feeRecipient": "0x4838b106fce9647bdf1e7877bf73ce8b0bad5f97",
  // "gasLimit": 30000000,
  // "gasUsed": 29993850,
  // "baseFee": 13934507525,
  // "txCount": 138,
  // "internalTxCount": 35,
  // "uncleCount": 0,
  // "parentHash": "0xb21c241ff2d1b0f0e94ed46e86477f795f765d53edaf937a092ecab870b6f5af",
  // "uncleHash": "0x1dcc4de8dec75d7aab85b567b6ccd41ad312451b948a7413f0a142fd40d49347",
  // "difficulty": 0,
  // "consensusAlgorithm": "pos"
}

type ELPerformance = {
  performance1d: number
  performance31d: number
  performance7d: number
  validatorindex: number
}

export const useValidatorsPerformance = (
  validators: string,
  shouldFetchData: boolean
): {
  validatorsPerformance: Record<
    number,
    {
      index: number
      balance: BigNumber
      earnt1D: Rewards
      earnt31D: Rewards
      earnt7D: Rewards
      earnt365D: Rewards
      earntToday: Rewards
      earntTotal: Rewards
      rank7D: number
    }
  >[]
  rawBlockData: RawBlockData[]
  isLoading: boolean
} => {
  const {
    data: clPerformanceData,
    // error: clPerformanceDataError,
    // isLoading: clPerformanceIsLoading,
  } = useSWR(
    !!validators && shouldFetchData ? `${BEACONCHAIN_URL}/api/v1/validator/${validators}/performance` : null,
    beaconChainFetcher
  )
  const {
    data: elPerformanceData,
    // error: elPerformanceDataError,
    // isLoading: elPerformanceDataIsLoading,
  } = useSWR(
    !!validators && shouldFetchData ? `${BEACONCHAIN_URL}/api/v1/validator/${validators}/execution/performance` : null,
    beaconChainFetcher
  )
  const {
    data: elBlocksData,
    // error: elBlocksDataError,
    // isLoading: elBlocksDataIsLoading,
  } = useSWR(!!validators ? `${BEACONCHAIN_URL}/api/v1/execution/${validators}/produced` : null, beaconChainFetcher)

  return useMemo(() => {
    if (!elPerformanceData || !clPerformanceData || !elBlocksData) {
      return { validatorsPerformance: [], rawBlockData: [], isLoading: true }
    }

    const elValidatorsPerformance = elPerformanceData.data.reduce(
      (o: any, v: ELPerformance) => ({
        ...o,
        [v.validatorindex]: {
          ...v,
          // these amounts are already in 18 decimal units
          performance1d: BigNumber.from(v.performance1d.toString()),
          performance7d: BigNumber.from(v.performance7d.toString()),
          performance31d: BigNumber.from(v.performance31d.toString()),
        },
      }),
      {} as Record<number, ELPerformance>
    )

    const elRewardTotals = elBlocksData.data.reduce(
      (o: any, b: RawBlockData) => ({
        ...o,
        [b.posConsensus.proposerIndex]: (o[b.posConsensus.proposerIndex] ?? BigNumber.from('0')).add(
          b.blockReward.toString()
        ),
      }),
      {} as Record<number, BigNumber>
    )

    // combines clData and elPerformance
    const validatorsPerformance = clPerformanceData.data.reduce((o: any, v: CLPerformance) => {
      // clPerformance amounts are in 9 decimal units need to parse them to 18
      return {
        ...o,
        [v.validatorindex]: {
          index: v.validatorindex,
          balance: parseUnits(v.balance.toString(), 9),
          earnt1D: {
            cl: parseUnits(v.performance1d.toString(), 9),
            el: elValidatorsPerformance[v.validatorindex]?.performance1d ?? BigNumber.from('0'),
          },
          earnt7D: {
            cl: parseUnits(v.performance7d.toString(), 9),
            el: elValidatorsPerformance[v.validatorindex]?.performance7d ?? BigNumber.from('0'),
          },
          earnt31D: {
            cl: parseUnits(v.performance31d.toString(), 9),
            el: elValidatorsPerformance[v.validatorindex]?.performance31d ?? BigNumber.from('0'),
          },
          earnt365D: {
            cl: parseUnits(v.performance365d.toString(), 9),
            el: BigNumber.from('0'), // TODO get el rewards 365 days
          },
          earntToday: {
            cl: parseUnits(v.performancetoday.toString(), 9),
            el: BigNumber.from('0'), // TODO get el rewards today
          },
          earntTotal: {
            cl: parseUnits(v.performancetotal.toString(), 9),
            el: elRewardTotals?.[v.validatorindex] ?? BigNumber.from('0'),
          },
          rank7D: v.rank7d,
        },
      }
    }, {})

    return { validatorsPerformance, rawBlockData: elBlocksData.data, isLoading: false }
  }, [elBlocksData, clPerformanceData, elPerformanceData])
}

export default useValidatorsPerformance

// @ts-nocheck
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { fetchFromDatoAPI } from '../utils/cms'
import { WorkflowPageTemplate } from '../components/WorkflowPage/WorkflowPageTemplate'
import { MarkdownContent } from '../components/MarkdownContent'

export interface StakingContent {
  stakingTC: {
    bodyText: string
  }
}

const TERMS_CONDITIONS_QUERY = `{
    stakingTC {
      bodyText
    }
}`

const TextContainer = styled.div`
  color: white;
  margin: 0 auto;

  a {
    color: white;
  }
`

export const TermsAndConditions = (): JSX.Element => {
  const [data, setData] = useState<StakingContent>(undefined)

  useEffect(() => {
    const fetchData = async () => {
      const content = await fetchFromDatoAPI(TERMS_CONDITIONS_QUERY)
      setData(content)
    }
    fetchData()
  }, [])

  return (
    <WorkflowPageTemplate title="Ethereum Staking Terms & Conditions">
      <TextContainer>
        {data && data.stakingTC && <MarkdownContent>{data.stakingTC.bodyText}</MarkdownContent>}
      </TextContainer>
    </WorkflowPageTemplate>
  )
}

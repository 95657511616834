export const fetchFromDatoAPI = async (query: string): Promise<any> => {
  const { data } = await fetch('https://graphql.datocms.com/', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${process.env.REACT_APP_DATOCMS_API_KEY}`,
    },
    body: JSON.stringify({
      query,
    }),
  })
    .then((res) => res.json())
    .then((res) => res)
    .catch((error) => {
      throw new Error(error)
    })

  return data
}

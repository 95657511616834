import React from 'react'
import { Link } from 'react-router-dom'
import { RoutesEnum } from '../../Routes'
import { getCurrentYear } from '../../helpers/common'
import PierTwoLogo from '../../static/logo.svg'
import { legalLinks, socialLinkRowContent } from './presets'

export const Footer = () => (
  <footer className="border-t border-pt-dark-indigo py-3">
    <div className="relative mx-auto flex w-full max-w-[1440px] justify-between gap-x-[100px] px-4 text-xs md:px-10 lg:px-0">
      <div className="flex items-center gap-x-10">
        <div className="flex items-center gap-x-2">
          <Link to={RoutesEnum.landingPage}>
            <img
              src={PierTwoLogo}
              alt="Pier Two"
              className="h-[17px] w-[87px]"
            />
          </Link>
          <span>&copy; {getCurrentYear()}</span>
        </div>
        <div className="grid grid-cols-4 gap-x-6">
          {socialLinkRowContent.map(({ label, href, icon }) => (
            <a
              href={href}
              key={label}
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center gap-x-2.5"
            >
              <img
                src={icon}
                alt={label}
                className="h-[22px] w-[22px]"
              />
            </a>
          ))}
        </div>
      </div>

      {/* Right side */}
      <div className="flex gap-x-6">
        {legalLinks.map(({ label, href }) => (
          <a
            href={href}
            key={label}
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="text-pt-dark-indigo transition-colors hover:text-pt-light-indigo">{label}</span>
          </a>
        ))}
      </div>
    </div>
  </footer>
)

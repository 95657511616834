import cx from 'classnames'
import React, { DetailedHTMLProps, FC, HTMLAttributes } from 'react'

interface ContainerProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  className?: string
  children: any
}

export const Container: FC<ContainerProps> = ({ className, children, ...props }) => (
  <div
    className={cx('relative mx-auto w-full max-w-[1440px] px-4 md:px-10 lg:px-0', className)}
    {...props}
  >
    {children}
  </div>
)

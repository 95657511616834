import cx from 'classnames'
import React, { FC, useRef, useState } from 'react'
import { useOutsideClick } from '../../hooks/useOutsideClick'
import ChevronDownIcon from '../../static/chevron-down.svg'
import { NETWORK_NAME } from '../../utils/envVars'
import { Link } from '../../components/Link'
import { networkConfigs } from '../../constants/networks'
import { Network } from '../../types/network'

interface NetworkDropdownProps {
  targetNetwork: Network
}

export const NetworkDropdown: FC<NetworkDropdownProps> = ({ targetNetwork }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const containerRef = useRef<HTMLDivElement>(null)

  const { [targetNetwork]: currentNetwork, ...otherNetworks } = networkConfigs

  useOutsideClick(containerRef, () => setIsDropdownOpen(false))
  return (
    <div
      ref={containerRef}
      className="relative h-12"
    >
      <button
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        className="flex min-h-[47px] items-center gap-x-2 bg-pt-light-indigo px-2.5"
      >
        <span className="font-semibold text-pt-dark-indigo">{currentNetwork.name}</span>
        <img
          src={ChevronDownIcon}
          alt="Chevron Down"
          className="h-[14px] w-[14px]"
        />
      </button>
      <div
        className={cx(
          `absolute left-0 top-[calc(100%+1px)] flex w-full flex-col bg-pt-light-indigo text-center transition-opacity`,
          {
            'pointer-events-none opacity-0': !isDropdownOpen,
          }
        )}
      >
        {Object.values(otherNetworks as Record<string, { link?: string; name: string }>).map((network, i) => (
          <div
            className={cx(
              `pointer-events-none flex w-full flex-col bg-pt-light-indigo text-center transition-opacity`,
              {
                'pointer-events-none opacity-0': !isDropdownOpen,
              }
            )}
          >
            <button className="h-10 px-0 leading-none">
              {network.link ? (
                <Link
                  className={cx('h-10 px-0 leading-none', {
                    'pointer-events-none': !isDropdownOpen,
                  })}
                  to={network.link}
                >
                  {network.name}
                </Link>
              ) : (
                <>
                  {network.name} <span className="whitespace-nowrap text-xs">(Coming soon)</span>
                </>
              )}
            </button>
          </div>
        ))}
      </div>
    </div>
  )
}

export default NetworkDropdown

import { Web3ReactProvider, Web3ReactHooks } from '@web3-react/core'
import { Connector } from '@web3-react/types'
import { Grommet } from 'grommet'
import { createBrowserHistory } from 'history'
import React from 'react'
import { Provider } from 'react-redux'
import { createStore } from 'redux'
import { Routes as RoutedContent } from './Routes'
import { AppBar } from './components/AppBar'
import { LocalizedRouter } from './components/LocalizedRouter'
import './font.css'
import './index.css'
import { reducers } from './store/reducers'
import { GlobalStyles } from './styles/GlobalStyles'
import { grommetTheme } from './styles/grommetTheme'
import { ThemeProvider } from 'styled-components'
import { styledComponentsTheme } from './styles/styledComponentsTheme'

import { 
  metamask, 
  metamaskHooks, 
  walletConnect, 
  walletConnectHooks,
  coinbaseWallet,
  coinbaseWalletHooks
} from './pages/ConnectWallet/connectors'

export const store = createStore(
  reducers,
  // @ts-ignore
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)

const connectors: [Connector, Web3ReactHooks][] = [
  [metamask, metamaskHooks],
  [walletConnect, walletConnectHooks],
  [coinbaseWallet, coinbaseWalletHooks]
]

export const history = createBrowserHistory()

export const App: React.FC = () => {
  return (
    <Web3ReactProvider connectors={connectors}>
      <LocalizedRouter history={history}>
        <Provider store={store}>
          <Grommet theme={grommetTheme as any}>
            <ThemeProvider theme={styledComponentsTheme}>
              <AppBar />
              <GlobalStyles />
              <RoutedContent />
            </ThemeProvider>
          </Grommet>
        </Provider>
      </LocalizedRouter>
    </Web3ReactProvider>
  )
}

import cx from 'classnames'
import React, { ButtonHTMLAttributes, DetailedHTMLProps, FC, ReactNode } from 'react'

type ButtonColor = 'primary' | 'secondary'

const BUTTON_COLOURS = {
  primary: 'bg-pt-orange hover:bg-pt-orange-dark',
  secondary: 'bg-pt-dark-grey hover:bg-pt-orange',
}

interface ButtonProps extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  variant?: ButtonColor
  className?: string
  children: string | ReactNode
}

export const Button: FC<ButtonProps> = ({ variant = 'primary', className, children, ...props }) => (
  <button
    tabIndex={-1}
    className={cx(
      'flex h-[50px] max-w-[570px] select-none items-center justify-center rounded-[7px] px-4 font-sourceSansPro text-white shadow-button transition-all',
      className,
      BUTTON_COLOURS[variant],
      {
        'cursor-not-allowed opacity-50': props.disabled,
      }
    )}
    {...props}
  >
    <span className="whitespace-nowrap">{children}</span>
  </button>
)

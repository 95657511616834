// @ts-nocheck
import React from 'react'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import { Text } from '../components/Text'
import { Link } from '../components/Link'
import { WorkflowPageTemplate } from '../components/WorkflowPage/WorkflowPageTemplate'

const Background = styled.div`
  background-color: ${(p) => p.theme.pierTwo.blue};
  min-height: 100vh;
  span,
  a {
    color: white !important;
  }
  a {
    text-decoration: underline !important;
  }
`
const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`
const LogoText = styled(Text)`
  font-weight: bold;
  align-items: center;
  color: white;
`
const Content = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 400px;
  height: 400px;
  color: white;

  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;

  /*this to solve "the content will not be cut when the window is smaller than the content": */
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
`

export const NotFoundPage = (): JSX.Element => {
  return (
    <WorkflowPageTemplate title="Page not found">
      <Background>
        <Content>
          <LogoContainer>
            <LogoText
              className="mt20"
              center
              size="large"
            >
              PierTwo Staking Launchpad
            </LogoText>
          </LogoContainer>
          <Text
            center
            className="mt20"
          >
            Sorry, this page does not exist.
            <FormattedMessage defaultMessage="Sorry, this page does not exist." />
            <Link
              primary
              className="mt20"
              to="/"
            >
              {' '}
              <FormattedMessage defaultMessage="Launchpad home" />
            </Link>
          </Text>
        </Content>
      </Background>
    </WorkflowPageTemplate>
  )
}

import { Network } from '../types/network'
// type Network = 'Mainnet' | 'Testnet' | 'Goerli' | 'Holesky'

export const networkConfigs: Record<
  Network,
  {
    name: string
    link?: string
    broadcastLink?: string
  }
> = {
  Mainnet: {
    name: 'Mainnet',
    link: 'https://stake.piertwo.com',
    broadcastLink: 'https://beaconcha.in/tools/broadcast',
  },
  Goerli: {
    name: 'Goerli',
    link: 'https://goerli.piertwo.com',
    broadcastLink: 'https://goerli.beaconcha.in/tools/broadcast',
  },
  Holesky: {
    name: 'Holesky',
    link: 'https://holesky.piertwo.com',
    broadcastLink: 'https://holesky.beaconcha.in/tools/broadcast',
  },
}

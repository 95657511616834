import { useEffect, useState } from 'react'
import { useWeb3React } from '@web3-react/core'
import { formatEther } from '@ethersproject/units'

export const useEthBalance = (): number => {
  const { provider, account } = useWeb3React()
  const [balance, setBalance] = useState(0)

  useEffect(() => {
    if (!provider || !account) {
      return
    }
    try {
      provider.getBalance(account).then((userBalance) => {
        setBalance(+formatEther(userBalance))
      })
    } catch (error) {
      console.error('Error getting balance:', error)
      setBalance(0)
    }
  }, [provider, account])

  // adds event emitter to listen to new blocks & update balance if it changed
  useEffect((): any => {
    if (!!account && !!provider) {
      provider.on('block', () => {
        if (!provider || !account) {
          console.log('Found new block but provider or account undefined')
          return
        }
        try {
          provider.getBalance(account).then((userBalance) => {
            setBalance(+formatEther(userBalance))
          })
        } catch (error) {
          console.error('Error getting balance:', error)
          setBalance(0)
        }
      })
      return () => !!provider && provider.off('block')
    }
  }, [provider, account])

  return balance
}

export default useEthBalance

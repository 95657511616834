import cx from 'classnames'
import React, { ChangeEvent, createRef, FC, FormEvent, useMemo, useRef, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { Modal } from '.'
import { isValidEmail } from '../../helpers/common'
import { CloseButton } from './ModalItems'
import { RECAPTCHA_SITE_KEY } from '../../utils/envVars'

const buttonStyles =
  'group py-2 px-4 border border-pt-dark-indigo w-max text-pt-dark-indigo inline-flex items-center gap-x-2 font-bold text-xs hover:bg-pt-dark-indigo hover:text-white transition-colors'

const formFields = [
  {
    label: 'First name',
    type: 'text',
    name: 'firstName',
  },
  {
    label: 'Last name',
    type: 'text',
    name: 'lastName',
  },
  {
    label: 'Company',
    type: 'text',
    name: 'company',
  },
  {
    label: 'Validator Count',
    type: 'number',
    name: 'validator_count',
  },
  {
    label: 'Ethereum Withdrawal Address',
    type: 'text',
    name: 'ethereum_withdrawal_address',
  },
  {
    label: 'Email',
    type: 'email',
    name: 'email',
  },
  {
    label: 'Message',
    type: 'textarea',
    name: 'message',
  },
]

interface StakeModalProps {
  isOpen: boolean
  onClose: () => void
}

export const StakeModal: FC<StakeModalProps> = ({ isOpen, onClose }) => {
  const [isSuccessful, setIsSuccessful] = useState(false)
  const [formValues, setFormValues] = useState({
    email: '',
    firstName: '',
    lastName: '',
    company: '',
    validator_count: 0,
    ethereum_withdrawal_address: '',
    message: '',
  })

  const handleUpdateValues = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target

    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  const handleSuccess = () => setIsSuccessful(true)

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      className="bg-white shadow-modal xxs:max-w-[800px]"
    >
      <CloseButton
        onClose={onClose}
        className="absolute right-2 top-3 sm:right-6 sm:top-6"
      />
      {isSuccessful ? (
        <SuccessText />
      ) : (
        <FormInput
          formValues={formValues}
          handleSuccess={handleSuccess}
          handleUpdateValues={handleUpdateValues}
        />
      )}
    </Modal>
  )
}

type EmailError = 'invalid' | 'duplicate' | 'unknown' | 'missingFields' | 'none'

enum ErrorEnum {
  invalid = 'invalid',
  duplicate = 'duplicate',
  unknown = 'unknown',
  missingFields = 'missingFields',
  none = 'none',
}

type HubspotResponse = 200 | 400 | 403 | 404

interface FormInputProps {
  formValues: {
    email: string
    firstName: string
    lastName: string
    company: string
    validator_count: number
    ethereum_withdrawal_address: string
    message: string
    [key: string]: string | number | undefined
  }
  handleSuccess: () => void
  handleUpdateValues: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
}

export const FormInput: FC<FormInputProps> = ({ formValues, handleSuccess, handleUpdateValues }) => {
  const [fieldsWithError, setFieldsWithError] = useState<string[]>([])
  const [errorType, setErrorType] = useState<EmailError>('none')
  const formRef = useRef(null)
  const recaptchaRef = createRef()

  const getHubspotCookie = () => {
    // eslint-disable-next-line no-useless-escape
    return document.cookie.replace(/(?:(?:^|.*;\s*)hubspotutk\s*\=\s*([^;]*).*$)|^.*$/, '$1')
  }

  const sendToHubspot = async () => {
    const hutk = getHubspotCookie()
    const data = {
      submittedAt: Date.now().toString(),
      fields: [
        {
          objectTypeId: '0-1',
          name: 'email',
          value: formValues.email,
        },
        {
          objectTypeId: '0-1',
          name: 'firstname',
          value: formValues.firstName,
        },
        {
          objectTypeId: '0-1',
          name: 'lastname',
          value: formValues.lastName,
        },
        {
          objectTypeId: '0-1',
          name: 'company',
          value: formValues.company,
        },
        {
          objectTypeId: '0-1',
          name: 'validator_count',
          value: formValues.validator_count,
        },
        {
          objectTypeId: '0-1',
          name: 'ethereum_withdrawal_address',
          value: formValues.ethereum_withdrawal_address,
        },
        {
          objectTypeId: '0-1',
          name: 'message',
          value: formValues.message,
        },
      ],
      context: {
        pageUri: window.location.href,
        ...(hutk ? { hutk } : {}),
      },
    }
    const result = await fetch(
      'https://api.hsforms.com/submissions/v3/integration/submit/22649712/6630187f-1348-41b5-a985-5a396bab5279',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      }
    )
    const response = await result.json()
    console.log(response)
    return result.status
  }

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    setErrorType('none')
    setFieldsWithError([])
    const isValid = isValidEmail(formValues.email)
    event.preventDefault()

    // Check all fields have been filled
    const emptyFields = Object.keys(formValues).filter((key) => formValues[key] === '')

    if (emptyFields.length > 0) {
      setErrorType('missingFields')
      setFieldsWithError(emptyFields)
      return
    }

    if (isValid) {
      // eslint-disable-next-line
      ;(recaptchaRef.current as any).execute()
    } else {
      setErrorType('invalid')
    }
  }

  const handleResult = (result: HubspotResponse) => {
    if (result === 200) {
      setErrorType('none')
      handleSuccess()
    } else if (result === 400) {
      setErrorType('duplicate')
    } else {
      setErrorType('unknown')
    }
  }

  // Prevent bots from signing up on submit
  const onReCAPTCHAChange = async (captchaCode: string | null) => {
    if (!captchaCode) {
      return
    }
    const result = await sendToHubspot()
    handleResult(result as HubspotResponse)
  }

  const labelText = useMemo(() => {
    switch (errorType) {
      case ErrorEnum.invalid:
        return 'Enter a valid email address'
      case ErrorEnum.duplicate:
        return 'Email address already subscribed'
      case ErrorEnum.unknown:
        return 'An error occurred, please try again'
      default:
        return ''
    }
  }, [errorType])

  return (
    <div className="max-h-[calc(100vh-80px)] overflow-y-auto overflow-x-hidden p-4 pt-8 md:px-10 md:pb-10 md:pt-10">
      <div className="mx-auto w-full">
        <p className="mb-6">
          If you are interested in non-custodial ETH Staking, please provide some details. Our team will get in touch
          and ensure you get the best customer service and experience.
        </p>
        <form
          id="newsletter-subscription-form"
          className="flex w-full flex-col gap-y-4"
          ref={formRef}
          onSubmit={handleSubmit}
        >
          <ReCAPTCHA
            ref={recaptchaRef as any}
            size="invisible"
            sitekey={String(RECAPTCHA_SITE_KEY)}
            onChange={onReCAPTCHAChange}
          />
          <div className="grid w-full gap-x-6 gap-y-4 md:grid-cols-2">
            {formFields.map(({ label, type, name }) => (
              <label
                key={label}
                id={label}
                className={cx('flex flex-col items-start gap-y-2', {
                  'md:col-span-2': type === 'textarea',
                })}
              >
                <span className="text-xs">{label}</span>
                {type === 'textarea' && (
                  <textarea
                    id={label}
                    name={name}
                    onChange={(e) => handleUpdateValues(e)}
                    value={formValues[name]}
                    className={cx('h-[77px] w-full resize-none border p-2 pr-0 text-pt-dark-indigo', {
                      'border-[red]': fieldsWithError.includes(name),
                      'border-pt-dark-indigo/25 ': !fieldsWithError.includes(name),
                    })}
                  />
                )}
                {type !== 'textarea' && (
                  <input
                    id={label}
                    type={type}
                    name={name}
                    onChange={(e) => handleUpdateValues(e)}
                    value={formValues[name]}
                    className={cx('h-[34px] w-full border p-2 pr-0 text-pt-dark-indigo', {
                      'border-[red]': fieldsWithError.includes(name),
                      'border-pt-dark-indigo/25 ': !fieldsWithError.includes(name),
                    })}
                  />
                )}
              </label>
            ))}
          </div>
          <button
            type="submit"
            className={cx('mx-auto', buttonStyles)}
          >
            Submit
          </button>
          {labelText && <span className="block w-full text-center text-[red]">{labelText}</span>}
        </form>
      </div>
    </div>
  )
}

const SuccessText: FC = () => (
  <div className="px-10 pb-10 pt-16 text-center">
    <h1 className="mb-10 text-[40px] leading-[48px]">Welcome to the Pier Two Network</h1>
    <p>You’ve successfully expressed interest in our ETH Staking platform and will be seeing us in your inbox soon.</p>
  </div>
)
